import { sendErrorToSentry } from 'utils/sentry';
import {
  buildGetPaymentMethodIdRequest,
  buildPayPalInitialTransactionRequest,
  buildApplePayInitialTransactionRequest,
} from './paymentRequestBuilder';

type RedirectPaymentMethod = {
  type: string;
  displayName: string;
  details?: {
    id: string;
    shopperEmail: string;
    preferred?: boolean;
  }[];
};

type PaymentMethod = {
  method: string;
  types: RedirectPaymentMethod[];
};
type TriggerInitialTransactionOptions = {
  apiKey: string;
  token: string;
  ciamId: string;
  marketplace: string;
  applePayToken?: string;
  userAgent?: string;
  currency?: string;
};

export const triggerPayPalInitialTransaction =
  ({ apiKey, token, ciamId, marketplace }: TriggerInitialTransactionOptions) =>
  async () => {
    try {
      const payPalResponse = await fetch(
        `${process.env.REACT_APP_PORSCHE_PAYMENTS_API_URL}/transactions/${marketplace}`,
        buildPayPalInitialTransactionRequest({
          apiKey,
          token,
          ciamId,
          marketplace,
        }),
      );

      if (payPalResponse.url) {
        window.location.href = payPalResponse.url;
      }
    } catch (error: unknown) {
      sendErrorToSentry(error);
    }
  };

export const triggerApplePayTransaction = async ({
  apiKey,
  token,
  ciamId,
  marketplace,
  userAgent = '',
  applePayToken = '',
  currency = '',
}: TriggerInitialTransactionOptions) => {
  try {
    const applePayResponse = await fetch(
      `${process.env.REACT_APP_PORSCHE_PAYMENTS_API_URL}/transactions/${marketplace}`,
      buildApplePayInitialTransactionRequest({
        apiKey,
        token,
        ciamId,
        marketplace,
        applePayToken,
        userAgent,
        currency,
      }),
    );

    const applePayResponseJson = await applePayResponse.json();

    return applePayResponseJson as unknown as { paymentMethodId: string };
  } catch (error: unknown) {
    sendErrorToSentry(error);
  }
};

export const getPayPalPaymentMethodId = async ({
  apiKey,
  token,
  marketplace,
}: Omit<TriggerInitialTransactionOptions, 'ciamId'>) => {
  try {
    const paymentMethodsResponse = await fetch(
      `${process.env.REACT_APP_PORSCHE_PAYMENTS_API_URL}/mypaymentmethods/${marketplace}`,
      buildGetPaymentMethodIdRequest({ apiKey, token }),
    );

    const paymentMethods = await paymentMethodsResponse.json();
    const redirectPaymentMethods: PaymentMethod =
      paymentMethods.paymentMethods.find(
        (paymentMethod: PaymentMethod) => paymentMethod.method === 'redirect',
      );
    const savedPayPalMethod = redirectPaymentMethods.types.find(
      (redirectPaymentMethod) => redirectPaymentMethod.type === 'paypal',
    );
    const savedPayPalMethodId = savedPayPalMethod?.details?.find(
      (detail) => detail.id,
    )?.id;

    return savedPayPalMethodId;
  } catch (error: unknown) {
    console.log('Something went wrong with loading payment methods');
  }
};
