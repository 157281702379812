import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'confirmation.failure.title',
    defaultMessage: 'Activation failed',
    description: 'Title of the failure page',
  },
  titleSanctionCheck: {
    id: 'confirmation.failure.titleSanctionCheck',
    defaultMessage: 'Ativation not possible',
    description: 'Title of the failure page if failed on sanction check',
  },
  subtitle: {
    id: 'confirmation.failure.subtitle',
    defaultMessage: 'Here’s what you can do',
    description: 'Subtitle of the failure page',
  },
  subtitleSanctionCheck: {
    id: 'confirmation.failure.subtitleSanctionCheck',
    defaultMessage:
      'Unfortunately, our check confirmed that you are not eligible for this service. Please contact our customer support for more details.',
    description: 'Subtitle of the failure page if failed on sanction check',
  },
  help1: {
    id: 'confirmation.failure.help1',
    defaultMessage:
      'Unfortunately, the Porsche Charging Service activation failed . Please contact our {customerSupport} to resolve this issue or try activating the service again later.',
    description: 'Single item of self service help list',
  },
  customerSupportLinkLabel: {
    id: 'confirmation.failure.customerSupportLinkLabel',
    defaultMessage: 'customer support',
    description: 'Customer support link included in help text',
  },
  action: {
    id: 'confirmation.failure.action',
    defaultMessage: 'Go back to my car',
    description: 'Button with link to porsche profile',
  },
});
