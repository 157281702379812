import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  tariffActivation: {
    id: 'planCard.tariffActivation',
    defaultMessage: 'Tariff Activation',
    description: 'title for tariff activation',
  },
  tariffActivationSubtitle: {
    id: 'planCard.tariffActivationSubtitle',
    defaultMessage: 'Choose a charging tariff to continue.',
    description: 'An invitation message to select a tariff',
  },
  month: {
    id: 'planCard.month',
    defaultMessage: 'month',
    description: 'Month',
  },
  chargingAstrixNew: {
    id: 'planCard.chargingAstrixNew',
    defaultMessage:
      'Free of charge for {numberOfYears} years, charged {monthlyCost} per month after {endOfFreePeriodDate}.',
    description: 'Astrix for chaging Tariff 2.0',
  },
  chargingAstrixOld: {
    id: 'planCard.chargingAstrixOld',
    defaultMessage:
      'Free of charge for {numberOfYears} years, until {endOfFreePeriodDate}.',
    description: 'Astrix for old chaging Tariff',
  },
  vatIncluded: {
    id: 'planCard.vatIncluded',
    defaultMessage: '*All displayed prices include taxes.',
    description: 'A mention of VAT included in the price',
  },
  extraCostPerMinute: {
    id: 'planCard.extraCostPerMinute',
    defaultMessage: '+ {costPerMinute}/min from {cheapCostTime} min',
    description: 'extra cost per minute after grace time',
  },
  extraCostPerkWh: {
    id: 'planCard.extraCostPerkWh',
    defaultMessage: '+ {numberOfKWatts} kW:  {costPerkW} {currency}/min',
    description: 'extra cost kW after first chunk',
  },
  priceAestrix: {
    id: 'planCard.priceAestrix',
    defaultMessage: '*All displayed prices include taxes.',
    description: 'Aestrix for all prices',
  },
  ac: {
    id: 'planCard.ac.title',
    defaultMessage: 'AC',
  },
  dc: {
    id: 'planCard.dc.title',
    defaultMessage: 'DC',
  },
  ionity: {
    id: 'planCard.ionity.title',
    defaultMessage: 'IONITY',
  },
  preferredTitle: {
    id: 'planCard.preferred.title',
    defaultMessage: 'Preferred',
  },
});
