import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Ownership mismatch',
    id: 'ownership.title',
  },
  warning: {
    defaultMessage:
      'The vehicle you are trying to access does not belong to your account.',
    id: 'ownership.warning',
  },
  user: {
    defaultMessage: 'You are currently logged in as {email}.',
    id: 'ownership.user',
  },
  actionLogout: {
    defaultMessage: 'Switch account',
    id: 'ownership.actionLogout',
    description: 'Action button',
  },
  actionGarage: {
    defaultMessage: 'Back to my garage',
    id: 'ownership.actionGarage',
    description: 'Action button',
  },
});
