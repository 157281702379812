import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  text: {
    defaultMessage:
      'Free of charge until {date}. Billed {amount} monthly after that date. Subscription can be canceled monthly.',
    id: 'tariff.inclusivePeriod.text',
  },
  narText: {
    defaultMessage:
      'On {date}, your Porsche Charging Service Inclusive Period will end. After this date, you will switch to Porsche Charging Service.',
    id: 'tariff.inclusivePeriod.nar.text',
  },
  euV1Inclusive: {
    defaultMessage:
      'Inclusive period without base fee until {date}. After your inclusive period, you can choose between on of the two new plans subject to change, Porsche Chargin Service or Porsche Chargin Service Plus.',
    id: 'tariff.inclusivePeriod.euV1Inclusive',
  },
});
