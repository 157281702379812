import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PStepperHorizontal,
  PStepperHorizontalItem,
  StepperHorizontalUpdateEvent,
} from '@porsche-design-system/components-react';
import { FormattedMessage } from 'react-intl';
import style from 'components/stepper/stepper.module.scss';
import { InclusiveOnly } from 'components/context/vehicle/discriminators/InclusiveOnly';
import { ExclusiveOnly } from 'components/context/vehicle/discriminators/ExclusiveOnly';
import { messages } from './stepper.messages';
import { useRoutes } from 'hooks/useRoutes';
import { Routes } from 'router/routes';

interface StepperProps {
  currentStep: Step;
}

export enum Step {
  Tariff = 0,
  Activation = 1,
  Confirmation = 2,
}

const generateState = (step: Step, currentStep: Step) => {
  if (step === currentStep) {
    return 'current';
  }
  if (step < currentStep) {
    return 'complete';
  }
  return undefined;
};

export const Stepper: FC<StepperProps> = (props) => {
  const navigate = useNavigate();
  const routes = useRoutes();

  const onUpdate = (e: CustomEvent<StepperHorizontalUpdateEvent>) => {
    if (e.detail.activeStepIndex === 0) navigate(routes(Routes.TariffSelect));
    if (e.detail.activeStepIndex === 1) navigate(routes(Routes.Activation));
  };

  return (
    <div className={style.container}>
      <PStepperHorizontal onUpdate={onUpdate}>
        <InclusiveOnly>
          <PStepperHorizontalItem state={generateState(0, props.currentStep)}>
            <div
              data-testid={`stepper-step-${
                generateState(0, props.currentStep) || 'disabled'
              }`}
            >
              <FormattedMessage {...messages.info} />
            </div>
          </PStepperHorizontalItem>
        </InclusiveOnly>
        <ExclusiveOnly>
          <PStepperHorizontalItem state={generateState(0, props.currentStep)}>
            <div
              data-testid={`stepper-step-${
                generateState(0, props.currentStep) || 'disabled'
              }`}
            >
              <FormattedMessage {...messages.selection} />
            </div>
          </PStepperHorizontalItem>
        </ExclusiveOnly>
        <PStepperHorizontalItem state={generateState(1, props.currentStep)}>
          <div
            data-testid={`stepper-step-${
              generateState(1, props.currentStep) || 'disabled'
            }`}
          >
            <FormattedMessage {...messages.activation} />
          </div>
        </PStepperHorizontalItem>
        <PStepperHorizontalItem state={generateState(2, props.currentStep)}>
          <div
            data-testid={`stepper-step-${
              generateState(2, props.currentStep) || 'disabled'
            }`}
          >
            <FormattedMessage {...messages.confirmation} />
          </div>
        </PStepperHorizontalItem>
      </PStepperHorizontal>
    </div>
  );
};
