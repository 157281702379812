import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  historyTabLabel: {
    id: 'management.historyTabLabel',
    defaultMessage: 'Charging history',
    description: 'Label history tab',
  },
  subscriptionManagementLabel: {
    id: 'management.subscriptionManagementLabel',
    defaultMessage: 'Subscription',
    description: 'Label subscription management',
  },
  pageTitle: {
    id: 'management.pageTitle',
    defaultMessage: 'Charging History and Subscription',
    description: 'Page title',
  },
  pageMainText: {
    id: 'management.pageMainText',
    defaultMessage:
      'Get an overview of your charging processes at public charging stations and manage your current charging contract.',
    description: 'Page main text',
  },
  backButtonLabel: {
    id: 'management.backButtonLabel',
    defaultMessage: 'Back to My Vehicle',
    description: 'Page back button',
  },
  appButtonLabel: {
    id: 'management.appButtonLabel',
    defaultMessage: 'Back to MyPorsche app',
    description: 'App back button',
  },
});
