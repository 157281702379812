import React, { ReactNode, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import {
  NetworkStatus,
  subscriptionContext,
} from '../../../../context/subscription/SubscriptionContext';
import {
  PIcon,
  PSpinner,
  PText,
} from '@porsche-design-system/components-react';
import style from './networkBarrier.module.scss';
import { messages } from './networkBarrier.messages';
import { FormattedMessage } from 'react-intl';

interface NetworkBarrierProps {
  children?: ReactNode;
}

export const NetworkBarrier: React.FunctionComponent<NetworkBarrierProps> =
  observer((props) => {
    const context = useContext(subscriptionContext);

    if (context.network === NetworkStatus.Loading) {
      return (
        <div className={style.center}>
          <PSpinner />
          <PText size={'medium'}>
            <FormattedMessage {...messages.loading} />
          </PText>
        </div>
      );
    }

    if (context.network === NetworkStatus.Error) {
      return (
        <div className={style.center}>
          <PIcon
            name={'close'}
            size={'large'}
            aria={{ 'aria-label': 'Close icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.errorTitle} />
          </PText>
          <PText size={'small'}>
            <FormattedMessage {...messages.errorText} />
          </PText>
        </div>
      );
    }

    if (context.subscription === null) {
      return (
        <div className={style.center}>
          <PIcon
            name={'close'}
            size={'large'}
            aria={{ 'aria-label': 'Close icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.noSubscription} />
          </PText>
        </div>
      );
    }

    if (context.subscription.isExpired) {
      return (
        <div className={style.center}>
          <PIcon
            name={'close'}
            size={'large'}
            aria={{ 'aria-label': 'Close icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.expired} />
          </PText>
        </div>
      );
    }

    return <React.Fragment>{props.children}</React.Fragment>;
  });
