import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  legalNameInputLabel: {
    defaultMessage: 'Full legal name',
    id: 'businessVerification.input.label.legalName',
  },
  vatIdInputLabel: {
    defaultMessage: 'VAT ID',
    id: 'businessVerification.input.label.vatId',
  },
  cityInputLabel: {
    defaultMessage: 'Town/City',
    id: 'businessVerification.input.label.city',
  },
});
