import React from 'react';
import { FrostedItem } from '../../../components/layout/frosted/FrostedItem';
import { FrostedLayout } from '../../../components/layout/frosted/FrostedLayout';
import background from 'media/backgroundFailure.jpg';
import { FrostedHeading } from '../../../components/layout/frosted/FrostedHeading';
import { PButton, PText } from '@porsche-design-system/components-react';
import style from './errorPage.module.scss';
import { Link } from 'react-router-dom';
import { messages } from './errorPage.messages';
import { FormattedMessage } from 'react-intl';

export const ErrorPage: React.FunctionComponent = () => {
  return (
    <FrostedLayout background={background} className={style.panel}>
      <FrostedHeading>
        <FormattedMessage {...messages.title} />
      </FrostedHeading>
      <FrostedItem>
        <PText size={'large'}>
          <FormattedMessage {...messages.text} />
        </PText>
      </FrostedItem>
      <Link to={'./../../manage/contract/overview'} className={style.fullWidth}>
        <PButton className={style.fullWidth}>
          <FormattedMessage {...messages.action} />
        </PButton>
      </Link>
    </FrostedLayout>
  );
};
