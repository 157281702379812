import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Charging Tariff Activation',
    id: 'checkout.title',
  },
  addressNotAllowedError: {
    defaultMessage: 'Address must be in the current marketplace',
    id: 'checkout.addressNotAllowed.error',
  },
  backButtonLabel: {
    defaultMessage: 'Back to tariff info',
    id: 'checkout.backButtonLabel',
  },
  bussinessVerificationActionButtonLabel: {
    defaultMessage: 'Verify the information',
    id: 'checkout.bussinessVerificationActionButtonLabel',
  },
  bussinessVerificationSectionTitle: {
    defaultMessage: 'Verify company information',
    id: 'checkout.bussinessVerificationSectionTitle',
  },
  billingAddressActionButtonLabel: {
    defaultMessage: 'Continue',
    id: 'checkout.billingAddressActionButtonLabel',
  },
  billingAddressSectionTitle: {
    defaultMessage: 'Billing address',
    id: 'checkout.billingAddressSectionTitle',
  },
  paymentActionButtonLabel: {
    defaultMessage: 'Continue',
    id: 'checkout.paymentActionButtonLabel',
  },
  paymentSectionTitle: {
    defaultMessage: 'Payment method',
    id: 'checkout.paymentSectionTitle',
  },
  deliveryAddressActionButtonLabel: {
    defaultMessage: 'Continue',
    id: 'checkout.deliveryAddressActionButtonLabel',
  },
  deliveryAddressSectionTitle: {
    defaultMessage: 'Delivery address for Porsche Charging Card',
    id: 'checkout.deliveryAddressSectionTitle',
  },
  deliveryAddressCheckboxLabel: {
    defaultMessage: 'Delivery address is the same as the billing address',
    id: 'checkout.deliveryAddressCheckboxLabel',
  },
  termsAndConditionsActionButtonLabel: {
    defaultMessage: 'Activate Charging Tariff',
    id: 'checkout.termsAndConditionsActionButtonLabel',
  },
  termsAndConditionsSectionTitle: {
    defaultMessage: 'Terms & Conditions',
    id: 'checkout.termsAndConditionsSectionTitle',
  },
  termsAndConditionsCheckboxLabel: {
    defaultMessage:
      'By activating the Charging Service you accept our Terms & Conditions that apply immediately upon Service activation. Read Terms & Conditions here.',
    id: 'checkout.termsAndConditionsCheckboxLabel',
  },
  verified: {
    defaultMessage: 'Verified',
    description: 'Verified checkmark once the business info has been verified.',
    id: 'checkout.verified',
  },
});
