import React from 'react';
import style from 'components/slider/bullet.module.scss';
import { Link } from 'react-router-dom';

interface BulletProps {
  /**
   * Mark a bullet as active, disables on click on itself
   */
  active: boolean;
  /**
   * Makes an inactive bullet clickable
   */
  link: string | null;
}

/**
 * Single bullet of the {@Link Slider}
 * @param props
 * @constructor
 */
export const Bullet: React.FunctionComponent<BulletProps> = React.memo(
  (props) => {
    if (props.active) {
      return (
        <Link
          to={props.link || ''}
          className={style.linkDisabled}
          data-testid={'bullet-active-true'}
        >
          <div className={style.bulletActive} />
        </Link>
      );
    }

    const linkDisabled = props.link ? '' : style.linkDisabled;

    return (
      <Link
        to={props.link || ''}
        className={linkDisabled}
        data-testid={'bullet-active-false'}
      >
        <div className={style.bullet} />
      </Link>
    );
  },
);
