import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { vehicleContext } from '../../context/vehicle/VehicleContextProvider';
import { Ownership as OwnershipState } from '../../context/vehicle/VehicleContext';
import { Portal } from '../portal/Portal';
import {
  PButton,
  PButtonGroup,
  PHeading,
  PModal,
  PText,
} from '@porsche-design-system/components-react';
import style from './ownership.module.scss';
import { messages } from './ownership.messages';
import { FormattedMessage } from 'react-intl';
import { logout } from '../../../utils/oauth';
import { Link } from 'react-router-dom';

export const Ownership: React.FunctionComponent = observer(() => {
  const context = useContext(vehicleContext);

  if (context.ownership !== OwnershipState.External) {
    return null;
  }

  return (
    <Portal>
      <PModal
        open={true}
        dismissButton={false}
        aria={{ 'aria-label': 'Owner mismatch' }}
      >
        <div slot={'heading'}>
          <PHeading>
            <FormattedMessage {...messages.title} />
          </PHeading>
        </div>
        <div className={style.content}>
          <PText>
            <FormattedMessage {...messages.warning} />
          </PText>
          <PText>
            <FormattedMessage
              {...messages.user}
              values={{ email: <strong>{context.ownershipEmail}</strong> }}
            />
          </PText>
        </div>
        <PButtonGroup className="footer">
          <PButton onClick={logout}>
            <FormattedMessage {...messages.actionLogout} />
          </PButton>
          <Link to={process.env.REACT_APP_MY_PORSCHE_URL!}>
            <PButton type="button" variant="secondary" icon="close">
              <FormattedMessage {...messages.actionGarage} />
            </PButton>
          </Link>
        </PButtonGroup>
      </PModal>
    </Portal>
  );
});
