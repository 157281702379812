import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  freeUntil: {
    defaultMessage: 'Free until {date}',
    id: 'flyout.overview.baseFee.inclusivePeriod.freeUntil',
  },
  afterwardsMonthly: {
    defaultMessage: 'Charged {amount}/month afterwards',
    id: 'flyout.overview.baseFee.inclusivePeriod.afterwards.monthly',
  },
});
