import { PSpinner, PText } from '@porsche-design-system/components-react';
import style from './vehicleBarrier.module.scss';
import { messages } from './vehicleBarrier.messages';
import { FormattedMessage } from 'react-intl';

const LoadingVehicleSpinner = () => {
  return (
    <div className={style.center} data-testid={'vehicleBarrier-loading'}>
      <PSpinner />
      <PText size={'medium'}>
        <FormattedMessage {...messages.loading} />
      </PText>
    </div>
  );
};

export default LoadingVehicleSpinner;
