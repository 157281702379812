import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  deleteMethodDisclaimer: {
    id: 'paymentWidget.deleteMethodDisclaimer',
    defaultMessage:
      'Deleting payment methods can only be done through the {paymentManagerLabel}.',
    description: 'Label for deletion of payment method',
  },
  paymentManagerLabel: {
    id: 'paymentWidget.paymentManagerLabel',
    defaultMessage: 'Payment Manager',
    description: 'Label for payment manager link',
  },
  saveChangesButtonLabel: {
    id: 'paymentWidget.saveChangesButtonLabel',
    defaultMessage: 'Save changes',
    description: 'Save payment method change button label',
  },
});
