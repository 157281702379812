import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Routes } from 'router/routes';

export const useRoutes = () => {
  const { pathname } = useLocation();
  const { marketplace, locale, vin } = useParams();

  const isMobile = useMemo(() => pathname.startsWith('/m/'), [pathname]);

  return (route: Routes) => {
    return `${isMobile ? '/m' : ''}/${marketplace}/${locale}/${vin}/${route}`;
  };
};
