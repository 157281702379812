import { FC } from 'react';
import { PText } from '@porsche-design-system/components-react';

import style from './addressPreview.module.scss';

export type Address = {
  addressId: string;
  city: string;
  coName?: string;
  companyName1?: string;
  country: string;
  houseNumber: string;
  postalCode: string;
  street1: string;
  vatId?: string;
  usage?: string;
};

interface AddressPreviewProps {
  address: Address;
}

export const AddressPreview: FC<AddressPreviewProps> = ({ address }) => {
  return (
    <div className={style.root}>
      {address.companyName1 ? (
        <PText color="contrast-medium" size="small">
          {address.companyName1}
        </PText>
      ) : null}
      {address.coName ? (
        <PText color="contrast-medium" size="small">
          {address.coName}
        </PText>
      ) : null}
      <PText color="contrast-medium" size="small">
        {address.street1} {address.houseNumber}
      </PText>
      <PText color="contrast-medium" size="small">
        {address.city} {address.postalCode}
      </PText>
      <PText color="contrast-medium" size="small">
        {address.country}
      </PText>
    </div>
  );
};
