import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  message: {
    id: 'adressError.message',
    defaultMessage: 'Address must be in the current marketplace',
    description:
      'Error message that appears if the address country doesnt match current marketplace',
  },
});
