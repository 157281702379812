import React from 'react';
import style from './header.module.scss';
import { PHeading } from '@porsche-design-system/components-react';
import { messages } from './header.messages';
import { FormattedMessage } from 'react-intl';

export const Header: React.FunctionComponent = () => {
  return (
    <div className={style.root} data-testid={'header'}>
      <PHeading size={'x-large'}>
        <FormattedMessage {...messages.title} />
      </PHeading>
    </div>
  );
};
