import React from 'react';
import style from './preloadImage.module.scss';

import backgroundFailure from 'media/backgroundFailure.jpg';
import backgroundSuccess from 'media/backgroundSuccess.png';
import backgroundTariff from 'media/backgroundTariff.jpg';
import backgroundWelcome from 'media/backgroundWelcome.png';

export const preloadList = [
  backgroundFailure,
  backgroundSuccess,
  backgroundTariff,
  backgroundWelcome,
];

/**
 * Component to preload a hardcoded set of images.
 * @constructor
 */
export const PreloadImages: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      {preloadList.map((image, key) => {
        return (
          <img
            alt={'Preloaded'}
            src={image}
            className={style.image}
            key={key}
          />
        );
      })}
    </React.Fragment>
  );
};
