export function formatDate(date = new Date(), isInput = true) {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', {
    month: '2-digit',
  });
  const day = date.toLocaleString('default', { day: '2-digit' });

  if (isInput) {
    return [year, month, day].join('-');
  }

  return [day, month, year].join('.');
}
