import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  loading: {
    defaultMessage: 'Loading subscription...',
    id: 'flyout.overview.networkBarrier.loading',
  },
  errorTitle: {
    defaultMessage: 'Failed to load subscription',
    id: 'flyout.overview.networkBarrier.errorTitle',
  },
  errorText: {
    defaultMessage: 'Please try again later',
    id: 'flyout.overview.networkBarrier.errorText',
  },
  noSubscription: {
    defaultMessage: 'This vehicle has no subscription',
    id: 'flyout.overview.networkBarrier.noSubscription',
  },
  expired: {
    defaultMessage: 'Your subscription expired',
    id: 'flyout.overview.networkBarrier.expired',
  },
});
