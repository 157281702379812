import React, { JSXElementConstructor, ReactElement } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: ReactElement<unknown, JSXElementConstructor<unknown>>;
}

/**
 * Portal, portals the children to document.body
 * @param props
 * @constructor
 */
export const Portal: React.FunctionComponent<PortalProps> = (props) => {
  const portalBody = document.getElementById('portals')!;
  return ReactDOM.createPortal(props.children, portalBody);
};
