import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { PButton } from '@porsche-design-system/components-react';
import { Link } from 'react-router-dom';
import style from './action.module.scss';
import { FormattedMessage } from 'react-intl';
import { messages } from './action.messages';

interface ActionProps {
  onClick: () => void;
  loading: boolean;
  disableAction: boolean;
}

export const Action: React.FunctionComponent<ActionProps> = observer(
  (props) => {
    const cancel = useMemo(() => {
      if (props.loading) {
        return (
          <PButton variant={'secondary'} disabled={true}>
            <FormattedMessage {...messages.cancel} />
          </PButton>
        );
      }

      return (
        <Link to={'./../manage/contract/overview'}>
          <PButton variant={'secondary'}>
            <FormattedMessage {...messages.cancel} />
          </PButton>
        </Link>
      );
    }, [props.loading]);

    return (
      <div className={style.root}>
        <PButton
          disabled={props.disableAction}
          loading={props.loading}
          onClick={props.onClick}
        >
          <FormattedMessage {...messages.select} />
        </PButton>
        {cancel}
      </div>
    );
  },
);
