import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'tariff.select.title',
    defaultMessage: 'Choose your tempo',
    description: 'Title of the tariff select page',
  },
  proceed: {
    id: 'tariff.select.proceed',
    defaultMessage: 'Proceed to activation',
    description: 'Proceed button',
  },
  compare: {
    id: 'tariff.select.compare',
    defaultMessage: 'Compare Tariffs',
    description: 'Comparison button',
  },
  loadingTariffs: {
    defaultMessage: 'Loading available tariffs...',
    id: 'tariff.select.loading.tariffs',
  },
  unavailableTariffs: {
    defaultMessage: 'There are no plans available',
    id: 'tariff.select.unavailable.tariffs',
  },
  singlePlanTitle: {
    id: 'tariff.select.singlePlanTitle',
    defaultMessage: 'Your Charging Tariff',
    description: 'Title of the tariff select page when single plan available',
  },
});
