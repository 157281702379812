import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from './useParams';
import { sendErrorToSentry } from 'utils/sentry';

type LegalDoc = {
  type: string;
  version: string;
  url: string;
  pdfUrl: string;
};

type LegalDocs = {
  countryLegalDocs: LegalDoc[];
};

export const useTermsAndConditions = () => {
  const { marketplace, locale } = useParams();
  const [data, setData] = useState<LegalDocs | null>(null);

  const fetchLegalDocs = useCallback(
    (marketplace: string) =>
      fetch(
        `${process.env.REACT_APP_TAC_API_URL}?country=${marketplace}&locale=${locale}`,
      )
        .then((response) => {
          return response.json();
        })
        .catch((err) => {
          console.log(err);
          sendErrorToSentry(err);
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (marketplace && !data) {
      fetchLegalDocs(marketplace).then((fetchedData) => setData(fetchedData));
    }
  }, [marketplace, data, fetchLegalDocs]);

  const { tacDoc, dpgDoc, touDoc } = useMemo(() => {
    if (!data || !data.countryLegalDocs) {
      return { tacDoc: null, dpgDoc: null, touDoc: null };
    }

    const tacDoc =
      data.countryLegalDocs.find((doc) => doc.type === 'TAC')?.pdfUrl || null;
    const dpgDoc =
      data.countryLegalDocs.find((doc) => doc.type === 'DPG')?.pdfUrl || null;
    const touDoc =
      data.countryLegalDocs.find((doc) => doc.type === 'TOU')?.pdfUrl || null;

    return { tacDoc, dpgDoc, touDoc };
  }, [data]);

  return { tacDoc, dpgDoc, touDoc };
};

export default useTermsAndConditions;
