import { FC } from 'react';
import { PText } from '@porsche-design-system/components-react';

import { PaymentData } from '../PaymentWidget/usePaymentWidget';

import style from './paymentPreview.module.scss';

interface PaymentPreviewProps {
  payment: PaymentData;
}

export const PaymentPreview: FC<PaymentPreviewProps> = ({ payment }) => {
  const paymentPreviewSuffix = payment?.additionalData?.lastDigits
    ? `**** ${payment?.additionalData?.lastDigits}`
    : payment.paymentMethodDetails?.strongCustomerAuthenticationData
        ?.shopperEmail;
  return (
    <div className={style.root}>
      <PText color="contrast-medium" size="small">
        {`${payment?.paymentMethodDetails?.paymentMethod?.typeDisplayName} - ${paymentPreviewSuffix}`}
      </PText>
    </div>
  );
};
