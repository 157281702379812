import {
  PCheckboxWrapper,
  PDivider,
} from '@porsche-design-system/components-react';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { observer } from 'mobx-react-lite';
import { AddressWidget } from 'pages/activation/components/AddressWidget';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { messages } from './adressForm.messages';
import style from './style.module.scss';

const CardAddress = observer(() => {
  const intl = useIntl();
  const authentication = useAuthentication();
  const [
    cardDeliveryAddressSameAsBilling,
    setCardDeliveryAddressSameAsBilling,
  ] = useState(true);

  return (
    <section className={style.pb3}>
      <PCheckboxWrapper
        className={style.pb2}
        hideLabel={false}
        label={intl.formatMessage(messages.sameAddressCheck)}
      >
        <input
          type="checkbox"
          name="cardDeliveryAddressSameAsBilling"
          checked={cardDeliveryAddressSameAsBilling}
          onChange={() =>
            setCardDeliveryAddressSameAsBilling(
              !cardDeliveryAddressSameAsBilling,
            )
          }
        />
      </PCheckboxWrapper>
      <PDivider color="contrast-low" className={style.py1} />

      {!cardDeliveryAddressSameAsBilling && (
        <AddressWidget
          authorizationToken={authentication.token}
          name="cardAddressId"
          identifier="cardAddressId"
        />
      )}
    </section>
  );
});

export default CardAddress;
