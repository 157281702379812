import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  info: {
    id: 'stepper.info',
    defaultMessage: 'Tariff info',
    description: 'Stepper item',
  },
  selection: {
    id: 'stepper.selection',
    defaultMessage: 'Tariff selection',
    description: 'Stepper item',
  },
  activation: {
    id: 'stepper.activation',
    defaultMessage: 'Activation',
    description: 'Stepper item',
  },
  confirmation: {
    id: 'stepper.confirmation',
    defaultMessage: 'Confirmation',
    description: 'Stepper item',
  },
});
