import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  PButton,
  PSpinner,
  PText,
} from '@porsche-design-system/components-react';
import { FrostedHeading } from 'components/layout/frosted/FrostedHeading';
import { FrostedItem } from 'components/layout/frosted/FrostedItem';
import style from 'pages/tariff/select/selectPage.module.scss';
import { messages } from './selectPage.messages';
import { usePlan } from 'pages/invitation/usePlan';
import { RawPlan } from 'components/context/plan/planContext.types';
import { AdditionalDisclaimer } from 'pages/change/select/additionalDisclaimer/AdditionalDisclaimer';
import { Region } from 'hooks/usePlans';
import { TariffSelection } from '../../change/select/tariffSelection/TariffSelection';

// plan variants for each major version of the PCS
export enum PlanVariant {
  V1_PREMIUM_INCLUSIVE = 'V1_PREMIUM_INCLUSIVE',
  V2_PREMIUM_INCLUSIVE = 'V2_PREMIUM_INCLUSIVE',
  V2_PREMIUM = 'V2_PREMIUM',
  V2_BASIC = 'V2_BASIC',
}

export const SelectPage: FC = () => {
  const [selected, setSelected] = useState<PlanVariant | null>(null);

  const onPlanChanged = useCallback((planId: string, plan: RawPlan) => {
    setSelected(plan.variant);
  }, []);

  const { isLoading, plans, marketplace, region, planVariants } =
    usePlan(onPlanChanged);
  const isEuRegion = region === Region.EU;
  const onChange = useCallback((variant: PlanVariant) => {
    setSelected(variant);
  }, []);

  if (isLoading) {
    return (
      <div className={style.center}>
        <PSpinner />
        <PText>
          <FormattedMessage {...messages.loadingTariffs} />
        </PText>
      </div>
    );
  }

  if (!plans?.length || !selected) {
    return (
      <PText>
        <FormattedMessage {...messages.unavailableTariffs} />
      </PText>
    );
  }

  return (
    <div className={style.root} data-testid={'selectPage'}>
      <FrostedHeading>
        <FormattedMessage
          {...(plans.length === 1 ? messages.singlePlanTitle : messages.title)}
        />
      </FrostedHeading>
      <FrostedItem>
        <TariffSelection
          planVariant={selected}
          onChange={onChange}
          marketplace={marketplace}
          plans={plans}
          disabled={false}
        />
      </FrostedItem>
      <div className={style.actionContainer}>
        <Link
          to={`../../activation?selected=${selected}`}
          className={style.primaryButton}
        >
          <PButton className={style.primaryButton}>
            <FormattedMessage {...messages.proceed} />
          </PButton>
        </Link>
      </div>
      {isEuRegion ? <AdditionalDisclaimer planVariants={planVariants} /> : null}
    </div>
  );
};
