import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { PriceSegment } from '../segment/PriceSegment';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChargePointClass } from 'components/context/plan/planContext.types';
import { planContext } from '../../../context/plan/PlanContext';
import { chargingOverrides } from '../Charging';

/**
 * Display charging cost <br/>
 * Use for eu premium tariff only
 */
export const ChargingEuPremium: React.FunctionComponent = observer(() => {
  const context = useContext(planContext);
  const { messages } = useContext(chargingOverrides);
  const intl = useIntl();

  const overview = {
    ac: {
      price: context.resolveEnergyPrice(ChargePointClass.AC),
      blocking: [context.resolveBlocking(ChargePointClass.AC)],
    },
    dc: {
      price: context.resolveEnergyPrice(ChargePointClass.DC),
      blocking: [context.resolveBlocking(ChargePointClass.DC)],
    },
    preferred: {
      price: context.resolveEnergyPrice(ChargePointClass.PREFERRED),
      blocking: [],
    },
  };

  return (
    <React.Fragment>
      <div style={{ display: 'none' }} data-testid={'chargingEuPremium'} />
      <PriceSegment
        title={<FormattedMessage {...messages.ac} />}
        {...overview.ac}
      />
      <PriceSegment
        title={<FormattedMessage {...messages.dc} />}
        {...overview.dc}
      />
      <PriceSegment
        title={<FormattedMessage {...messages.preferredTitle} />}
        subTitle={`Dealership (${intl.formatMessage(messages.dcOnly)}), Porsche Charging Lounges, IONITY`}
        {...overview.preferred}
      />
    </React.Fragment>
  );
});
