import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  select: {
    defaultMessage: 'Select tariff',
    id: 'tariff.change.action.select',
  },
  change: {
    defaultMessage: 'Change the tariff',
    id: 'tariff.change.action.change',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'tariff.change.action.cancel',
  },
});
