import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'paimentForm.title',
    defaultMessage: 'Select a payment method',
    description: 'Title for the paiment selector',
  },
  subtitle: {
    id: 'paimentForm.subtitle',
    defaultMessage: 'Set a new payment method or select an existing one',
    description: 'Subtitle for the paiment selector',
  },
});
