import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Tariff changed',
    id: 'tariff.change.success.title',
  },
  text: {
    defaultMessage: 'Changes will take effect at {date}.',
    id: 'tariff.change.success.text',
  },
  action: {
    defaultMessage: 'Go back to my tariff',
    id: 'tariff.change.success.action',
  },
});
