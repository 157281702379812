import { NotFoundPage } from 'pages/404/NotFoundPage';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useValidateUrlPattern } from 'hooks/useValidateUrlPattern';

const RouteHandler: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const { validatedPathname, location } = useValidateUrlPattern();

  useEffect(() => {
    if (validatedPathname && location.pathname !== validatedPathname) {
      navigate(`${validatedPathname}${location.search}`, { replace: true });
    }
  }, [location.pathname, location.search, navigate, validatedPathname]);

  if (!validatedPathname) {
    return <NotFoundPage />;
  }

  return <>{children}</>;
};

export default RouteHandler;
