import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'tariff.welcome.title',
    defaultMessage: 'Welcome to our Porsche Charging Service',
    description: 'Title of the welcome page',
  },
  text: {
    id: 'tariff.welcome.text',
    defaultMessage:
      'Take advantage of an exciting charging experience at a large number of public charging points.',
    description: 'Text of the welcome page',
  },
  action: {
    id: 'tariff.welcome.action',
    defaultMessage: 'Continue',
    description: 'Button with link to next step',
  },
});
