import {
  PHeading,
  PRadioButtonWrapper,
  PText,
} from '@porsche-design-system/components-react';
import { FormattedMessage } from 'react-intl';
import { messages } from './planCard.messages';
import style from './style.module.scss';
import { usePlan } from 'pages/invitation/usePlan';
import { observer } from 'mobx-react-lite';
import Plan from './Plan';
import React from 'react';
import PlanCardsSkeleton from './Skeleton/PlanCardsSkeleton';
import { RawPlan } from 'components/context/plan/planContext.types';
import { PlanContextProvider } from 'components/context/plan/PlanContextProvider';
import { AdditionalDisclaimer } from 'pages/change/select/additionalDisclaimer/AdditionalDisclaimer';
import { Region } from 'hooks/usePlans';
import { Disclaimer } from 'components/tariff/components/disclaimer/Disclaimer';

interface IProps {
  onPlanChanged: (planId: string, plan: RawPlan) => void;
}

const PlanSelection: React.FC<IProps> = observer(({ onPlanChanged }) => {
  const { isLoading, plans, marketplace, locale, region, planVariants } =
    usePlan(onPlanChanged);
  const isEuRegion = region === Region.EU;

  if (isLoading) {
    return <PlanCardsSkeleton />;
  }

  return (
    <>
      <PHeading size="x-large" className={style.subTitle}>
        <FormattedMessage {...messages.tariffActivation} />
      </PHeading>
      <PText className={style.pt2} size="small" weight="regular">
        <FormattedMessage {...messages.tariffActivationSubtitle} />
      </PText>
      <div className={style.plantList}>
        {plans &&
          plans.map((plan, index) => (
            <label className={style.planCardLabel} key={plan.id}>
              <PRadioButtonWrapper>
                <input
                  type="radio"
                  name="chargingTariffId"
                  defaultChecked={index === 0}
                  value={plan.variant}
                  onChange={() => onPlanChanged(plan.id, plan)}
                />
              </PRadioButtonWrapper>
              <PlanContextProvider
                plan={plan}
                marketplace={marketplace}
                locale={locale}
              >
                <Plan
                  rawPlan={plan}
                  selected={index === 0 && plans.length === 2}
                />
              </PlanContextProvider>
            </label>
          ))}
      </div>
      {isEuRegion ? <AdditionalDisclaimer planVariants={planVariants} /> : null}
      {plans && (
        <PText
          className={style.pt2}
          size="x-small"
          weight="thin"
          color="neutral-contrast-medium"
        >
          <PlanContextProvider
            plan={plans[0]}
            marketplace={marketplace}
            locale={locale}
          >
            <Disclaimer />
          </PlanContextProvider>
        </PText>
      )}
    </>
  );
});

export default PlanSelection;
