import { useRouteLoaderData } from 'react-router-dom';
import { useMemo } from 'react';

export interface Authentication {
  /**
   * Tokens are valid for 12h and do not get refreshed during a session
   */
  token: string;
  /**
   * ApiKey / our clientID of the apiGee SPA app
   */
  apiKey: string;
  /**
   * ciamId of the logged in user
   */
  ciamId: string;
}

/**
 * Returns an {@link Authentication} object of the current user, uses {@link tokenLoader} under the hood<br/>
 * Always returns data, out of the context the data are empty strings
 */
export const useAuthentication = (): Authentication => {
  const rootData = useRouteLoaderData('root') as {
    authorizationToken?: string;
    widgetApiKey?: string;
    ciamId?: string;
  };

  const data = rootData;
  return useMemo(() => {
    return {
      token: data.authorizationToken || '',
      apiKey: data.widgetApiKey || '',
      ciamId: data.ciamId || '',
    };
  }, [data.authorizationToken, data.widgetApiKey, data.ciamId]);
};
