import {
  applyPolyfills,
  defineCustomElements as definePorscheNavigation,
} from '@porschehn/navigation/loader';
import { FC, useEffect } from 'react';
import { getAuth0Client } from 'utils/oauth';

type HeaderProps = {
  locale: string;
};

export const Header: FC<HeaderProps> = ({ locale }) => {
  useEffect(() => {
    applyPolyfills().then(() => {
      definePorscheNavigation();
    });

    // window.addEventListener('phnLogin', (e) => {});
    window.addEventListener('phnLogout', async (e) => {
      e.preventDefault();
      const auth0 = getAuth0Client();
      await auth0.logout({
        logoutParams: {
          returnTo:
            process.env.REACT_APP_MY_PORSCHE_URL || 'https://my.porsche.com',
        },
      });
    });
  }, []);

  return (
    <phn-header
      locale={locale}
      env={process.env.REACT_APP_ENV !== 'prod' ? 'preview' : 'production'}
      mode="navbar"
      theme="light"
    />
  );
};
