import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  included: {
    defaultMessage: 'All displayed prices include taxes.',
    id: 'flyout.overview.disclaimer.included',
  },
  excluded: {
    defaultMessage: 'Taxes and other fees are not included.',
    id: 'flyout.overview.disclaimer.excluded',
  },
  excludedYearly: {
    defaultMessage:
      'Porsche Charging Service Premium is {yearly} annual subscription billed monthly at {monthly}',
    description:
      'Example: Taxes and other fees are not included. The total cost of the tariff is $99 per year. Invoices are sent monthly for $8.25. The contract is valid for 12 months after the activation date. Your current payment method will be used for the transaction.',
    id: 'flyout.overview.disclaimer.excludedYearly',
  },
  seeInApp: {
    defaultMessage:
      'See detailed prices at charging stations or in the app before the charging session.',
    id: 'flyout.overview.disclaimer.seeInApp',
  },
});
