import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Order Summary',
    id: 'orderSummary.title',
  },
  inclusivePlanText: {
    defaultMessage:
      'After the inclusive period ends, {tariffName} will be charged at the regular price of {monthlyPrice} per month including taxes. Prices are subject to change. You will be able to switch at any time.',
    description:
      "{tariffName} and {monthlyPrice} will be replaced automatically with their respective values. 'After the inclusive period ends, Porsche Charging Service Plus will be charged at the regular price of £29.99 per month including taxes.'",
    id: 'orderSummary.inclusivePlanText.v3',
  },
  exclusivePlanText: {
    defaultMessage:
      'All prices include taxes. On the renewal date each month the subscription will be renewed automatically and your preferred payment method for Porsche Charging Services will be charged. Prices are subject to change.',
    id: 'orderSummary.exclusivePlanText',
  },
  startDateText: {
    defaultMessage: 'Start date: {date}',
    id: 'orderSummary.startDateText',
  },
  expirationDateText: {
    defaultMessage: 'Expiration of inclusive period: {date}',
    id: 'orderSummary.expirationDateText',
  },
  totalPrice: {
    defaultMessage: 'Total price:',
    id: 'orderSummary.totalPrice',
  },
  showDetailsText: {
    defaultMessage: 'Show details',
    id: 'orderSummary.showDetailsText',
  },
  narInclusiveText: {
    defaultMessage:
      'All prices exclude taxes and other fees. On the renewal date each month the subscription will be renewed automatically and your preferred payment method for Porsche Charging Services will be charged. Prices are subject to change.',
    id: 'orderSummary.narInclusiveText',
  },
  euInclusiveFollowUpPlanPriceText: {
    defaultMessage: 'Price after inclusive period: {price} per month',
    id: 'orderSummary.euInclusiveFollowUpPlanPriceText',
  },
});
