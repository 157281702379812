import { useParams as useRouterParams } from 'react-router-dom';

export type UrlParams = {
  marketplace: string;
  locale: string;
  vin: string;
};

export const defaultMarketplace = 'gb';

export const defaultLocale = 'en-GB';

export const useParams = (): UrlParams => {
  const { marketplace, locale, vin } = useRouterParams();

  return {
    marketplace: marketplace || defaultMarketplace,
    locale: locale || defaultLocale,
    vin: vin || '',
  };
};
