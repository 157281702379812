import { FC, ReactNode } from 'react';
import { PText } from '@porsche-design-system/components-react';

import style from 'components/layout/frosted/frosted.module.scss';

interface FrostedTextProps {
  children: ReactNode;
}

export const FrostedText: FC<FrostedTextProps> = (props) => {
  return (
    <div className={style.text}>
      <PText size={'inherit'}>{props.children}</PText>
    </div>
  );
};
