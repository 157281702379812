const PCSEnabledMarketplaces = [
  'BE',
  'DK',
  'DE',
  'FI',
  'FR',
  'IE',
  'IT',
  'HR',
  'LT',
  'LU',
  'NL',
  'NO',
  'AT',
  'PL',
  'PT',
  'SE',
  'CH',
  'SK',
  'SI',
  'ES',
  'CZ',
  'GB',
  'US',
  'CA',
];

export const getIsMarketPlacePCSEnabled = (marketplace?: string) => {
  return PCSEnabledMarketplaces.includes(marketplace?.toUpperCase() || '');
};
