import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { messages } from './text.messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTariffName } from '../../../../utils/getTariffName';
import { PlanVariant } from '../../../../components/context/plan/planContext.types';
import { subscriptionContext } from '../../../../components/context/subscription/SubscriptionContext';
import { upgradeContext } from '../../../../components/context/upgrade/UpgradeContextProvider';
import {
  NetworkStatus,
  useCombinedNetworking,
} from '../../../../hooks/useCombinedNetworking/useCombinedNetworking';

export const Text: React.FunctionComponent = observer(() => {
  const subscription = useContext(subscriptionContext);
  const upgrade = useContext(upgradeContext);
  const intl = useIntl();
  const network = useCombinedNetworking([
    subscription.network,
    upgrade.network,
  ]);

  const effective = useMemo(() => {
    if (!subscription.subscription) {
      return null;
    }

    if (!subscription.subscription.nextPossibleEffectiveDate) {
      return null;
    }

    return (
      <>
        {' '}
        <FormattedMessage
          {...messages.effective}
          values={{
            date: intl.formatDate(
              new Date(subscription.subscription.nextPossibleEffectiveDate),
              {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
              },
            ),
          }}
        />
      </>
    );
  }, [intl, subscription.subscription]);

  if (
    network !== NetworkStatus.Success ||
    !subscription.subscription ||
    !subscription.subscription.country
  ) {
    return null;
  }

  /**
   * Only 1 variant to select, downgrade from premium
   */
  if (
    upgrade.planVariants.length === 1 &&
    upgrade.planVariants[0] === PlanVariant.V2_BASIC
  ) {
    return (
      <>
        <FormattedMessage
          {...messages.downgrade}
          values={{
            tariff1: getTariffName(
              subscription.subscription.currentOrLastPhase.plan.variant,
              subscription.subscription.country,
            ),
            tariff2: getTariffName(
              upgrade.planVariants[0],
              subscription.subscription.country,
            ),
          }}
        />
        {effective}
      </>
    );
  }

  /**
   * Only 1 variant to select
   */
  if (upgrade.planVariants.length === 1) {
    return (
      <>
        <FormattedMessage
          {...messages.switch}
          values={{
            tariff: getTariffName(
              upgrade.planVariants[0],
              subscription.subscription.country,
            ),
          }}
        />
        {effective}
      </>
    );
  }

  return (
    <>
      <FormattedMessage
        {...messages.select}
        values={{
          tariff1: getTariffName(
            upgrade.planVariants[0],
            subscription.subscription.country,
          ),
          tariff2: getTariffName(
            upgrade.planVariants[1],
            subscription.subscription.country,
          ),
        }}
      />
      {effective}
    </>
  );
});
