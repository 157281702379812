import { PText } from '@porsche-design-system/components-react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ChargePointRegistration,
  ChargePointRegistrationStatus,
} from '../../../../components/context/subscription/SubscriptionContext';
import { messages } from './subscriptionManagement.messages';
import style from './chargePointRegistrationWidget.module.scss';

type ChargePointRegistrationWidgetProps = {
  inactive: boolean;
  registration?: ChargePointRegistration;
};
export const ChargePointRegistrationWidget: FC<
  ChargePointRegistrationWidgetProps
> = ({ registration, inactive }) => {
  if (
    !registration ||
    registration.status === ChargePointRegistrationStatus.NOT_AVAILABLE
  ) {
    return null;
  }

  return (
    <div
      className={style.contentMainBox}
      style={{
        pointerEvents: 'none',
      }}
    >
      <div className={style.contentPreviewWrapper}>
        <div className={style.contentPreviewTextWrapper}>
          <PText
            weight="bold"
            color={inactive ? 'contrast-medium' : 'primary'}
            className={style.sectionTitle}
          >
            <FormattedMessage {...messages.chargePointTitle} />
          </PText>

          {registration.status === ChargePointRegistrationStatus.AVAILABLE && (
            <PText size="x-small">
              <FormattedMessage
                {...messages.chargePointRegistrationAvailable}
              />
            </PText>
          )}

          {registration.status ===
            ChargePointRegistrationStatus.ACTIVATION_PENDING && (
            <PText size="x-small">
              <FormattedMessage {...messages.chargePointRegistrationPending} />
            </PText>
          )}

          {registration.status === ChargePointRegistrationStatus.REGISTERED && (
            <PText size="x-small">{registration.email}</PText>
          )}
        </div>
      </div>
    </div>
  );
};
