import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  PTabs,
  PTabsItem,
  PHeading,
  PText,
  PLinkPure,
  TabsUpdateEvent,
} from '@porsche-design-system/components-react';

import { SubscriptionManagement } from './components/subscriptionManagement/SubscriptionManagement';
import { SubscriptionHistoryList } from './components/SubscriptionHistoryList/SubscriptionHistoryList';
import { useIntl } from 'react-intl';
import { messages } from './management.module.messages';

import { SubscriptionContextProvider } from '../../components/context/subscription/SubscriptionContextProvider';
import { deviceContext } from '../../components/context/device/DeviceContextProvider';

import style from './management.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Flyout } from '../../components/manage/flyout/Flyout';
import { UpgradeContextProvider } from '../../components/context/upgrade/UpgradeContextProvider';

export const ManagementPage: FC = () => {
  const intl = useIntl();
  const { returnUrl, isReturnUrlWeb } = useContext(deviceContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { vin, marketplace, locale } = useParams();

  useEffect(() => {
    const isOnlyManageRoute = /manage\b$/.test(location.pathname);

    if (isOnlyManageRoute) {
      navigate(`${location.pathname}/history`, { replace: true });
    }
  }, [location, navigate]);

  const activeTabIndex = useMemo(
    () => (location.pathname.includes('history') ? 0 : 1),
    [location.pathname],
  );

  const onActiveTabUpdate = useCallback(
    (event: CustomEvent<TabsUpdateEvent>) => {
      const isOnlyManageRoute = /manage\b$/.test(location.pathname);

      if (!isOnlyManageRoute) {
        const routePartToNavigateTo =
          event.detail.activeTabIndex === 0 ? 'history' : 'contract';

        if (location.pathname.includes(routePartToNavigateTo)) {
          return;
        }

        const pathnameSplited = location.pathname.split('/');
        const newPathname = pathnameSplited
          .slice(0, pathnameSplited.length - 1)
          .join('/');

        navigate(`${newPathname}/${routePartToNavigateTo}`);
      }
    },
    [navigate, location.pathname],
  );

  const backButtonWebUrl = `${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/vehicles/${vin}`;

  return (
    <SubscriptionContextProvider>
      <UpgradeContextProvider>
        <div className={style.gridgrid}>
          <div className={style.mainContentWrapper}>
            <PLinkPure
              href={returnUrl ? returnUrl : backButtonWebUrl}
              icon="arrow-left"
              className={style.backButton}
            >
              {intl.formatMessage(
                isReturnUrlWeb
                  ? messages.backButtonLabel
                  : messages.appButtonLabel,
              )}
            </PLinkPure>

            <PHeading className={style.pageTitle}>
              {intl.formatMessage(messages.pageTitle)}
            </PHeading>

            <PText className={style.mainText}>
              {intl.formatMessage(messages.pageMainText)}
            </PText>

            <PTabs
              weight="semibold"
              size="medium"
              activeTabIndex={activeTabIndex}
              onUpdate={onActiveTabUpdate}
            >
              <PTabsItem
                label={intl.formatMessage(messages.historyTabLabel)}
                className={style.tabItem}
              >
                <SubscriptionHistoryList />
              </PTabsItem>
              <PTabsItem
                label={intl.formatMessage(messages.subscriptionManagementLabel)}
                className={style.tabItem}
              >
                <SubscriptionManagement />
              </PTabsItem>
            </PTabs>
            <Flyout />
          </div>
        </div>
      </UpgradeContextProvider>
    </SubscriptionContextProvider>
  );
};
