import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'confirmation.success.title',
    defaultMessage: 'Charging services activated!',
    description: 'Title of the success page',
  },
  subtitle: {
    id: 'confirmation.success.subtitle',
    defaultMessage: "Here's what happens next",
    description: 'Subtitle of the success page',
  },
  procedure1: {
    id: 'confirmation.success.procedure1',
    defaultMessage:
      "Before your car arrives, you'll receive your Porsche Charging Card which you can use to start your charging sessions.",
    description: 'Single item of an action list',
  },
  procedure2: {
    id: 'confirmation.success.procedure2',
    defaultMessage:
      'View & manage your Porsche Charging Service subscription inside the My Porsche App on your phone',
    description: 'Single item of an action list',
  },
  procedure4: {
    id: 'confirmation.success.procedure4',
    defaultMessage:
      'Use the My Porsche App to start charging immediately, even before your Porsche Charging Card arrives',
    description: 'Single item of an action list',
  },
  procedure3: {
    id: 'confirmation.failure.procedure3',
    defaultMessage: 'Be ready for the seamless driving and charging experience',
    description: 'Single item of an action list',
  },
  action: {
    id: 'confirmation.success.action',
    defaultMessage: 'Go back to my car',
    description: 'Button with link to porsche profile',
  },
});
