import React, { ReactNode, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { SimpleSegment } from './SimpleSegment';
import { FormattedMessage } from 'react-intl';
import {
  Blocking,
  BlockingPrefixed,
  BlockingType,
} from 'components/context/plan/planContext.types';
import { chargingOverrides } from '../Charging';
import style from './priceSegment.module.scss';

interface SegmentProps {
  title: ReactNode;
  subTitle?: ReactNode;
  price: string | null;
  blocking: (Blocking | null)[];
}

/**
 * Generates a single row for the overview table
 */
export const PriceSegment: React.FunctionComponent<SegmentProps> = observer(
  (props) => {
    const { messages } = useContext(chargingOverrides);

    if (!props.price) {
      return null;
    }

    const subText = props.blocking.map((item) => {
      if (!item) {
        return null;
      }

      if (item.type === BlockingType.ByPower) {
        return (
          <div className={style.blocking}>
            {(item as BlockingPrefixed).prefix}
            <FormattedMessage
              {...messages.perMinuteKw}
              values={{ amount: item.price, kw: item.kw }}
            />
          </div>
        );
      }

      if (item.type === BlockingType.ByTime) {
        return (
          <div className={style.blocking}>
            {(item as BlockingPrefixed).prefix}
            <FormattedMessage
              {...messages.perMinuteStartingAt}
              values={{ amount: item.price, time: item.time }}
            />
          </div>
        );
      }

      return (
        <div className={style.blocking}>
          {(item as BlockingPrefixed).prefix}
          <FormattedMessage
            {...messages.perMinuteAdditional}
            values={{ amount: item.price }}
          />
        </div>
      );
    });

    return (
      <SimpleSegment
        title={props.title}
        subTitle={props.subTitle}
        text={
          <FormattedMessage
            {...messages.perKwh}
            values={{ amount: props.price }}
          />
        }
        subText={subText}
      />
    );
  },
);
