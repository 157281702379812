import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { PlanVariant } from 'hooks/usePlans';
import { getRegion } from 'hooks/useRegion';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchApi } from 'utils/fetchApi';
import { RawPlan } from '../../components/context/plan/planContext.types';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { defaultLocale } from 'components/context/locale/LocaleContextProvider';

const planBaseUrl = `${process.env.REACT_APP_BASE_API_URL}/plans`;
const buildFetchPlansApiUrl = (
  region: string,
  marketplace: string | undefined,
  planVariants: PlanVariant[],
) => {
  const planUrlSearchParams = new URLSearchParams();
  planUrlSearchParams.set('region', region);
  marketplace && planUrlSearchParams.set('country', marketplace.toUpperCase());
  planVariants?.forEach((variant) =>
    planUrlSearchParams.append('variants', variant),
  );
  return `${planBaseUrl}?${planUrlSearchParams}`;
};

export const usePlan = (
  onPlanChanged: (planId: string, plan: RawPlan) => void,
) => {
  const { token } = useAuthentication();
  const vehicleContextFromProvider = useContext(vehicleContext);
  const planVariants =
    vehicleContextFromProvider.entitlementObject?.planVariants;
  const entitledUntil = vehicleContextFromProvider.entitledUntil;

  const [isLoading, setIsLoading] = useState(true);

  const { marketplace, locale } = useParams<{
    marketplace: string;
    locale: string;
  }>();

  const region = getRegion(marketplace);

  const [plans, setPlans] = useState<RawPlan[]>([]);

  useEffect(() => {
    if (!planVariants?.length) {
      return;
    }

    fetchApi(buildFetchPlansApiUrl(region, marketplace, planVariants), token, {
      method: 'GET',
    })
      .then((plansResponse) => {
        if (plansResponse.status !== 200) {
          throw new Error('Something went wrong when fetching plans');
        }

        return plansResponse.json();
      })
      .then((plans: RawPlan[]) => {
        if (plans.length === 0) {
          return;
        }

        const sortedPlans = plans.sort((a, b) =>
          a.variant > b.variant ? -1 : 1,
        );

        onPlanChanged(sortedPlans[0].id, sortedPlans[0]);
        setPlans(sortedPlans);
      })
      .catch(() => {
        console.log('Something went wrong when fetching plans');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    planVariants,
    planVariants?.length,
    region,
    marketplace,
    onPlanChanged,
    token,
  ]);

  return {
    isLoading,
    plans,
    marketplace: marketplace?.toUpperCase() || 'gb',
    locale: locale || defaultLocale,
    entitledUntil,
    region,
    planVariants,
  };
};
