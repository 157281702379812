import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  PButtonPure,
  PDivider,
  PHeading,
  PText,
} from '@porsche-design-system/components-react';

import style from './orderSummary.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from './ordersSummary.messages';
import { useRegion } from 'hooks/useRegion';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { Region } from 'hooks/usePlans';
import { fetchApi } from 'utils/fetchApi';
import {
  BasePricing,
  FeeType,
  Option,
  PlanVariant,
  Price,
} from 'components/context/plan/planContext.types';
import { getTariffName } from '../../../../utils/getTariffName';
import { useParams } from '../../../../hooks/useParams';
import { TaxPercentage } from 'components/taxPercentage/TaxPercentage';

type OrderSummaryProps = {
  vehicleName: string;
  planName: string;
  planPrice: string;
  hasFollowupPlan?: boolean;
  tariffExpiration: Date | null;
  vin: string;
  taxPercentage?: string;
  isInclusivePlan?: boolean;
};

export const OrderSummary: FC<OrderSummaryProps> = ({
  vehicleName,
  planName,
  tariffExpiration,
  vin,
  planPrice,
  hasFollowupPlan,
  taxPercentage,
  isInclusivePlan,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [premiumPlanPrice, setPremiumPlanPrice] = useState<null | string>(null);
  const params = useParams();
  const country = params.marketplace.toUpperCase();
  const locale = params.locale;
  const planRegion = useRegion();
  const { token } = useAuthentication();

  const fetchPremiumPlanPrice = useCallback(async () => {
    const planBaseUrl = `${process.env.REACT_APP_BASE_API_URL}/plans`;

    const planSearchParams = { region: planRegion } as {
      [key: string]: string;
    };

    if (country) {
      planSearchParams.country = country;
    }

    const planUrlSearchParams = new URLSearchParams(planSearchParams);

    planUrlSearchParams.append('variants', PlanVariant.V2_PREMIUM);

    const plansResponse = await fetchApi(
      `${planBaseUrl}?${planUrlSearchParams}`,
      token,
      {
        method: 'GET',
      },
    );

    const plans = await plansResponse.json();
    const options = plans[0].options[country || ''] || ([] as Option[]);
    const base = options.find((option: Option) => {
      return option.feeType === FeeType.BASE;
    }) as BasePricing | undefined;

    const getCurrencyCode = () => {
      if (base) {
        return base.pricingModel.price.grossAmount.currency;
      }

      // Just try our best at this point
      for (const item of options) {
        const unknown = item as {
          pricingModel?: {
            priceTiers?: { price: Price }[];
          };
        };

        if (
          unknown.pricingModel?.priceTiers &&
          unknown.pricingModel.priceTiers[0]
        ) {
          return unknown.pricingModel.priceTiers[0].price.grossAmount.currency;
        }
      }

      return 'EUR';
    };

    const currency = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: getCurrencyCode(),
    });

    if (!base) {
      return currency.format(0);
    }

    setPremiumPlanPrice(
      currency.format(base.pricingModel.price.grossAmount.decimal),
    );
  }, [country, planRegion, token, locale]);

  const isEuRegion = useMemo(() => planRegion === Region.EU, [planRegion]);

  useEffect(() => {
    if (isEuRegion && hasFollowupPlan && !premiumPlanPrice) {
      fetchPremiumPlanPrice();
    }
  }, [fetchPremiumPlanPrice, premiumPlanPrice, hasFollowupPlan, isEuRegion]);

  const handleAccordionButtonClick = useCallback(
    () => setIsAccordionOpen((previousValue) => !previousValue),
    [],
  );
  const intl = useIntl();
  const formatDate = (date: Date) =>
    intl.formatDate(date, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  const startDate = formatDate(new Date());

  const expirationDate = tariffExpiration ? formatDate(tariffExpiration) : null;

  return (
    <div className={style.root}>
      <div className={style.accordionContainer}>
        <PHeading size="large" className={style.header}>
          <FormattedMessage {...messages.title} />
        </PHeading>

        <PButtonPure
          icon={isAccordionOpen ? 'arrow-head-up' : 'arrow-head-down'}
          className={style.detailsButton}
          onClick={handleAccordionButtonClick}
        >
          <FormattedMessage {...messages.showDetailsText} />
        </PButtonPure>
      </div>

      <div
        className={
          isAccordionOpen ? style.containerOpened : style.containerClosed
        }
      >
        <div className={style.priceLine}>
          <PHeading size="medium">{planName}</PHeading>
          <PText size="medium">{planPrice}</PText>
        </div>
        <PText color="contrast-medium" size="x-small">
          {vehicleName} [{vin}]
        </PText>
        <PText color="contrast-medium" size="x-small">
          <FormattedMessage
            {...messages.startDateText}
            values={{ date: startDate }}
          />
        </PText>
        {expirationDate ? (
          <PText color="contrast-medium" size="x-small">
            <FormattedMessage
              {...messages.expirationDateText}
              values={{ date: expirationDate }}
            />
          </PText>
        ) : null}

        {premiumPlanPrice ? (
          <PText color="contrast-medium" size="x-small">
            <FormattedMessage
              {...messages.euInclusiveFollowUpPlanPriceText}
              values={{ price: premiumPlanPrice }}
            />
          </PText>
        ) : null}
        <PDivider className={style.divider} />
      </div>
      <div className={style.totalPriceLine}>
        <PHeading size="medium">
          <FormattedMessage {...messages.totalPrice} />
        </PHeading>
        <div className={style.totalPriceContainer}>
          <PHeading size="medium">{planPrice}</PHeading>
          {isEuRegion && !isInclusivePlan ? (
            <TaxPercentage taxPercentage={taxPercentage} />
          ) : null}
        </div>
      </div>
      <PText color="contrast-medium" size="x-small">
        {isEuRegion ? (
          <FormattedMessage
            {...messages[
              hasFollowupPlan ? 'inclusivePlanText' : 'exclusivePlanText'
            ]}
            values={{
              monthlyPrice: premiumPlanPrice,
              tariffName: getTariffName(
                PlanVariant.V2_PREMIUM_INCLUSIVE,
                params.marketplace,
              ),
            }}
          />
        ) : (
          <FormattedMessage {...messages.narInclusiveText} />
        )}
      </PText>
    </div>
  );
};
