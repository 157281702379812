import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Tariff',
    id: 'flyout.overview.tariff.title',
  },
  action: {
    defaultMessage: 'Manage',
    id: 'flyout.overview.tariff.action',
  },
});
