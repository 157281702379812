import React from 'react';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter,
} from 'react-router-dom';
import { getPathSegments, isNotLocalHostPath } from './router';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),

    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: process.env.REACT_APP_SENTRY_ENV,
  tracesSampleRate: 0.25,
  tracePropagationTargets: [
    /^https:\/\/api\.porsche-preview\.com\/subscription-service/,
    /^https:\/\/api\.porsche\.com\/subscription-service/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled:
    process.env.REACT_APP_SENTRY_ENABLED === 'true' && isNotLocalHostPath(),
});

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const sendErrorToSentry = async (error: any) => {
  Sentry.captureException(error);
  await Sentry.flush(200);
};

export const maskVin = (vin: string) => {
  const start = vin.substring(0, 4);
  const end = vin.substring(vin.length - 4);
  return `${start}*********${end}`;
};

export const setSentryUser = (path: string) => {
  const vin = getPathSegments(path)?.vin;
  if (vin) Sentry.setUser({ username: maskVin(vin) });
};
