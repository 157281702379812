import Cookies from 'js-cookie';

const oauthRedirectCookie = 'mycharging_oauth_redirect';
const cookiePath = '/';

export function setOAuthRedirectUrl(url: string): string {
  return setCookie(oauthRedirectCookie, url);
}

export function getOAuthRedirectUrl(): string | undefined {
  return Cookies.get(oauthRedirectCookie);
}

export function clearOAuthRedirectUrl() {
  clearCookie(oauthRedirectCookie);
}

function getHostname() {
  const url = new URL(window.location.href);
  return url.hostname;
}

function clearCookie(name: string) {
  Cookies.remove(name, {
    path: cookiePath,
    domain: getHostname(),
  });
}

function setCookie(name: string, value: string): string {
  const now = new Date();
  const inOneHour = new Date(now.getTime() + 60 * 60 * 1000);

  Cookies.set(name, value, {
    domain: getHostname(),
    expires: inOneHour,
    path: cookiePath,
    sameSite: 'Strict',
    secure: window.location.protocol === 'https:',
  });

  return value;
}
