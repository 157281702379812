import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PInlineNotification } from '@porsche-design-system/components-react';
import { messages } from './adressError.messages';
import style from './style.module.scss';

type AddressFormProps = {
  isVisible: boolean;
};

const AddressError: FC<AddressFormProps> = ({ isVisible }) => {
  const intl = useIntl();

  if (!isVisible) return null;

  return (
    <div className={style.container}>
      <PInlineNotification
        dismissButton={false}
        state="error"
        description={intl.formatMessage(messages.message)}
      />
    </div>
  );
};

export default AddressError;
