import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ErrorPage } from './error/ErrorPage';
import { SuccessPage } from './success/SuccessPage';
import { SubscriptionContextProvider } from '../../components/context/subscription/SubscriptionContextProvider';
import { UpgradeContextProvider } from '../../components/context/upgrade/UpgradeContextProvider';
import { UpgradePlansContextProvider } from '../../components/context/upgrade/UpgradePlansContextProvider';
import { SelectPage } from './select/SelectPage';

export const ChangePage: React.FunctionComponent = () => {
  return (
    <SubscriptionContextProvider>
      <UpgradeContextProvider>
        <UpgradePlansContextProvider>
          <Routes>
            <Route element={<SuccessPage />} path={'success'} />
            <Route element={<ErrorPage />} path={'error'} />
            <Route element={<SelectPage />} path={'*'} />
          </Routes>
        </UpgradePlansContextProvider>
      </UpgradeContextProvider>
    </SubscriptionContextProvider>
  );
};
