import React from 'react';
import { messages } from './notificationUpgradeToNew.messages';
import { useIntl } from 'react-intl';
import { InlineNotification } from '../InlineNotification';
import { getTariffName } from '../../../../utils/getTariffName';
import { PlanVariant } from '../../../context/plan/planContext.types';
import { useParams } from '../../../../hooks/useParams';

interface NotificationUpgradeToNewProps {
  dismissible: boolean;
}

export const NotificationUpgradeToNew: React.FunctionComponent<
  NotificationUpgradeToNewProps
> = (props) => {
  const intl = useIntl();
  const params = useParams();

  return (
    <InlineNotification
      title={intl.formatMessage(messages.title, {
        tariff: getTariffName(PlanVariant.V2_PREMIUM, params.marketplace),
      })}
      text={intl.formatMessage(messages.text)}
      actionLabel={intl.formatMessage(messages.action)}
      state={'info'}
      dismissibleId={props.dismissible ? 'upgrade' : undefined}
    />
  );
};
