import { FC, useContext, useMemo } from 'react';
import { subscriptionContext } from 'components/context/subscription/SubscriptionContext';
import { planContext } from 'components/context/plan/PlanContext';
import { observer } from 'mobx-react-lite';
import { getTariffName } from 'utils/getTariffName';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from './nextPlanDisclaimer.messages';

export const NextPlanDisclaimer: FC = observer(() => {
  const intl = useIntl();
  const subscription = useContext(subscriptionContext);
  const plan = useContext(planContext);

  const nextCustomerRelevantPhase = useMemo(
    () => subscription.subscription?.nextCustomerRelevantPhase,
    [subscription],
  );

  if (!nextCustomerRelevantPhase?.plan) {
    return null;
  }

  if (nextCustomerRelevantPhase.plan.name === plan.name) {
    return null;
  }

  return (
    <FormattedMessage
      {...messages.disclaimer}
      values={{
        name: getTariffName(
          nextCustomerRelevantPhase.plan.variant,
          subscription.subscription?.country || 'US',
        ),
        date: intl.formatDate(new Date(nextCustomerRelevantPhase.startDate), {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
        }),
      }}
    />
  );
});
