import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import translations from 'intl/translations';
import { validPathRegex } from 'utils/router';
import { defaultLocale, defaultMarketplace } from './useParams';

const availableTranslations = Object.keys(translations);
export const marketplaceLocalesMatrix = {
  at: ['de-AT'],
  be: ['nl-BE', 'fr-BE'],
  ca: ['en-CA', 'fr-CA'],
  ch: ['de-CH', 'fr-CH', 'it-CH'],
  cz: ['cs-CZ'],
  de: ['de-DE'],
  dk: ['da-DK'],
  ee: ['et-EE'],
  es: ['es-ES'],
  fi: ['fi-FI'],
  fr: ['fr-FR'],
  gb: ['en-GB'],
  hr: ['hr-HR'],
  hu: ['hu-HU'],
  ie: ['en-IE'],
  it: ['it-IT'],
  li: ['de-LI', 'de-DE'],
  lt: ['lt-LT'],
  lu: ['fr-LU', 'de-LU'],
  lv: ['lv-LV'],
  nl: ['nl-NL'],
  no: ['no-NO'],
  pl: ['pl-PL'],
  pt: ['pt-PT'],
  se: ['sv-SE'],
  si: ['sl-SI'],
  sk: ['sk-SK'],
  us: ['en-US'],
} as { [key: string]: string[] };

export const getResolvedPathParams = ({
  locale,
  marketplace,
}: {
  locale?: string;
  marketplace?: string;
}) => {
  if (!marketplace || !locale) {
    return null;
  }
  const { availableLocales, resolvedMarketplace } =
    resolveMarketplaceLocales(marketplace);

  const [language, country] = splitLocale(locale);
  const bestMatchingLocale = getBestMatchingLocale(
    language,
    country,
    availableLocales,
  );

  return {
    locale: bestMatchingLocale,
    marketplace: resolvedMarketplace,
  };
};

export const validatePath = ({
  pathname,
  locale,
  marketplace,
  vin,
}: {
  pathname: string;
  locale?: string;
  marketplace?: string;
  vin?: string;
}) => {
  if (!marketplace || !locale || !vin) {
    return null;
  }

  const resolvedPathParams = getResolvedPathParams({
    locale,
    marketplace,
  });

  if (!resolvedPathParams) {
    return null;
  }

  const path = [resolvedPathParams.marketplace, resolvedPathParams.locale, vin];

  const correctedPath = `/${(pathname.startsWith('/m/')
    ? ['m', ...path]
    : path
  ).join('/')}${pathname.split(vin)[1]}`;

  if (!validPathRegex.test(correctedPath)) {
    return null;
  }

  return correctedPath;
};

export const useValidateUrlPattern = () => {
  const location = useLocation();
  const { marketplace, locale, vin } = useParams();

  const validatedPathname = useMemo(
    () =>
      validatePath({ pathname: location.pathname, locale, marketplace, vin }),
    [location.pathname, locale, marketplace, vin],
  );

  return { validatedPathname, location };
};

/**
 * Splits the locale into language and country
 * @param locale
 * @returns
 */
function splitLocale(locale: string): string[] {
  return locale.toLowerCase().replace('_', '-').split('-');
}

function getBestMatchingLocale(
  language: string,
  country: string | undefined,
  availableLocales: string[],
) {
  let locale: string;
  if (!country) {
    locale =
      findLocaleByPrefix(availableLocales, language.substring(0, 2)) || '';
  } else {
    locale = `${language}-${country.toUpperCase()}`;
  }

  if (!availableLocales.includes(locale)) {
    // Marketplace does not support this locale, try to prefix match again to see
    // if we provide a locale in the correct language
    locale =
      findLocaleByPrefix(availableLocales, locale.substring(0, 2)) ??
      availableLocales[0];
  }

  if (!availableTranslations.includes(locale)) {
    // No translation for the requested locale, we need to fall back to the
    // default locale
    return defaultLocale;
  }

  return locale;
}

function findLocaleByPrefix(locales: string[], prefix: string) {
  return locales.find((locale) => locale.startsWith(prefix));
}

/**
 * Returns the actual marketplace and a list of available locales.
 * @param marketplace
 * @returns
 */
function resolveMarketplaceLocales(marketplace: string) {
  marketplace = marketplace.toLowerCase();
  const marketplaceLocales = marketplaceLocalesMatrix[marketplace];
  if (marketplaceLocales) {
    return {
      resolvedMarketplace: marketplace,
      availableLocales: marketplaceLocales,
    };
  }

  return {
    resolvedMarketplace: defaultMarketplace,
    availableLocales: marketplaceLocalesMatrix[defaultMarketplace],
  };
}
