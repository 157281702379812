import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  plugAndCharge: {
    defaultMessage: 'Plug & Charge',
    id: 'flyout.overview.membership.plugAndCharge',
  },
  basicPricingUs: {
    defaultMessage: 'Guest or Electrify America Pass membership prices*',
    id: 'flyout.overview.membership.basicPricingUs',
  },
  basicPricingCa: {
    defaultMessage: 'Guest or Electrify Canada Pass membership prices*',
    id: 'flyout.overview.membership.basicPricingCa',
  },
  premiumPricingUs: {
    defaultMessage:
      'Cheaper charging prices* with Electrify America Pass+ membership',
    id: 'flyout.overview.membership.premiumPricingUs',
  },
  premiumPricingCa: {
    defaultMessage:
      'Cheaper charging prices* with Electrify Canada Pass+ membership',
    id: 'flyout.overview.membership.premiumPricingCa',
  },
  saveUpTo: {
    defaultMessage: 'Save up to 20% on charging',
    id: 'flyout.overview.membership.saveUpTo',
  },
  noMonthlyFee: {
    defaultMessage: 'No monthly fee',
    id: 'flyout.overview.membership.noMonthlyFee',
  },
  inclusiveAc: {
    defaultMessage: 'AC (≤ 7 kW)',
    id: 'flyout.overview.membership.inclusiveAc',
  },
  inclusiveSubtitle: {
    defaultMessage: 'additional fees apply afterwards',
    id: 'flyout.overview.membership.inclusiveSubtitle',
  },
  inclusiveDc: {
    defaultMessage: 'DC (≤ 350 kW)',
    id: 'flyout.overview.membership.inclusiveDc',
  },
  inclusiveGrace: {
    defaultMessage: 'Grace Period',
    id: 'flyout.overview.membership.inclusiveGrace',
  },
  inclusiveIdle: {
    defaultMessage: 'Idle fee',
    id: 'flyout.overview.membership.inclusiveIdle',
  },
  inclusiveIdleSubtitle: {
    defaultMessage: 'Depending on the charging station',
    id: 'flyout.overview.membership.inclusiveIdleSubtitle',
  },
  inclusiveTaxDisclaimer: {
    defaultMessage: 'Taxes and other fees are excluded from all prices.',
    id: 'flyout.overview.membership.inclusiveTaxDisclaimer',
  },
  inclusivePricesDisclaimer: {
    defaultMessage:
      'See detailed prices at charging stations or in the app before the charging session.',
    id: 'flyout.overview.membership.inclusivePricesDisclaimer',
  },
  inclusiveFlatrate: {
    defaultMessage: '{amount} min flatrate',
    id: 'flyout.overview.membership.inclusiveFlatrate',
  },
  inclusiveGraceText: {
    defaultMessage: '{amount} min',
    id: 'flyout.overview.membership.inclusiveGraceText',
  },
});
