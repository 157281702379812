import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage:
      'With brand new {tariff} you can now charge at attractive conditions - with full flexibility.',
    id: 'manage.notification.upgradeToNew.title',
  },
  text: {
    defaultMessage: 'Order your upgrade in just a few clicks.',
    id: 'manage.notification.upgradeToNew.text',
  },
  action: {
    defaultMessage: 'Order the upgrade',
    id: 'manage.notification.upgradeToNew.action',
  },
});
