import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  monthlyText: {
    defaultMessage: '{baseFee} /month',
    id: 'flyout.overview.baseFee.monthlyText',
  },
  monthlyTitle: {
    defaultMessage: 'Monthly fee',
    id: 'flyout.overview.baseFee.monthlyTitle',
  },
});
