import { FC, ReactNode } from 'react';

import style from 'components/layout/frosted/frosted.module.scss';

interface FrostedItemProps {
  children: ReactNode;
}

export const FrostedItem: FC<FrostedItemProps> = (props) => {
  return <div className={style.item}>{props.children}</div>;
};
