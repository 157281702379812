import React from 'react';
import { messages } from '../../../../tariff/components/membership/membership.messages';
import { FormattedMessage } from 'react-intl';
import { PText } from '@porsche-design-system/components-react';
import style from './flatTariff.module.scss';
import { NorthAmericaOnly } from '../../../../context/plan/discriminators/region/NorthAmericaOnly';
import { NarPremiumInclusiveOnly } from '../../../../context/plan/discriminators/tariff/NarPremiumInclusiveOnly';

interface FlatTariffProps {}

/**
 * Flat tariff display, styled only for the tariff overview
 * @param props
 * @constructor
 */

export const FlatTariff: React.FunctionComponent<FlatTariffProps> = (props) => {
  return (
    <NorthAmericaOnly>
      <NarPremiumInclusiveOnly>
        <div>
          <div>
            <PText>
              <FormattedMessage {...messages.inclusiveDc} />
            </PText>
          </div>
          <div className={style.alignRight}>
            <div>
              <PText weight={'bold'}>
                <FormattedMessage
                  {...messages.inclusiveFlatrate}
                  values={{ amount: '30' }}
                />
              </PText>
            </div>
            <div>
              <PText>
                <FormattedMessage {...messages.inclusiveSubtitle} />*
              </PText>
            </div>
          </div>
        </div>
        <div>
          <div>
            <PText>
              <FormattedMessage {...messages.inclusiveGrace} />
            </PText>
          </div>
          <div>
            <PText weight={'bold'}>
              <FormattedMessage
                {...messages.inclusiveGraceText}
                values={{ amount: '10' }}
              />
            </PText>
          </div>
        </div>
        <div>
          <div>
            <PText>
              <FormattedMessage {...messages.inclusiveIdle} />
            </PText>
          </div>
          <div>
            <PText weight={'bold'}>
              <FormattedMessage {...messages.inclusiveIdleSubtitle} />
            </PText>
          </div>
        </div>
      </NarPremiumInclusiveOnly>
    </NorthAmericaOnly>
  );
};
