import { PHeading, PText } from '@porsche-design-system/components-react';
import { BlankLayout } from 'components/layout/blank/BlankLayout';
import { FC } from 'react';

export const NotFoundPage: FC = () => {
  return (
    <BlankLayout>
      <PHeading align="center">404</PHeading>
      <PText align="center">Page not found</PText>
    </BlankLayout>
  );
};
