import React, { ReactNode, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { planContext } from '../../PlanContext';
import { Region } from '../../planContext.types';

interface Props {
  children?: ReactNode;
}

export const CanadaOnly: React.FunctionComponent<Props> = observer((props) => {
  const context = useContext(planContext);

  if (context.region === Region.CA) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return null;
});
