import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { PriceSegment } from '../segment/PriceSegment';
import { FormattedMessage } from 'react-intl';
import { ChargePointClass } from 'components/context/plan/planContext.types';
import { planContext } from '../../../context/plan/PlanContext';
import { chargingOverrides } from '../Charging';

/**
 * Display charging cost <br/>
 * Use for legacy tariff eu only
 */
export const ChargingEuLegacy: React.FunctionComponent = observer((pros) => {
  const context = useContext(planContext);
  const { messages } = useContext(chargingOverrides);

  const overview = {
    ac: {
      price: context.resolveEnergyPrice(ChargePointClass.AC_22_KW),
      blocking: [context.resolveBlocking(ChargePointClass.AC_22_KW)],
    },
    dc: {
      price: context.resolveEnergyPrice(ChargePointClass.DC_50_KW),
      blocking: [
        context.resolveBlocking(ChargePointClass.DC_50_KW),
        context.resolveBlocking(ChargePointClass.DC_150_KW),
        context.resolveBlocking(ChargePointClass.HPC_350_KW),
      ],
    },
    ionity: {
      price: context.resolveEnergyPrice(ChargePointClass.IONITY_350_KW),
      blocking: [],
    },
  };

  return (
    <React.Fragment>
      <div style={{ display: 'none' }} data-testid={'chargingEuLegacy'} />
      <PriceSegment
        title={<FormattedMessage {...messages.ac} />}
        {...overview.ac}
      />
      <PriceSegment
        title={<FormattedMessage {...messages.dc} />}
        {...overview.dc}
      />
      <PriceSegment
        title={<FormattedMessage {...messages.ionity} />}
        {...overview.ionity}
      />
    </React.Fragment>
  );
});
