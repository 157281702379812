import { Subscription } from '../subscription/SubscriptionContext';
import { Authentication } from '../../../hooks/useAuthentication/useAuthentication';
import { makeAutoObservable } from 'mobx';
import { PlanVariant, Region } from '../plan/planContext.types';
import { NetworkStatus } from '../../../hooks/useCombinedNetworking/useCombinedNetworking';

export enum Notification {
  /**
   * EU only, upgrade your V1 inclusive contract to V2 Premium inclusive
   */
  UpgradeToNew,
  /**
   * EU only, extend your V1 inclusive contract to V2 Premium exclusive or V2 basic
   */
  ExtendToNew,
  /**
   * NA only, upgrade your V2 inclusive contract to V2 Premium exclusive
   */
  UpgradeToPremium,
}

export interface UpgradeContextProps {
  dummy: boolean;
  auth: Authentication;
  marketplace: string;
  subscription: Subscription;
}

interface Entitlement {
  planVariants: PlanVariant[];
}

/**
 * Provides CTA notifications and planVariants the customer is allowed to upgrade/downgrade to
 */
export class UpgradeContext {
  public network: NetworkStatus = NetworkStatus.Loading;
  public planVariants: PlanVariant[] = [];
  public notification: Notification | null = null;

  private readonly subscription: Subscription;
  private readonly marketplace: string;
  private readonly auth: Authentication;

  constructor(props: UpgradeContextProps) {
    makeAutoObservable(this);

    this.subscription = props.subscription;
    this.marketplace = props.marketplace;
    this.auth = props.auth;
    if (props.dummy) {
      return;
    }
    this.notification = this.generateNotification();
    this.fetch();
  }

  private fetch = async () => {
    try {
      const urlParams = this.subscription.nextPossibleEffectiveDate
        ? {
            effectiveDate: this.subscription.nextPossibleEffectiveDate,
          }
        : undefined;
      const params = new URLSearchParams({
        ...urlParams,
        country: this.marketplace,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/my/vehicles/${
          this.subscription.vin
        }/entitlement?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.auth.token}`,
            'apikey': this.auth.apiKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        this.network = NetworkStatus.Error;
        return;
      }
      const result = (await response.json()) as Entitlement;

      this.planVariants = result.planVariants;

      this.network = NetworkStatus.Success;
    } catch (e: unknown) {
      console.error(e);
      this.network = NetworkStatus.Error;
    }
  };

  private generateNotification = () => {
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const in30Days = new Date(new Date().getTime() + oneMonth);
    const current = this.subscription.currentOrLastPhase;
    const nextRelevantPhase = this.subscription.nextCustomerRelevantPhase;

    /**
     * Customer
     * - is on V1
     * - Subscription is terminated and no customer relevant follow up (plan change) is scheduled
     * - > 30 days remaining => Notification.UpgradeToNew
     * - < 30 days remaining => Notification.ExtendToNew
     */
    if (this.subscription.region === Region.EU) {
      const nextVariant = nextRelevantPhase
        ? nextRelevantPhase.plan.variant
        : current.plan.variant;

      if (nextVariant !== PlanVariant.V1_PREMIUM_INCLUSIVE) {
        return null;
      }

      if (
        this.subscription.endDate &&
        new Date(this.subscription.endDate) < in30Days
      ) {
        return Notification.ExtendToNew;
      }

      return Notification.UpgradeToNew;
    }

    /**
     * Customer
     * - is NA
     * - V2 Inclusive
     * - Less than 30 days remaining
     * - Follow up is not premium
     * => Display Notification.UpgradeToPremium
     */
    if (current.plan.variant !== PlanVariant.V2_PREMIUM_INCLUSIVE) {
      return null;
    }
    if (!current.endDate || new Date(current.endDate) > in30Days) {
      return null;
    }
    if (
      !this.subscription.nextCustomerRelevantPhase ||
      this.subscription.nextCustomerRelevantPhase.plan.variant ===
        PlanVariant.V2_PREMIUM
    ) {
      return null;
    }
    return Notification.UpgradeToPremium;
  };
}
