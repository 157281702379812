import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Your inclusive period ends on {date}.',
    id: 'manage.notification.upgradeToPremium.title',
  },
  text: {
    defaultMessage:
      'You’ll automatically be switched to the free Porsche Charging Service tariff. If you’d like to, you can upgrade to the {tariff}.',
    id: 'manage.notification.upgradeToPremium.text',
  },
  action: {
    defaultMessage: 'Upgrade to {tariff}',
    id: 'manage.notification.upgradeToPremium.action',
  },
});
