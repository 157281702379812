import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { subscriptionContext } from '../../../context/subscription/SubscriptionContext';
import { messages } from './notificationUpgradeToPremium.messages';
import { InlineNotification } from '../InlineNotification';
import { getTariffName } from '../../../../utils/getTariffName';
import { PlanVariant } from '../../../context/plan/planContext.types';
import { observer } from 'mobx-react-lite';

interface NotificationExtendNewProps {
  dismissible: boolean;
}

export const NotificationUpgradeToPremium: React.FunctionComponent<NotificationExtendNewProps> =
  observer((props) => {
    const intl = useIntl();
    const subscription = useContext(subscriptionContext);

    if (
      !subscription.subscription ||
      !subscription.subscription.nextCustomerRelevantPhase?.startDate
    ) {
      return null;
    }

    return (
      <InlineNotification
        title={intl.formatMessage(messages.title, {
          date: intl.formatDate(
            new Date(
              subscription.subscription.nextCustomerRelevantPhase.startDate,
            ),
            {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
            },
          ),
        })}
        text={intl.formatMessage(messages.text, {
          tariff: getTariffName(
            PlanVariant.V2_PREMIUM,
            subscription.subscription.country,
          ),
        })}
        actionLabel={intl.formatMessage(messages.action, {
          tariff: getTariffName(
            PlanVariant.V2_PREMIUM,
            subscription.subscription.country,
            true,
          ),
        })}
        state={'warning'}
        dismissibleId={props.dismissible ? 'upgrade' : undefined}
      />
    );
  });
