import React from 'react';
import { FrostedHeading } from 'components/layout/frosted/FrostedHeading';
import { FrostedItem } from 'components/layout/frosted/FrostedItem';
import { FrostedText } from 'components/layout/frosted/FrostedText';
import { Link } from 'react-router-dom';
import { PButton } from '@porsche-design-system/components-react';
import style from 'pages/tariff/welcome/welcomePage.module.scss';
import { messages } from 'pages/tariff/welcome/welcomePage.messages';
import { FormattedMessage } from 'react-intl';

interface WelcomePageProps {
  link: string;
}

export const WelcomePage: React.FunctionComponent<WelcomePageProps> = (
  props,
) => {
  return (
    <div className={style.root} data-testid={'welcomePage'}>
      <div>
        <FrostedHeading>
          <FormattedMessage {...messages.title} />
        </FrostedHeading>
        <FrostedItem>
          <FrostedText>
            <FormattedMessage {...messages.text} />
          </FrostedText>
        </FrostedItem>
      </div>

      <Link to={props.link}>
        <PButton className={style.button}>
          <FormattedMessage {...messages.action} />
        </PButton>
      </Link>
    </div>
  );
};
