import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SuccessPage } from 'pages/confirmation/success/SuccessPage';
import { FailurePage } from 'pages/confirmation/failure/FailurePage';

export const ConfirmationPage: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path={'success'} element={<SuccessPage />} />
      <Route path={'failure'} element={<FailurePage />} />
    </Routes>
  );
};
