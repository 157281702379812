import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Notification } from '../../context/upgrade/UpgradeContext';
import { upgradeContext } from '../../context/upgrade/UpgradeContextProvider';
import { NotificationUpgradeToNew } from './upgradeToNew/NotificationUpgradeToNew';
import { NotificationExtendToNew } from './extendToNew/NotificationExtendToNew';
import { NotificationUpgradeToPremium } from './upgradeToPremium/NotificationUpgradeToPremium';

interface NotificationTileProps {
  dismissible: boolean;
}

export const UpgradeNotification: React.FunctionComponent<NotificationTileProps> =
  observer((props) => {
    const context = useContext(upgradeContext);

    if (context.notification === null) {
      return null;
    }

    if (context.planVariants.length === 0) {
      return null;
    }

    switch (context.notification) {
      case Notification.UpgradeToNew:
        return <NotificationUpgradeToNew dismissible={props.dismissible} />;
      case Notification.ExtendToNew:
        return <NotificationExtendToNew dismissible={props.dismissible} />;
      case Notification.UpgradeToPremium:
        return <NotificationUpgradeToPremium dismissible={props.dismissible} />;
      default:
        return null;
    }
  });
