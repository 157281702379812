import React from 'react';
import { Membership } from '../../../../tariff/components/membership/Membership';
import { Disclaimer } from './disclaimer/Disclaimer';
import { EuropeOnly } from '../../../../context/plan/discriminators/region/EuropeOnly';
import { NorthAmericaOnly } from '../../../../context/plan/discriminators/region/NorthAmericaOnly';
import { BasicOnly } from '../../../../context/plan/discriminators/tariff/BasicOnly';
import { NarPremiumOnly } from '../../../../context/plan/discriminators/tariff/NarPremiumOnly';

export const Footer: React.FunctionComponent = () => {
  return (
    <div data-testid={'footer'}>
      <EuropeOnly>
        <Membership />
      </EuropeOnly>
      <NorthAmericaOnly>
        <BasicOnly>
          <Membership />
        </BasicOnly>
        <NarPremiumOnly>
          <Membership />
        </NarPremiumOnly>
      </NorthAmericaOnly>
      <Disclaimer />
    </div>
  );
};
