import React, { useCallback, useContext, useMemo, useState } from 'react';
import { PInlineNotification } from '@porsche-design-system/components-react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { vehicleContext } from '../../context/vehicle/VehicleContextProvider';
import { useRoutes } from '../../../hooks/useRoutes';
import { Routes } from '../../../router/routes';

interface InlineNotificationProps {
  title: string;
  text: string;
  actionLabel: string;
  state: 'success' | 'warning' | 'error' | 'info' | 'neutral';
  /**
   * Allows the user to permanently dismiss this notification <br/>
   * Unique id of the notification, must !! equal true for the dismiss button to show up <br/>
   */
  dismissibleId?: string;
}

export const InlineNotification: React.FunctionComponent<InlineNotificationProps> =
  observer((props) => {
    const navigate = useNavigate();
    const vehicle = useContext(vehicleContext);
    const generateRoute = useRoutes();

    const id = useMemo(() => {
      return `notification-${props.dismissibleId}-${vehicle.vin}-dismissed`;
    }, [props.dismissibleId, vehicle.vin]);

    const [dismissedOverride, setDismissedOverride] = useState(false);
    const dismissed = useMemo(() => {
      if (!props.dismissibleId) {
        return false;
      }
      if (dismissedOverride) {
        return true;
      }
      try {
        return localStorage.getItem(id) === 'true';
      } catch (e) {
        return false;
      }
    }, [props.dismissibleId, id, dismissedOverride]);

    const onAction = useCallback(() => {
      navigate(generateRoute(Routes.ChangeTariff));
    }, [generateRoute, navigate]);

    const onDismiss = useCallback(() => {
      setDismissedOverride(true);
      localStorage.setItem(id, 'true');
    }, [id]);

    if (!vehicle.vin || dismissed) {
      return null;
    }

    return (
      <PInlineNotification
        state={props.state}
        actionIcon={'arrow-right'}
        actionLabel={props.actionLabel}
        onAction={onAction}
        onDismiss={onDismiss}
        dismissButton={!!props.dismissibleId}
      >
        <span slot={'heading'}>{props.title}</span>
        {props.text}
      </PInlineNotification>
    );
  });
