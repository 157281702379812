import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Your contract ends on {date}.',
    id: 'manage.notification.extendToNew.title',
  },
  text: {
    defaultMessage:
      'To continue using the Porsche Charging Service, activate the new Porsche Charging Service.',
    id: 'manage.notification.extendToNew.text',
  },
  action: {
    defaultMessage: 'Extend subscription',
    id: 'manage.notification.extendToNew.action',
  },
});
