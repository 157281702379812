import {
  ChangeEvent,
  FC,
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { SubscriptionHistoryItem } from 'pages/management/components/SubscriptionHistoryItem/SubscriptionHistoryItem';
import style from './subscriptionHistoryList.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from './subscriptionHistoryList.module.messages';
import {
  PDivider,
  PLinkPure,
  PSpinner,
  PText,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import { subscriptionContext } from 'components/context/subscription/SubscriptionContext';
import { fetchApi } from 'utils/fetchApi';
import { observer } from 'mobx-react-lite';
import { formatDate } from 'utils/formatDate';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { useParams } from 'react-router-dom';

type Money = {
  cents: number;
  fractionDigits: number;
  decimal: number;
  currency: string;
};

type Session = {
  id: string;
  vin: string;
  startedAt: string;
  endedAt: string;
  startCapacity?: string;
  endCapacity?: string;
  legalBasis?: string;
  authenticationType: string;
};

type Price = {
  netAmount: Money;
  grossAmount: Money;
};

type ChargePoint = {
  evseId: string;
  operator: string;
  address: {
    name?: string;
    houseNumber?: string;
    street?: string;
    city?: string;
    postalCode?: string;
    state?: string;
  };
};

type Charges = {
  [key: string]: {
    price: Price;
    unitPrice: Price;
    quantity: number;
    unitType: string;
  };
};

export type ChargingSession = {
  id: string;
  cdrId: string;
  subscriptionId: string;
  session: Session;
  chargePoint: ChargePoint;
  totalPrice: Price;
  charges: Charges;
};

const nowDate = new Date();
const todayDateFormated = formatDate(nowDate);
const twoMonthsBeforeTodayDateFormated = formatDate(
  new Date(nowDate.getFullYear(), nowDate.getMonth() - 2, nowDate.getDate()),
);

export const SubscriptionHistoryList: FC = observer(() => {
  const intl = useIntl();
  const context = useContext(subscriptionContext);
  const params = useParams<{ locale: string; marketplace: string }>();

  const [isOpenStateMap, setIsOpenStateMap] = useState<{
    [key: string]: boolean;
  }>({});
  const [fromDate, setFromDate] = useState(twoMonthsBeforeTodayDateFormated);
  const [toDate, setToDate] = useState(todayDateFormated);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedHistoryItems, setFetchedHistoryItems] = useState<
    ChargingSession[]
  >([]);
  const { token } = useAuthentication();

  const fetchChargingSessions = useCallback(async () => {
    setIsLoading(true);
    try {
      const historyItemsResponse = await fetchApi(
        `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions/${context.subscription?.id}/charging-sessions?startDate=${fromDate}&endDate=${toDate}T23:59:59`,
        token,
        {
          method: 'GET',
        },
      );

      const history = await historyItemsResponse.json();
      setFetchedHistoryItems(
        history.sort((a: ChargingSession, b: ChargingSession) =>
          a.session.startedAt > b.session.startedAt ? -1 : 1,
        ),
      );
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [context.subscription, fromDate, toDate, token]);

  useEffect(() => {
    if (context.subscription?.id) {
      fetchChargingSessions();
    }
  }, [fetchChargingSessions, context.subscription]);

  const handleToggleOpenState = (cdrId: string) => {
    return setIsOpenStateMap((previousState) => {
      const newState = { ...previousState };
      newState[cdrId] = !newState[cdrId];

      return newState;
    });
  };

  const changeDate = useCallback(
    (event: ChangeEvent<HTMLInputElement>, dateField: 'from' | 'to') => {
      const setterFunction = { from: setFromDate, to: setToDate }[dateField];
      setterFunction(event.target.value);
    },
    [],
  );

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div className={style.alternateContent}>
          <PSpinner />
        </div>
      );
    }

    if (fetchedHistoryItems.length === 0) {
      return (
        <div className={style.alternateContent}>
          <PText data-testid="subscriptionHistoryList-empty-message">
            <FormattedMessage {...messages.historyListNoResult} />
          </PText>
        </div>
      );
    }

    return (
      <>
        {fetchedHistoryItems.map((historyItem) => (
          <SubscriptionHistoryItem
            key={historyItem.id}
            isOpen={isOpenStateMap[historyItem.cdrId]}
            toggleOpen={() => handleToggleOpenState(historyItem.cdrId)}
            item={historyItem}
          />
        ))}
      </>
    );
  }, [fetchedHistoryItems, isLoading, isOpenStateMap]);

  return (
    <div
      className={style.mainWrapper}
      data-testid="subscriptionHistoryList-main-wrapper"
    >
      <div className={style.filterWrapper}>
        <PTextFieldWrapper
          className={style.firstDateInput}
          label={intl.formatMessage(messages.dateFromLabel)}
        >
          <input
            type="date"
            name="dateFrom"
            value={fromDate}
            onChange={(ev) => changeDate(ev, 'from')}
            data-testid="subscriptionHistoryList-date-from-input-field"
          />
        </PTextFieldWrapper>

        <PTextFieldWrapper
          label={intl.formatMessage(messages.dateToLabel)}
          className={style.secondDateInput}
        >
          <input
            type="date"
            name="dateTo"
            value={toDate}
            onChange={(ev) => changeDate(ev, 'to')}
            data-testid="subscriptionHistoryList-date-to-input-field"
          />
        </PTextFieldWrapper>

        <PLinkPure
          href={`${process.env.REACT_APP_OHS_FRONTEND_URL}/${params.marketplace}/${params.locale}/account/orders?type=charging`}
          icon="external"
          className={style.externalLink}
          data-testid="subscriptionHistoryList-external-link"
          target="__blank"
        >
          <FormattedMessage {...messages.billsLinkLabel} />
        </PLinkPure>
      </div>

      <PDivider />

      {content}
    </div>
  );
});
