import { FC } from 'react';
import {
  PButton,
  PHeading,
  PText,
} from '@porsche-design-system/components-react';
import { FrostedHeading } from 'components/layout/frosted/FrostedHeading';
import { FrostedItem } from 'components/layout/frosted/FrostedItem';
import { FrostedLayout } from 'components/layout/frosted/FrostedLayout';
import { FrostedText } from 'components/layout/frosted/FrostedText';
import { Step, Stepper } from 'components/stepper/Stepper';
import style from 'pages/confirmation/success/successPage.module.scss';
import background from 'media/backgroundSuccess.png';
import { Link, useParams } from 'react-router-dom';
import { messages } from './successPage.messages';
import { FormattedMessage } from 'react-intl';
import { getRegion } from 'utils/getRegion';
import { Region } from 'components/context/plan/planContext.types';

export const SuccessPage: FC = () => {
  const { vin, marketplace, locale } = useParams();
  const marketpaceRegion = getRegion(marketplace || '');
  const isNarRegion =
    marketpaceRegion === Region.CA || marketpaceRegion === Region.US;
  const euSpecificMessages = [
    messages.procedure1,
    messages.procedure2,
    messages.procedure4,
  ];
  const messagesToShow = isNarRegion
    ? [messages.procedure3]
    : euSpecificMessages;

  return (
    <div data-testid={'successPage'}>
      <Stepper currentStep={Step.Confirmation} />
      <FrostedLayout background={background}>
        <FrostedHeading>
          <FormattedMessage {...messages.title} />
        </FrostedHeading>
        <FrostedItem>
          <PHeading size={'x-large'}>
            <FormattedMessage {...messages.subtitle} />
          </PHeading>
        </FrostedItem>
        <FrostedItem>
          <div className={style.container}>
            {messagesToShow.map((message, index) => (
              <div className={style.item} key={index}>
                <PText
                  className={style.circle}
                  color={'inherit'}
                  size="xx-small"
                >
                  {index + 1}
                </PText>
                <FrostedText>
                  <FormattedMessage {...message} />
                </FrostedText>
              </div>
            ))}
          </div>
        </FrostedItem>
        <Link
          to={`${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/vehicles/${vin}`}
        >
          <PButton className={style.button}>
            <FormattedMessage {...messages.action} />
          </PButton>
        </Link>
      </FrostedLayout>
    </div>
  );
};
