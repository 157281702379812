import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  taxText: {
    defaultMessage: 'All displayed prices include taxes.',
    id: 'additionalDisclaimer.taxText',
  },
  newVehicleText: {
    defaultMessage:
      'Buyers of a new vehicle of the models Taycan (all models), Macan electric (all models), and Cayenne E-Hybrid from model year 2025/Model year S, as well as Panamera E-Hybrid from 3rd generation (G3), receive a basic fee-free inclusive period for up to 3 years for the Porsche Charging Service Plus (or its successor product). Only agreed prices for charging sessions are payable. The service must be activated within 3 years of when the vehicle is handed over to the customer.',
    id: 'additionalDisclaimer.newVehicleText',
  },
  chargingRatesText: {
    defaultMessage:
      'The charging rates valid at the respective time of activating the service apply. Porsche reserves the right to terminate the Porsche Charging Service Plus during the inclusive period in accordance with the contract. In this case, the vehicle owner can reactivate the service (or its successor product) under the newly offered terms while continuing the unused subscription-free inclusive period.',
    id: 'additionalDisclaimer.chargingRatesText',
  },
  terminationText: {
    defaultMessage:
      'The time between termination by Porsche and the customer re-activating the service (or its successor product) will also count towards the remaining subscription-free inclusive period.',
    id: 'additionalDisclaimer.terminationText',
  },
  singlePriceLinkText: {
    defaultMessage: 'Additional price information can be seen {linkHereText}.',
    id: 'additionalDisclaimer.singlePriceLinkText',
  },
  multiPriceLinkText: {
    defaultMessage:
      'Additional price information can be seen {linkHereText1} and {linkHereText2}.',
    id: 'additionalDisclaimer.multiPriceLinkText',
  },
  linkHereText: {
    defaultMessage: 'here',
    id: 'additionalDisclaimer.linkHereText',
  },
});
