import React, { ReactNode, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { planContext } from '../../PlanContext';
import { PlanVariant } from 'components/context/plan/planContext.types';

interface Props {
  children?: ReactNode;
}

export const NarPremiumOnly: React.FunctionComponent<Props> = observer(
  (props) => {
    const context = useContext(planContext);

    if (context.variant === PlanVariant.V2_PREMIUM) {
      return <React.Fragment>{props.children}</React.Fragment>;
    }

    return null;
  },
);
