import {
  PlanVariant,
  Region,
} from '../components/context/plan/planContext.types';
import { getRegion } from './getRegion';

/**
 * Returns the customer friendly tariff name <br />
 * Names should not be translated and be used for all customer touch-points <br />
 * See https://confluence.porsche.codes/display/CHARGE/Glossar for glossary
 */
export const getTariffName = (
  variant: PlanVariant,
  marketplace: string,
  short?: boolean,
) => {
  const region = getRegion(marketplace);

  const name = (() => {
    if (region === Region.EU) {
      switch (variant) {
        case PlanVariant.V1_PREMIUM_INCLUSIVE:
          return 'PCS Taycan';
        case PlanVariant.V2_PREMIUM_INCLUSIVE:
          return 'PCS Plus';
        case PlanVariant.V2_PREMIUM:
          return 'PCS Plus';
        case PlanVariant.V2_BASIC:
          return 'PCS';
      }
    }
    switch (variant) {
      // Does not exist in NA
      case PlanVariant.V1_PREMIUM_INCLUSIVE:
        return 'PCS Inclusive Period';
      case PlanVariant.V2_PREMIUM_INCLUSIVE:
        return 'PCS Inclusive Period';
      case PlanVariant.V2_PREMIUM:
        return 'PCS Premium';
      case PlanVariant.V2_BASIC:
        return 'PCS';
    }
  })();

  if (short) {
    return name;
  }
  return name.replace('PCS', 'Porsche Charging Service');
};
