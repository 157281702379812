import { FC } from 'react';
import { messages } from './taxPercentage.messages';
import { FormattedMessage } from 'react-intl';

type TaxPercentageProps = {
  taxPercentage?: string;
};

export const TaxPercentage: FC<TaxPercentageProps> = ({ taxPercentage }) => {
  const normalizedPercentage = taxPercentage?.replace('0.', '');

  if (!normalizedPercentage) {
    return null;
  }

  return (
    <FormattedMessage
      {...messages.taxPercentageText}
      values={{ tax: normalizedPercentage }}
    />
  );
};
