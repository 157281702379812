import React, { useContext } from 'react';
import { planContext } from '../../../context/plan/PlanContext';

interface TariffNameProps {
  /**
   * Uses short "PCS" abbreviation instead of full "Porsche Charging Service"
   */
  short?: boolean;
}

/**
 * Returns the customer friendly tariff name of current {@link planContext}
 * @param props
 * @constructor
 */
export const TariffName: React.FunctionComponent<TariffNameProps> = (props) => {
  const context = useContext(planContext);

  return <>{context.getTariffName()}</>;
};
