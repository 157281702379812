import React, { HTMLAttributes } from 'react';
import {
  PStepperHorizontal,
  PStepperHorizontalItem,
} from '@porsche-design-system/components-react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../invitationPage.messages';
import { useSearchParams } from 'react-router-dom';

const Stepper: React.FC<HTMLAttributes<{}>> = (props) => {
  const [searchParams] = useSearchParams();
  const hasSecurityStep = searchParams.get('skipSecuritySettings') !== 'true';

  return (
    <PStepperHorizontal {...props}>
      <PStepperHorizontalItem state="complete">
        <FormattedMessage {...messages.step1} />
      </PStepperHorizontalItem>
      {hasSecurityStep && (
        <PStepperHorizontalItem state="complete">
          <FormattedMessage {...messages.step2} />
        </PStepperHorizontalItem>
      )}
      <PStepperHorizontalItem state="current">
        <FormattedMessage {...messages.step3} />
      </PStepperHorizontalItem>
    </PStepperHorizontal>
  );
};

export default Stepper;
