import React, { ReactNode, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { PIcon, PText } from '@porsche-design-system/components-react';
import style from './vehicleBarrier.module.scss';
import { messages } from './vehicleBarrier.messages';
import { FormattedMessage } from 'react-intl';
import { NetworkStatus } from '../../../../hooks/useCombinedNetworking/useCombinedNetworking';
import { Entitlement } from '../VehicleContext';
import LoadingVehicleSpinner from './LoadingVehicleSpinner';

interface VehicleBarrierProps {
  children?: ReactNode;
}

export const VehicleBarrier: React.FunctionComponent<VehicleBarrierProps> =
  observer((props) => {
    const context = useContext(vehicleContext);

    if (context.network === NetworkStatus.Loading) {
      return <LoadingVehicleSpinner />;
    }

    if (context.network === NetworkStatus.Error) {
      return (
        <div className={style.center} data-testid={'vehicleBarrier-error'}>
          <PIcon
            name={'close'}
            size={'large'}
            aria={{ 'aria-label': 'Close icon' }}
          />
          <PText size={'medium'}>
            {' '}
            <FormattedMessage {...messages.errorTitle} />
          </PText>
          <PText size={'small'}>
            {' '}
            <FormattedMessage {...messages.errorText} />
          </PText>
        </div>
      );
    }

    if (context.entitlement === Entitlement.NotEntitled) {
      return (
        <div
          className={style.center}
          data-testid={'vehicleBarrier-notEntitled'}
        >
          <PIcon
            name={'close'}
            size={'large'}
            aria={{ 'aria-label': 'Close icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.entitlement} />
          </PText>
        </div>
      );
    }

    return <React.Fragment>{props.children}</React.Fragment>;
  });
