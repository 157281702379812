import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  getPayPalPaymentMethodId,
  triggerPayPalInitialTransaction,
} from './paymentRequests';

type UsePayPalOptions = {
  apiKey: string;
  token: string;
  shouldIgnoreHook?: boolean;
};

export const usePayPal = ({
  apiKey,
  token,
  shouldIgnoreHook,
}: UsePayPalOptions) => {
  let [searchParams] = useSearchParams();
  const [
    savedPaymentWidgetPayPalMethodId,
    setSavedPaymentWidgetPayPalMethodId,
  ] = useState('');
  const params = useParams<{ marketplace: string }>();
  const marketplace = params.marketplace || 'DE';
  const payPalpaymentMethodId = searchParams.get('paymentMethodId');
  const payPalPaymentStatus = searchParams.get('status');
  let ciamId = '';
  try {
    ciamId = JSON.parse(atob(token.split('.')[1])).ciamId;
  } catch (error) {
    console.error(error);
  }

  useEffect(() => {
    if (!shouldIgnoreHook)
      getPayPalPaymentMethodId({
        apiKey,
        token,
        marketplace,
      }).then((paymentMethodId) => {
        if (paymentMethodId) {
          setSavedPaymentWidgetPayPalMethodId(paymentMethodId);
        }
      });
  }, [apiKey, marketplace, token, shouldIgnoreHook]);

  const isCommingFromPayPalRedirect = useCallback(() => {
    return searchParams.get('paypal') === 'true';
  }, [searchParams]);

  return {
    triggerPayPalInitialTransaction: triggerPayPalInitialTransaction({
      apiKey,
      token,
      ciamId,
      marketplace,
    }),
    payPalpaymentMethodId,
    payPalPaymentStatus,
    savedPaymentWidgetPayPalMethodId,
    isCommingFromPayPalRedirect,
  };
};
