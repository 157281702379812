export const buildPayPalInitialTransactionRequest = ({
  apiKey,
  token,
  ciamId,
  marketplace,
}: {
  apiKey: string;
  token: string;
  ciamId: string;
  marketplace: string;
}) => {
  const confirmationPageUrl = new URL(window.location.href);
  confirmationPageUrl.searchParams.append('paypal', 'true');

  return {
    method: 'POST',
    headers: {
      'apikey': apiKey,
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      amount: {
        value: '0',
        currency: 'EUR',
      },
      paymentMethod: {
        type: 'paypal',
        typeDisplayName: 'PayPal',
        storeMethod: true,
      },
      type: 'USER',
      userId: ciamId,
      assortment: 'CHARGING',
      country: marketplace,
      merchantReference: 'newPspTest157',
      confirmationPageUrl: confirmationPageUrl.toString(),
      strongCustomerAuthenticationData: {
        browserInfo: {
          acceptHeader: '*/*',
          userAgent:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.110 Safari/537.36',
          colorDepth: 24,
          javaEnabled: false,
          language: 'en',
          screenHeight: 640,
          screenWidth: 480,
          timeZoneOffset: 0,
        },
      },
      capture: false,
    }),
  };
};
export const buildApplePayInitialTransactionRequest = ({
  apiKey,
  token,
  ciamId,
  marketplace,
  applePayToken,
  userAgent,
  currency,
}: {
  apiKey: string;
  token: string;
  ciamId: string;
  marketplace: string;
  applePayToken: string;
  userAgent: string;
  currency: string;
}) => {
  const confirmationPageUrl = new URL(window.location.href);

  return {
    method: 'POST',
    headers: {
      'apikey': apiKey,
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      amount: {
        value: '0',
        currency: currency || 'EUR',
      },
      paymentMethod: {
        type: 'applepay',
        typeDisplayName: 'Apple Pay',
        token: applePayToken,
        storeMethod: true,
      },
      type: 'USER',
      userId: ciamId,
      assortment: 'CHARGING',
      country: marketplace,
      merchantReference: 'newPspTest157',
      confirmationPageUrl: confirmationPageUrl.toString(),
      strongCustomerAuthenticationData: {
        browserInfo: {
          acceptHeader: '*/*',
          userAgent,
          colorDepth: 24,
          javaEnabled: false,
          language: 'en',
          screenHeight: 640,
          screenWidth: 480,
          timeZoneOffset: 0,
        },
      },
      capture: false,
    }),
  };
};

export const buildGetPaymentMethodIdRequest = ({
  apiKey,
  token,
}: {
  apiKey: string;
  token: string;
}) => ({
  method: 'POST',
  headers: {
    'apikey': apiKey,
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    paymentMethodTypes: ['creditcard', 'directdebit', 'redirect'],
    curency: 'EUR',
    channel: 'Web',
    basket: [{ assortment: 'CHARGING', amount: 0 }],
  }),
});
