import { PDivider } from '@porsche-design-system/components-react';
import { RawPlan } from 'components/context/plan/planContext.types';
import { PlanContextProvider } from 'components/context/plan/PlanContextProvider';
import { BasicLayout } from 'components/layout/basic/BasicLayout';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { useFetchAddress } from 'hooks/useFetchAddress';
import { triggerApplePayTransaction } from 'pages/activation/components/PaymentWidget/paymentRequests';
import { isVatCheckRequired } from 'pages/activation/useCheckout';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getFormData } from 'utils/getFormData';
import { addNewSubscription } from './addNewSubscription';
import AddressError from './components/AddressError';
import AddressForm from './components/AddressForm';
import { fetchBusinessVerification } from './components/AddressForm/utils';
import InvitationHeader from './components/InvitaionHeader';
import InvitationOrderSummary from './components/InvitationOrderSummary';
import PaiementForm from './components/PaiementForm';
import PlanSelection from './components/PlanSelection';
import Stepper from './components/Stepper';
import SubmitSection from './components/SubmitSection';
import ToCCheckboxes from './components/ToCCheckboxes';
import { useGetConnectRedirectionUrl } from './getConnectRedirectionUrl';
import style from './invitation.module.scss';
import { useInvitation } from './useInvitation';

type TInvitationForm = {
  chargingTariffId: string;
  addressId: string;
} & ({ cardDeliveryAddressSameAsBilling: 'on' } | { cardAddressId: string });

const InvitationProcess = () => {
  const redirectionUrl = useGetConnectRedirectionUrl();
  const { token, apiKey, ciamId } = useAuthentication();
  const { fetchAddressById } = useFetchAddress();
  const { locale, marketplace } = useParams();

  const {
    setCanSubmit,
    canSubmit,
    isSubmitDisabled,
    setSelectedPaymentMethod,
    setSelectedPlan,
    selectedPaymentMethod,
    vin,
    setIsLoading,
    isLoading,
    selectedPlan,
    isAddressAllowed,
    setIsAddressAllowed,
    setIsVatVerified,
    applePayToken,
    isInitialApplePaySelected,
    setApplePayToken,
    planCurrency,
  } = useInvitation();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    const jsonForm = getFormData<TInvitationForm>(e);

    if (!selectedPaymentMethod) {
      return;
    }

    setIsLoading(true);

    const address = await fetchAddressById(jsonForm.addressId);

    if (!address || address?.country.toLowerCase() !== marketplace) {
      setIsLoading(false);
      setIsAddressAllowed(false);
      return;
    }
    setIsAddressAllowed(true);

    if (isVatCheckRequired(address)) {
      const isAddressVerified = await fetchBusinessVerification({
        legalName: address.companyName1 ?? '',
        vat: address.vatId ?? '',
        country: address.country ?? '',
        authorizationToken: token,
      });

      if (!isAddressVerified) {
        setIsLoading(false);
        setIsVatVerified(false);
        return;
      }
    }
    setIsVatVerified(true);

    let paymentRefToUse =
      typeof selectedPaymentMethod === 'string' ? selectedPaymentMethod : '';

    if (isInitialApplePaySelected) {
      const applePayMethodIdResponse = await triggerApplePayTransaction({
        apiKey,
        token,
        ciamId,
        marketplace,
        userAgent: navigator.userAgent,
        applePayToken: applePayToken || '',
        currency: planCurrency,
      });
      paymentRefToUse = applePayMethodIdResponse?.paymentMethodId || '';
    }

    const orderIdToAppend = await addNewSubscription({
      vin,
      deliveryAddressId:
        'cardDeliveryAddressSameAsBilling' in jsonForm
          ? jsonForm.addressId
          : jsonForm.cardAddressId,
      planVariant: jsonForm.chargingTariffId,
      invoiceAddressId: jsonForm.addressId,
      paymentRef: paymentRefToUse,
      locale: locale!,
      token,
    });

    setIsLoading(false);

    let redirectionURL;

    if (orderIdToAppend && 'id' in orderIdToAppend) {
      redirectionURL = new URL(redirectionUrl.defaultRedirection);
      redirectionURL.searchParams.append('charging', orderIdToAppend.id);
    } else {
      redirectionURL = new URL(redirectionUrl.redirectionIfNoChargingOrder);
    }

    window.location.replace(redirectionURL.toString());
  };

  const updateSelectedPlans = useCallback(
    (planId: string, rawPlan: RawPlan) => setSelectedPlan(rawPlan),
    [setSelectedPlan],
  );

  return (
    <BasicLayout>
      <main className={style.root}>
        <section>
          <Stepper className={style.stepper} />
        </section>
        <section>
          <InvitationHeader />
        </section>
        <form onSubmit={handleSubmit}>
          <section>
            <PlanSelection onPlanChanged={updateSelectedPlans} />
          </section>
          {selectedPlan && (
            <PlanContextProvider
              plan={selectedPlan}
              marketplace={marketplace!}
              locale={locale!}
            >
              <section>
                <InvitationOrderSummary selectedPlan={selectedPlan} />
              </section>
              <PDivider color="contrast-high" className={style.pt5} />
              <section className={style.pt3}>
                <AddressForm onVatVerification={setIsVatVerified} />
              </section>
              <section>
                <PaiementForm
                  onPaymentMethodChanged={setSelectedPaymentMethod}
                  setApplePayToken={setApplePayToken}
                />
              </section>
              <section className={style.pt4}>
                <ToCCheckboxes handleCanSubmitChange={setCanSubmit} />
              </section>
              <section>
                <AddressError isVisible={!isAddressAllowed} />
                <SubmitSection
                  isLoading={isLoading}
                  isSubmitDisabled={
                    isSubmitDisabled ||
                    (isInitialApplePaySelected && !applePayToken)
                  }
                  canSubmit={canSubmit}
                />
              </section>
            </PlanContextProvider>
          )}
        </form>
      </main>
    </BasicLayout>
  );
};

export default InvitationProcess;
