import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Address } from './components/AddressPreview';
import { BusinessData } from './components/BusinessVerification';
import { CheckoutStepStatus } from './components/CheckoutStep';
import { PaymentData } from './components/PaymentWidget/usePaymentWidget';
import { usePayPal } from './components/PaymentWidget/usePayPal';
import { fetchApi } from 'utils/fetchApi';
import {
  TAddressChangeEventPayload,
  getNormalizedLocale,
} from './components/AddressWidget';
import { getRegion } from 'hooks/useRegion';
import { Region } from 'hooks/usePlans';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { triggerApplePayTransaction } from './components/PaymentWidget/paymentRequests';
import { sendErrorToSentry } from 'utils/sentry';
import {
  CreditCard,
  CreditCradType,
  PaymentMethod,
} from 'pages/management/components/subscriptionManagement/useSubscriptionManagement';

export enum ActiveStep {
  InvoiceAddress = 1,
  BusinessVerification = 2,
  DeliveryAddress = 3,
  Payment = 4,
  TermsAndConditions = 5,
  Done = 0,
}

export const checkoutDetailsStorageKey = 'scw-checkout-details';
export const completedStepsStorageKey = 'scw-completed-steps';
export const stepsValueStorageKey = 'scw-steps-value';

export function isVatCheckRequired(address: Address): boolean {
  return (
    address.usage === 'work' &&
    [
      'AT',
      'BE',
      'BG',
      'CH',
      'CY',
      'CZ',
      'DE',
      'DK',
      'EE',
      'EL',
      'ES',
      'FI',
      'FR',
      'GB',
      'HR',
      'HU',
      'IE',
      'IT',
      'LI',
      'LT',
      'LU',
      'LV',
      'MT',
      'NL',
      'NO',
      'PL',
      'PT',
      'RO',
      'SE',
      'SI',
      'SK',
      'XI',
    ].includes(address.country.toUpperCase())
  );
}

export function isDeliveryAddressRequired(address: Address): boolean {
  return getRegion(address.country) === Region.EU;
}

export const useCheckout = ({
  authorizationToken,
  planVariant,
  vin,
  widgetApiKey,
  country,
  locale,
  currencyCode = '',
}: {
  authorizationToken: string;
  planVariant: string;
  vin: string;
  widgetApiKey: string;
  country: string;
  locale: string;
  currencyCode: string | undefined;
}) => {
  const navigate = useNavigate();
  const { token, ciamId, apiKey } = useAuthentication();
  const { marketplace } = useParams();
  const { triggerPayPalInitialTransaction, savedPaymentWidgetPayPalMethodId } =
    usePayPal({ token: authorizationToken, apiKey: widgetApiKey });
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [applePayToken, setApplePayToken] = useState<null | string>(null);
  const [checkoutDetails, setCheckoutDetails] = useState(
    (): {
      invoiceAddressId?: string;
      deliveryAddressId?: string;
      paymentRef?: string;
    } => {
      let storedCheckoutDetails =
        localStorage.getItem(checkoutDetailsStorageKey) || '';

      try {
        storedCheckoutDetails = JSON.parse(storedCheckoutDetails);
      } catch (error) {
        storedCheckoutDetails = '';
      }

      if (typeof storedCheckoutDetails === 'object') {
        return storedCheckoutDetails;
      }

      return {
        invoiceAddressId: undefined,
        deliveryAddressId: undefined,
        paymentRef: undefined,
      };
    },
  );
  const [completedSteps, setCompletedSteps] = useState((): Set<ActiveStep> => {
    try {
      const storedCompletedSteps = JSON.parse(
        localStorage.getItem(completedStepsStorageKey) || '',
      );

      return new Set(storedCompletedSteps);
    } catch (error) {
      return new Set();
    }
  });
  const [requiresBusinessVerification, setRequiresBusinessVerification] =
    useState(false);
  const [requiresDeliveryAddress, setRequiresDeliveryAddress] = useState(false);
  const [hasFailedBusinessVerification, setHasFailedBusinessVerification] =
    useState(false);
  const [stepsValue, setStepsValue] = useState(
    (): {
      [ActiveStep.InvoiceAddress]: string;
      [ActiveStep.BusinessVerification]: {
        legalName: string;
        vat: string;
      };
      [ActiveStep.DeliveryAddress]: string;
      [ActiveStep.Payment]: PaymentData;
    } => {
      let storedStepsValue = localStorage.getItem(stepsValueStorageKey) || '';

      try {
        storedStepsValue = JSON.parse(storedStepsValue);
      } catch (error) {
        storedStepsValue = '';
      }

      if (typeof storedStepsValue === 'object') {
        return storedStepsValue;
      }

      return {
        [ActiveStep.InvoiceAddress]: '',
        [ActiveStep.BusinessVerification]: {
          legalName: '',
          vat: '',
        },
        [ActiveStep.DeliveryAddress]: '',
        [ActiveStep.Payment]: {} as PaymentData,
      };
    },
  );
  const [currentEditingStep, setCurrentEditingStep] = useState<
    ActiveStep | undefined
  >();
  const [previewDetails, setPreviewDetails] = useState({
    [ActiveStep.InvoiceAddress]: {} as Address,
    [ActiveStep.DeliveryAddress]: {} as Address,
    [ActiveStep.Payment]: {} as PaymentData,
    isDeliveryTheSame:
      checkoutDetails.deliveryAddressId === checkoutDetails.invoiceAddressId,
    hasAcceptedTermsAndConditions: false,
    hasAcceptedRevocationPeriod: false,
  });
  const [isAddressAllowed, setIsAddressAllowed] = useState(true);
  const [isEditingAddress, setIsEditingAddress] = useState(false);

  const handleAddressEdit = useCallback((isEditing: boolean) => {
    setIsEditingAddress(isEditing);
  }, []);

  const setPreviewAfterApplePayTransaction = useCallback(
    async (applePayPaymentMethodId: string) => {
      const paymentMethodsResponse = await fetch(
        `${process.env.REACT_APP_PORSCHE_PAYMENTS_API_URL}/mypaymentmethods/${marketplace}`,
        {
          method: 'POST',
          headers: {
            'apikey': apiKey,
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            paymentMethodTypes: ['creditcard', 'directdebit'],
            curency: 'EUR',
            channel: 'Web',
            basket: [{ assortment: 'CHARGING', amount: 0 }],
          }),
        },
      );

      const savedPaymentMethods = await paymentMethodsResponse.json();

      const creditCards = savedPaymentMethods?.paymentMethods?.find(
        (paymentMethod: PaymentMethod) => paymentMethod.method === 'creditcard',
      );

      const matchingCreditCard = creditCards?.types.reduce(
        (acc: CreditCard, type: CreditCradType) => {
          const creditCardDetails = type.details?.find(
            (detail) => detail.id === applePayPaymentMethodId,
          );

          if (creditCardDetails) {
            return { ...creditCardDetails, typeDisplayName: type.displayName };
          }

          return acc;
        },
        {},
      );

      if (!matchingCreditCard.displayName) {
        return;
      }

      setPreviewDetails((currentPreviewDetails) => ({
        ...currentPreviewDetails,
        [ActiveStep.Payment]: {
          additionalData: {
            lastDigits: matchingCreditCard?.displayName?.slice(-4),
          },
          paymentMethodDetails: {
            paymentMethod: {
              id: '',
              typeDisplayName: matchingCreditCard.typeDisplayName,
              type: '',
              token: '',
            },
          },
        },
      }));
    },
    [token, apiKey, marketplace],
  );

  const clearPaymentSearchParams = useCallback(() => {
    if (
      searchParams.get('errorActionCode') ||
      searchParams.get('status') ||
      searchParams.get('paymentMethodId') ||
      searchParams.get('paypal')
    ) {
      searchParams.delete('errorActionCode');
      searchParams.delete('status');
      searchParams.delete('paymentMethodId');
      searchParams.delete('paypal');
      setSearchParams(searchParams);
    }
  }, [setSearchParams, searchParams]);

  const getNextUncompletedStep = useCallback(
    (
      latestCompletedSteps: Set<ActiveStep>,
      requestBusinessVerification = requiresBusinessVerification,
      requestDeliveryAddress = requiresDeliveryAddress,
    ): ActiveStep => {
      let nextActiveStep = ActiveStep.Done;

      (
        Object.values(ActiveStep).filter(
          (step) => !isNaN(parseInt(step.toString())),
        ) as ActiveStep[]
      ).some((step: ActiveStep) => {
        if (!latestCompletedSteps.has(step)) {
          if (
            step === ActiveStep.BusinessVerification &&
            !requestBusinessVerification
          ) {
            return false;
          }

          if (step === ActiveStep.DeliveryAddress && !requestDeliveryAddress) {
            return false;
          }

          nextActiveStep = step;
          return true;
        }

        return false;
      });

      return nextActiveStep;
    },
    [requiresBusinessVerification, requiresDeliveryAddress],
  );

  const [activeStep, setActiveStep] = useState(() => {
    return getNextUncompletedStep(completedSteps);
  });

  const fetchAddressById = useCallback(
    (addressId: string) =>
      fetch(
        `${process.env.REACT_APP_MY_PROFILE_API_URL}/addresses/${addressId}`,
        {
          headers: {
            apikey: widgetApiKey,
            Authorization: `Bearer ${authorizationToken}`,
          },
        },
      )
        .then((response) => response.json())
        .catch(() => ({})),
    [authorizationToken, widgetApiKey],
  );

  const toggleIsDeliveryTheSame = useCallback(() => {
    setPreviewDetails((currentPreviewDetails) => ({
      ...currentPreviewDetails,
      isDeliveryTheSame: !currentPreviewDetails.isDeliveryTheSame,
    }));
  }, []);

  const toggleHasAcceptedTermsAndConditions = useCallback(() => {
    setPreviewDetails((currentPreviewDetails) => ({
      ...currentPreviewDetails,
      hasAcceptedTermsAndConditions:
        !currentPreviewDetails.hasAcceptedTermsAndConditions,
    }));
  }, []);

  const toggleHasAcceptedRevocationPeriod = useCallback(() => {
    setPreviewDetails((currentPreviewDetails) => ({
      ...currentPreviewDetails,
      hasAcceptedRevocationPeriod:
        !currentPreviewDetails.hasAcceptedRevocationPeriod,
    }));
  }, []);

  const updateAddressById = useCallback(
    async (addressId: string, vatId: string, legalName: string) => {
      const address = await fetchAddressById(addressId);
      const normalizedLocale = getNormalizedLocale(locale);

      await fetch(
        `${process.env.REACT_APP_PORSCHE_PROFILES_API_URL}/${marketplace}/${normalizedLocale}/address/${addressId}`,
        {
          method: 'PUT',
          headers: {
            'apikey': widgetApiKey,
            'Authorization': `Bearer ${authorizationToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...address,
            vatId: vatId.replaceAll(/[^a-z0-9]/gi, ''),
            companyName1: legalName,
          }),
        },
      );
    },
    [authorizationToken, widgetApiKey, fetchAddressById, marketplace, locale],
  );

  const handleAddressChange = useCallback(
    (address: TAddressChangeEventPayload) => {
      const addressId =
        typeof address === 'string' ? address : address.selectedAddressId;
      if (activeStep === ActiveStep.InvoiceAddress) {
        setStepsValue((currentStepsValue) => ({
          ...currentStepsValue,
          [ActiveStep.InvoiceAddress]: addressId,
        }));
      }

      if (
        activeStep === ActiveStep.DeliveryAddress &&
        !previewDetails.isDeliveryTheSame
      ) {
        setStepsValue((currentStepsValue) => ({
          ...currentStepsValue,
          [ActiveStep.DeliveryAddress]: addressId,
        }));
      }
    },
    [activeStep, previewDetails],
  );

  const handleBusinessChange = useCallback((data: BusinessData) => {
    setStepsValue((currentStepsValue) => ({
      ...currentStepsValue,
      [ActiveStep.BusinessVerification]: {
        ...currentStepsValue[ActiveStep.BusinessVerification],
        [data.key]: data.value,
      },
    }));
  }, []);

  const handlePaymentChange = useCallback(
    (data: PaymentData) => {
      setApplePayToken(null);
      clearPaymentSearchParams();

      setStepsValue((currentStepsValue) => ({
        ...currentStepsValue,
        [ActiveStep.Payment]: data,
      }));
    },
    [clearPaymentSearchParams],
  );

  const handleStepEdit = useCallback(
    (step: ActiveStep) => {
      setCompletedSteps((currentCompletedSteps) => {
        const intermidiateCompletedSteps = new Set(currentCompletedSteps);

        intermidiateCompletedSteps.delete(step);

        if (currentEditingStep) {
          intermidiateCompletedSteps.add(currentEditingStep);
        }

        return intermidiateCompletedSteps;
      });

      setCurrentEditingStep(step);

      setActiveStep(step);
    },
    [currentEditingStep],
  );

  const handleBusinessVerificationCompletion = useCallback(
    async (address: any = {}) => {
      const isAutomaticVerification = address.companyName1 && address.vatId;
      const requiredValues = isAutomaticVerification
        ? { legalName: address.companyName1, vat: address.vatId }
        : stepsValue[ActiveStep.BusinessVerification];
      setIsLoading(true);

      let isVerified = false;

      try {
        const result = await fetchApi(
          `${process.env.REACT_APP_BASE_API_URL}/vat/verification`,
          token,
          {
            method: 'POST',
            body: JSON.stringify({
              ...requiredValues,
              country: previewDetails[ActiveStep.InvoiceAddress].country,
            }),
          },
        );

        isVerified = result.ok && (await result.json());
      } catch (error) {
        isVerified = false;
      }

      setHasFailedBusinessVerification(!isVerified);

      if (!isVerified) {
        setIsLoading(false);

        return;
      }

      const latestCompletedSteps = new Set(completedSteps).add(
        ActiveStep.BusinessVerification,
      );

      if (isAutomaticVerification) {
        latestCompletedSteps.add(ActiveStep.InvoiceAddress);
      } else {
        if (
          requiredValues.vat !== previewDetails[ActiveStep.InvoiceAddress].vatId
        ) {
          await updateAddressById(
            stepsValue[ActiveStep.InvoiceAddress],
            requiredValues.vat,
            requiredValues.legalName,
          );
        }
      }

      setIsLoading(false);
      setCompletedSteps(latestCompletedSteps);
      setActiveStep(
        getNextUncompletedStep(
          latestCompletedSteps,
          undefined,
          isAutomaticVerification
            ? isDeliveryAddressRequired(address)
            : undefined,
        ),
      );
      setCurrentEditingStep(undefined);
    },
    [
      completedSteps,
      getNextUncompletedStep,
      stepsValue,
      previewDetails,
      updateAddressById,
      token,
    ],
  );

  const handleInvoiceAddressCompletion = useCallback(async () => {
    setIsLoading(true);

    setPreviewDetails((currentPreviewDetails) => ({
      ...currentPreviewDetails,
      [ActiveStep.InvoiceAddress]: {
        addressId: stepsValue[ActiveStep.InvoiceAddress],
      } as Address,
    }));

    const address = await fetchAddressById(
      stepsValue[ActiveStep.InvoiceAddress],
    );

    if (address.country.toLowerCase() !== marketplace) {
      setIsLoading(false);
      setIsAddressAllowed(false);
      return;
    }

    setPreviewDetails((currentPreviewDetails) => ({
      ...currentPreviewDetails,
      [ActiveStep.InvoiceAddress]: address,
    }));

    setStepsValue((currentStepsValue) => ({
      ...currentStepsValue,
      [ActiveStep.BusinessVerification]: {
        legalName: address.companyName1 ?? '',
        vat: address.vatId ?? '',
      },
    }));

    setCheckoutDetails((currentCheckoutDetails) => ({
      ...currentCheckoutDetails,
      invoiceAddressId: stepsValue[ActiveStep.InvoiceAddress],
      deliveryAddressId: previewDetails.isDeliveryTheSame
        ? stepsValue[ActiveStep.InvoiceAddress]
        : currentCheckoutDetails.deliveryAddressId,
    }));

    const latestCompletedSteps = new Set(completedSteps).add(
      ActiveStep.InvoiceAddress,
    );

    const requestBusinessVerification = isVatCheckRequired(address);
    const requestDeliveryAddress = isDeliveryAddressRequired(address);

    setRequiresBusinessVerification(requestBusinessVerification);
    setRequiresDeliveryAddress(requestDeliveryAddress);

    let nextStep = getNextUncompletedStep(
      latestCompletedSteps,
      requestBusinessVerification,
      requestDeliveryAddress,
    );

    if (requestBusinessVerification) {
      nextStep = ActiveStep.BusinessVerification;
      latestCompletedSteps.delete(ActiveStep.BusinessVerification);
    }

    setIsLoading(false);
    setIsAddressAllowed(true);
    setCompletedSteps(latestCompletedSteps);
    setActiveStep(nextStep);
    setCurrentEditingStep(undefined);

    if (requestBusinessVerification && address.companyName1 && address.vatId) {
      handleBusinessVerificationCompletion(address);
    }
  }, [
    completedSteps,
    fetchAddressById,
    getNextUncompletedStep,
    handleBusinessVerificationCompletion,
    marketplace,
    previewDetails,
    stepsValue,
  ]);

  const handleDeliveryAddressCompletion = useCallback(async () => {
    setIsLoading(true);

    if (!previewDetails.isDeliveryTheSame) {
      setPreviewDetails((currentPreviewDetails) => ({
        ...currentPreviewDetails,
        [ActiveStep.DeliveryAddress]: {
          addressId: stepsValue[ActiveStep.DeliveryAddress],
        } as Address,
      }));

      const address = await fetchAddressById(
        stepsValue[ActiveStep.DeliveryAddress],
      );

      setPreviewDetails((currentPreviewDetails) => ({
        ...currentPreviewDetails,
        [ActiveStep.DeliveryAddress]: address,
      }));
    }

    setCheckoutDetails((currentDetails) => ({
      ...currentDetails,
      deliveryAddressId: previewDetails.isDeliveryTheSame
        ? currentDetails.invoiceAddressId
        : stepsValue[ActiveStep.DeliveryAddress],
    }));

    const latestCompletedSteps = new Set(completedSteps).add(
      ActiveStep.DeliveryAddress,
    );

    setIsLoading(false);
    setIsAddressAllowed(true);
    setCompletedSteps(latestCompletedSteps);
    setActiveStep(getNextUncompletedStep(latestCompletedSteps));
    setCurrentEditingStep(undefined);
  }, [
    completedSteps,
    fetchAddressById,
    getNextUncompletedStep,
    previewDetails.isDeliveryTheSame,
    stepsValue,
  ]);

  const handlePaymentNextStep = useCallback(
    (paymentMethodIdToSave: string) => {
      setIsLoading(true);

      clearPaymentSearchParams();

      setCheckoutDetails((currentDetails) => ({
        ...currentDetails,
        paymentRef: paymentMethodIdToSave,
      }));
      const latestCompletedSteps = new Set(completedSteps).add(
        ActiveStep.Payment,
      );
      setIsLoading(false);
      setCompletedSteps(latestCompletedSteps);
      setActiveStep(getNextUncompletedStep(latestCompletedSteps));
      setCurrentEditingStep(undefined);
    },
    [completedSteps, clearPaymentSearchParams, getNextUncompletedStep],
  );

  const handlePaymentCompletion = useCallback(async () => {
    clearPaymentSearchParams();
    setIsLoading(true);

    if (
      stepsValue[ActiveStep.Payment].paymentMethodDetails.paymentMethod
        .typeDisplayName === 'PayPal'
    ) {
      if (
        !stepsValue[ActiveStep.Payment].paymentMethodDetails
          .strongCustomerAuthenticationData?.shopperEmail
      ) {
        await triggerPayPalInitialTransaction();
        return setIsLoading(false);
      }

      handlePaymentNextStep(savedPaymentWidgetPayPalMethodId);
      return setIsLoading(false);
    } else if (applePayToken) {
      try {
        const applePayTransaction = await triggerApplePayTransaction({
          apiKey: widgetApiKey,
          token: authorizationToken,
          ciamId: ciamId,
          marketplace: marketplace || 'de',
          applePayToken,
          userAgent: navigator.userAgent,
          currency: currencyCode,
        });

        const applePaymentMethodId = applePayTransaction?.paymentMethodId || '';

        await setPreviewAfterApplePayTransaction(applePaymentMethodId);
        setIsLoading(false);
        return handlePaymentNextStep(applePaymentMethodId);
      } catch (error) {
        console.log('Something went wrong with Apple Pay Transaction', error);
        sendErrorToSentry(error);
        throw new Error('Apple Pay transaction failed');
      }
    }
    setIsLoading(false);
    handlePaymentNextStep(
      stepsValue[ActiveStep.Payment].paymentMethodDetails.paymentMethod.id,
    );
  }, [
    clearPaymentSearchParams,
    stepsValue,
    applePayToken,
    marketplace,
    triggerPayPalInitialTransaction,
    handlePaymentNextStep,
    savedPaymentWidgetPayPalMethodId,
    authorizationToken,
    ciamId,
    widgetApiKey,
    setPreviewAfterApplePayTransaction,
    currencyCode,
  ]);

  const handleTermsAndConditionCompletion = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await fetchApi(
        `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions`,
        token,
        {
          method: 'POST',
          body: JSON.stringify({
            vin,
            planVariant,
            ...checkoutDetails,
            ...stepsValue[ActiveStep.BusinessVerification],
            locale: locale!,
          }),
        },
      );

      if (!response.ok) {
        return navigate(`/${country}/${locale}/${vin}/confirmation/failure`);
      }

      navigate(`/${country}/${locale}/${vin}/confirmation/success`);
    } catch (error: unknown) {
      const isSanctionCheckError =
        error &&
        typeof error === 'object' &&
        'message' in error &&
        (error as any).message === 'sanction check failed';

      navigate(
        `/${country}/${locale}/${vin}/confirmation/failure${
          isSanctionCheckError ? '?sc=true' : ''
        }`,
      );
    } finally {
      localStorage.removeItem(checkoutDetailsStorageKey);
      localStorage.removeItem(completedStepsStorageKey);
      localStorage.removeItem(stepsValueStorageKey);
    }
  }, [
    checkoutDetails,
    planVariant,
    vin,
    stepsValue,
    navigate,
    country,
    locale,
    token,
  ]);

  const getStepStatus = useCallback(
    (step: ActiveStep) => {
      if (activeStep === step) {
        return CheckoutStepStatus.Active;
      }

      if (completedSteps.has(step)) {
        return CheckoutStepStatus.Completed;
      }

      return CheckoutStepStatus.Inactive;
    },
    [activeStep, completedSteps],
  );

  useEffect(() => {
    const setInitial = async () => {
      if (
        checkoutDetails.invoiceAddressId &&
        !Object.values(previewDetails[ActiveStep.InvoiceAddress]).length
      ) {
        try {
          const initialAddress = await fetchAddressById(
            checkoutDetails.invoiceAddressId,
          );

          if (
            isVatCheckRequired(initialAddress) &&
            !completedSteps.has(ActiveStep.BusinessVerification)
          ) {
            setRequiresBusinessVerification(true);
            setActiveStep(ActiveStep.BusinessVerification);
          }

          if (isDeliveryAddressRequired(initialAddress)) {
            setRequiresDeliveryAddress(true);
          }

          setPreviewDetails((currentPreviewData) => ({
            ...currentPreviewData,
            [ActiveStep.InvoiceAddress]: initialAddress,
          }));
        } catch (error: unknown) {
          console.log(error);
        }
      }
    };

    if (
      checkoutDetails.invoiceAddressId &&
      !Object.values(previewDetails[ActiveStep.InvoiceAddress]).length
    ) {
      setInitial();
    }
  }, [
    checkoutDetails.invoiceAddressId,
    completedSteps,
    fetchAddressById,
    previewDetails,
  ]);

  useEffect(() => {
    if (
      checkoutDetails.deliveryAddressId &&
      !Object.values(previewDetails[ActiveStep.DeliveryAddress]).length
    ) {
      fetchAddressById(checkoutDetails.deliveryAddressId).then(
        (address: Address) => {
          setPreviewDetails((currentPreviewData) => ({
            ...currentPreviewData,
            [ActiveStep.DeliveryAddress]: address,
          }));
        },
      );
    }
  }, [checkoutDetails.deliveryAddressId, fetchAddressById, previewDetails]);

  useEffect(() => {
    if (stepsValue[ActiveStep.Payment]) {
      setPreviewDetails((currentPreviewDetails) => ({
        ...currentPreviewDetails,
        [ActiveStep.Payment]: stepsValue[ActiveStep.Payment],
      }));
    }
  }, [stepsValue]);

  useEffect(() => {
    localStorage.setItem(
      completedStepsStorageKey,
      JSON.stringify([...completedSteps]),
    );
  }, [completedSteps]);

  useEffect(() => {
    localStorage.setItem(
      checkoutDetailsStorageKey,
      JSON.stringify(checkoutDetails),
    );
  }, [checkoutDetails]);

  useEffect(() => {
    localStorage.setItem(stepsValueStorageKey, JSON.stringify(stepsValue));
  }, [stepsValue]);

  const handleApplePayToken = useCallback((token: string) => {
    setApplePayToken(token);
  }, []);

  const isPayPalSelected = useMemo(() => {
    return checkoutDetails.paymentRef === savedPaymentWidgetPayPalMethodId;
  }, [checkoutDetails.paymentRef, savedPaymentWidgetPayPalMethodId]);

  const isInitialApplePaySelected = useMemo(() => {
    return (
      stepsValue?.[ActiveStep.Payment]?.paymentMethodDetails?.paymentMethod
        .type === 'applepay' &&
      !stepsValue?.[ActiveStep.Payment]?.paymentMethodDetails?.paymentMethod.id
    );
  }, [stepsValue]);

  return {
    activeStep,
    checkoutDetails,
    completedSteps,
    getStepStatus,
    handleAddressChange,
    handleAddressEdit,
    handleBusinessChange,
    handleBusinessVerificationCompletion,
    handleDeliveryAddressCompletion,
    handleInvoiceAddressCompletion,
    handlePaymentChange,
    handlePaymentCompletion,
    handleStepEdit,
    handleTermsAndConditionCompletion,
    hasFailedBusinessVerification,
    isAddressAllowed,
    isEditingAddress,
    isLoading,
    isPayPalSelected,
    previewDetails,
    requiresBusinessVerification,
    requiresDeliveryAddress,
    stepsValue,
    toggleHasAcceptedTermsAndConditions,
    toggleIsDeliveryTheSame,
    handleApplePayToken,
    toggleHasAcceptedRevocationPeriod,
    isInitialApplePaySelected,
    applePayToken,
  };
};
