export const getFormData = <T = void>(
  e: React.FormEvent<HTMLFormElement> &
    (T extends void
      ? 'You must provide form data type'
      : React.FormEvent<HTMLFormElement>),
): T => {
  e.preventDefault();
  const formData = new FormData(e.currentTarget);
  const formJson = Object.fromEntries(formData);
  return formJson as unknown as T;
};
