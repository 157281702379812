import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import style from './additionalDisclaimer.module.scss';
import { PText } from '@porsche-design-system/components-react';
import { useIntl } from 'react-intl';
import { messages } from './additionalDisclaimer.messages';
import { PlanVariant } from 'hooks/usePlans';

type AdditionalDisclaimerProps = {
  planVariants?: PlanVariant[];
  noSpacing?: boolean;
};

const englishTranslatedLinks = {
  V1: 'https://connect-store2.porsche.com/medias/Charging-bersicht-August2023-ENG.pdf?context=bWFzdGVyfHJvb3R8MzM4NjQ0OHxhcHBsaWNhdGlvbi9wZGZ8aGY5L2g1Ni84OTQ5ODUzNDU0MzY2LnBkZnw2MTlkN2ZiZjQ3YjQ1NjU0M2ZjMDM3OWU2ZTU3ZmFkYzQ2ZDQ2YzliODY5ZTY1MWU2ODQ2YWE4N2EzYTA4ZjU5',
  V2: 'https://www.porsche.com/international/charging-service-plus-prices/',
};

export const AdditionalDisclaimer: FC<AdditionalDisclaimerProps> = observer(
  ({ planVariants, noSpacing }) => {
    const intl = useIntl();
    const includesV1Variant = planVariants?.some((planVariant) =>
      planVariant.includes('V1'),
    );
    const includesV2Variant = planVariants?.some((planVariant) =>
      planVariant.includes('V2'),
    );
    const includesV1AndV2Variants = includesV1Variant && includesV2Variant;

    return (
      <div className={noSpacing ? style.root : style.rootWithSpacing}>
        <PText size="xx-small" color="contrast-medium">
          {intl.formatMessage(messages.taxText)}
        </PText>
        <PText size="xx-small" color="contrast-medium">
          {intl.formatMessage(messages.newVehicleText)}
        </PText>
        <PText size="xx-small" color="contrast-medium">
          {intl.formatMessage(messages.chargingRatesText)}
        </PText>
        <PText size="xx-small" color="contrast-medium">
          {intl.formatMessage(messages.terminationText)}
        </PText>
        {includesV1AndV2Variants ? (
          <PText size="xx-small" color="contrast-medium">
            {intl.formatMessage(messages.multiPriceLinkText, {
              linkHereText1: (
                <a
                  href={englishTranslatedLinks.V1}
                  target="_blank"
                  rel="noreferrer"
                >
                  Porsche Charging Service Taycan
                </a>
              ),
              linkHereText2: (
                <a
                  href={englishTranslatedLinks.V2}
                  target="_blank"
                  rel="noreferrer"
                >
                  Porsche Charging Service (Plus)
                </a>
              ),
            })}
          </PText>
        ) : (
          <PText size="xx-small" color="contrast-medium">
            {intl.formatMessage(messages.singlePriceLinkText, {
              linkHereText: (
                <a
                  href={englishTranslatedLinks[includesV1Variant ? 'V1' : 'V2']}
                  target="_blank"
                  rel="noreferrer"
                >
                  {includesV1Variant
                    ? 'Porsche Charging Service Taycan'
                    : 'Porsche Charging Service (Plus)'}
                </a>
              ),
            })}
          </PText>
        )}
      </div>
    );
  },
);
