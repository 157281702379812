export const fetchApi = (
  url: string,
  token: string,
  options: RequestInit = {},
): Promise<Response> => {
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    ...options,
  };

  return fetch(url, mergedOptions);
};
