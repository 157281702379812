import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  included: {
    defaultMessage: 'All displayed prices include taxes.',
    id: 'flyout.overview.disclaimer.included',
  },
  excluded: {
    defaultMessage: 'Taxes and other fees are not included.',
    id: 'flyout.overview.disclaimer.excluded',
  },
  seeInApp: {
    defaultMessage:
      'See detailed prices at charging stations or in the app before the charging session.',
    id: 'flyout.overview.disclaimer.seeInApp',
  },
});
