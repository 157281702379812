import React, { ReactNode, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Entitlement } from '../VehicleContext';
import { vehicleContext } from '../VehicleContextProvider';

interface ExclusiveOnlyProps {
  children: ReactNode;
}

/**
 * Displays children if the current vehicle is entitled to the exclusive flow
 */
export const ExclusiveOnly: React.FunctionComponent<ExclusiveOnlyProps> =
  observer((props) => {
    const context = useContext(vehicleContext);

    if (context.entitlement !== Entitlement.Exclusive) {
      return null;
    }

    return <React.Fragment>{props.children}</React.Fragment>;
  });
