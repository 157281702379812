import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ac: {
    defaultMessage: 'AC Charging Price',
    id: 'tariff.inline.ac.title',
  },
  dc: {
    defaultMessage: 'DC Charging Price',
    id: 'tariff.inline.dc.title',
  },
  ionity: {
    defaultMessage: 'IONITY Charging Price',
    id: 'tariff.inline.ionity.title',
  },
  preferredTitle: {
    defaultMessage: 'Preferred Charging Price',
    id: 'tariff.inline.preferred.title',
  },
});
