import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  loading: {
    defaultMessage: 'Loading vehicle data...',
    description: 'Spinner loading sub text',
    id: 'vehicle.barrier.loading',
  },
  errorTitle: {
    defaultMessage: 'Failed to load vehicle data',
    id: 'vehicle.barrier.error.title',
  },
  errorText: {
    defaultMessage: 'Please try again later',
    id: 'vehicle.barrier.error.text',
  },
  entitlement: {
    defaultMessage: 'This vehicle is not entitled to MyCharging services.',
    id: 'vehicle.barrier.entitlement',
  },
});
