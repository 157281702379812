import React, { ReactNode, useEffect } from 'react';
import { useAuthentication } from '../../../hooks/useAuthentication/useAuthentication';
import { PSpinner, PText } from '@porsche-design-system/components-react';
import style from './authBarrier.module.scss';
import { messages } from './authBarrier.messages';
import { FormattedMessage } from 'react-intl';
import { BackendClient } from '../../../backend-client';

interface AuthBarrierProps {
  children?: ReactNode;
}

/**
 * Display a loading spinner if the user is not authenticated
 * @param props
 * @constructor
 */
export const AuthBarrier: React.FunctionComponent<AuthBarrierProps> = (
  props,
) => {
  const auth = useAuthentication();

  useEffect(() => {
    BackendClient.OpenAPI.HEADERS = {
      'Authorization': `Bearer ${auth.token}`,
      'apikey': auth.apiKey,
      'Content-Type': 'application/json',
    };
  }, [auth.token, auth.apiKey]);

  if (auth.token === '') {
    return (
      <div className={style.root}>
        <PSpinner />
        <PText size={'medium'}>
          <FormattedMessage {...messages.message} />
        </PText>
      </div>
    );
  }

  return <>{props.children}</>;
};
