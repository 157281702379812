import { PHeading, PText } from '@porsche-design-system/components-react';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from '../../invitationPage.messages';
import style from '../../invitation.module.scss';
import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';

const InvitationHeader = () => {
  const intl = useIntl();
  const { vehicleName } = useContext(vehicleContext);

  return (
    <>
      <PHeading size="x-large" className={style.title}>
        <FormattedMessage {...messages.title} />
      </PHeading>
      <PText className={style.pt2} size="medium" weight="regular">
        {intl.formatMessage(messages.subTitle, { carModel: vehicleName })}
      </PText>
    </>
  );
};

export default InvitationHeader;
