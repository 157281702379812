import {
  useCallback,
  useEffect,
  useContext,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { useRegion } from './useRegion';
import { fetchApi } from 'utils/fetchApi';
import { useParams } from 'react-router-dom';
import { FeeType, RawPlan } from '../components/context/plan/planContext.types';
import { useAuthentication } from './useAuthentication/useAuthentication';
import { useIntl } from 'react-intl';
import { PlanContext } from '../components/context/plan/PlanContext';

// plan variants for each major version of the PCS
export enum PlanVariant {
  V1_PREMIUM_INCLUSIVE = 'V1_PREMIUM_INCLUSIVE',
  V2_PREMIUM_INCLUSIVE = 'V2_PREMIUM_INCLUSIVE',
  V2_PREMIUM = 'V2_PREMIUM',
  V2_BASIC = 'V2_BASIC',
}

export enum PcsVersion {
  V1 = '1.0',
  V2 = '2.0',
  V21 = '2.1',
}

export enum Region {
  EU = 'EU',
  US = 'US',
  CA = 'CA',
}

export type Price = {
  netAmount: {
    cents: number;
    currency: string;
    decimal: number;
    fractionDigits: number;
  };
  grossAmount: {
    cents: number;
    currency: string;
    decimal: number;
    fractionDigits: number;
  };
};

export type Option = {
  ident: string;
  productIdent: string;
  feeType: unknown;
  country: unknown;
  chargePointClass: string;
  pricingModel: {
    price: Price;
    priceTiers?: { price: Price; quantity?: number; type: string }[];
    taxRate: number;
  };
};

export type PlanObject = {
  id: number;
  ident: string;
  type: 'PCS';
  variant: PlanVariant;
  pcsVersion: PcsVersion;
  region: Region;
  charmProductId: string;
  exclusiveFollowUpPlanId: number | null;
  options: Record<string, Option[]>;
  name: string;
};

const formatCurrency = (locale: string, currency: string, amount: number) => {
  const intl = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });
  return intl.format(amount);
};

export const usePlans = (
  onSetSelected?: Dispatch<SetStateAction<PlanVariant | null>>,
) => {
  const vehicleContextFromProvider = useContext(vehicleContext);
  const params = useParams<{ marketplace: string }>();
  const country = params.marketplace?.toUpperCase();
  const planRegion = useRegion();
  const [availablePlansData, setAvailablePlansData] = useState<
    null | PlanObject[]
  >(null);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const { token } = useAuthentication();
  const intl = useIntl();

  const getPlanOptionsByCountry = useCallback(
    (planForOptions: PlanObject) => {
      return planForOptions.options[country || 'DE'];
    },
    [country],
  );

  const findEntitledPlans = useCallback(async () => {
    const planVariants =
      vehicleContextFromProvider.entitlementObject?.planVariants;

    if (!planVariants?.length) {
      return;
    }

    setIsLoadingPlans(true);

    try {
      const planBaseUrl = `${process.env.REACT_APP_BASE_API_URL}/plans`;

      const planSearchParams = { region: planRegion } as {
        [key: string]: string;
      };

      if (country) {
        planSearchParams.country = country;
      }

      const planUrlSearchParams = new URLSearchParams(planSearchParams);

      if (planVariants.length) {
        planVariants.forEach((variant) =>
          planUrlSearchParams.append('variants', variant),
        );
      }

      const plansResponse = await fetchApi(
        `${planBaseUrl}?${planUrlSearchParams}`,
        token,
        {
          method: 'GET',
        },
      );

      const plans = ((await plansResponse.json()) as PlanObject[]).sort(
        (a, b) => (a.variant > b.variant ? -1 : 1),
      );

      setAvailablePlansData(plans);
    } catch (error: unknown) {
      console.log('Something went wrong when fetching plans');
    } finally {
      setIsLoadingPlans(false);
    }
  }, [vehicleContextFromProvider, planRegion, country, token]);

  useEffect(() => {
    findEntitledPlans();
  }, [findEntitledPlans]);

  const normalizeEUPlan = useCallback(
    (planToNormalize: PlanObject) => {
      const planOptionsByCountry = getPlanOptionsByCountry(planToNormalize);

      const getFormattedPrice = (type: 'AC' | 'DC') => {
        const chargingOption = planOptionsByCountry.find(
          (countryOption) =>
            countryOption.chargePointClass?.includes(type) &&
            countryOption.feeType === FeeType.ENERGY,
        );

        const priceTier = chargingOption?.pricingModel.priceTiers?.find(
          (priceTier) => priceTier.type === 'PER_UNIT',
        );

        // This has the side effect that zero equals false, I think this is intentional
        if (priceTier?.price.grossAmount.decimal) {
          return formatCurrency(
            intl.locale,
            priceTier.price.grossAmount.currency,
            priceTier.price.grossAmount.decimal,
          );
        }
        return undefined;
      };

      const getFormattedBlocking = (
        type: 'AC' | 'DC' | 'HPC',
        typeExtension?: '22_KW' | '50_KW' | '150_KW' | '350_KW',
      ) => {
        const blockingChargingOption = planOptionsByCountry.find(
          (countryOption) =>
            countryOption.chargePointClass?.includes(
              `${type}${typeExtension ? `_${typeExtension}` : ''}`,
            ) &&
            ((!typeExtension &&
              !countryOption.chargePointClass?.includes('KW')) ||
              typeExtension) &&
            countryOption.feeType === FeeType.BLOCKING,
        );

        const blockingPriceTier =
          blockingChargingOption?.pricingModel.priceTiers?.find(
            (priceTier) => priceTier.type === 'PER_UNIT' && !priceTier.quantity,
          );

        const blockingVolumePriceTier =
          blockingChargingOption?.pricingModel.priceTiers?.find(
            (priceTier) => priceTier.type === 'PER_UNIT' && priceTier.quantity,
          );

        const blockingPrice = (() => {
          // This has the side effect that zero equals false, I think this is intentional
          if (blockingPriceTier?.price.grossAmount.decimal) {
            return `${
              typeExtension && type !== 'AC'
                ? `${typeExtension.split('_')[0]}kW: `
                : ''
            }${formatCurrency(
              intl.locale,
              blockingPriceTier.price.grossAmount.currency!,
              blockingPriceTier.price.grossAmount.decimal,
            )}`;
          }

          return undefined;
        })();

        const blockingVolume = blockingVolumePriceTier?.quantity
          ? `${blockingVolumePriceTier?.quantity} min`
          : undefined;

        return { price: blockingPrice, volume: blockingVolume };
      };

      return {
        ...planToNormalize,
        type: planToNormalize?.variant,
        title: planToNormalize?.name,
        options: planOptionsByCountry,
        acPrice: getFormattedPrice('AC'),
        dcPrice: getFormattedPrice('DC'),
        acBlocking: getFormattedBlocking('AC'),
        dcBlocking: getFormattedBlocking('DC'),
        //prefered
        ac22Blocking: getFormattedBlocking('AC', '22_KW'),
        dc22Blocking: getFormattedBlocking('DC', '22_KW'),
        dc50Blocking: getFormattedBlocking('DC', '50_KW'),
        dc150Blocking: getFormattedBlocking('DC', '150_KW'),
        dc350Blocking: getFormattedBlocking('HPC', '350_KW'),
        //inoity
      };
    },
    [getPlanOptionsByCountry, intl.locale],
  );

  // Quickfix
  // TODO: Expand with approprirate property values
  const normalizeNARPlan = useCallback((planToNormalize: PlanObject) => {
    return {
      ...planToNormalize,
      title: planToNormalize.name,
      subtitle: '',
      type: planToNormalize.variant,
    };
  }, []);

  const normalizePlan = useCallback(
    (planToNormalize: PlanObject) => {
      if (planToNormalize.region !== Region.EU) {
        return {
          ...normalizeNARPlan(planToNormalize),
          context: new PlanContext({
            plan: planToNormalize as unknown as RawPlan,
            marketplace: params.marketplace!.toUpperCase(),
            locale: intl.locale,
            dummy: false,
          }),
        };
      }

      return {
        ...normalizeEUPlan(planToNormalize),
        context: new PlanContext({
          plan: planToNormalize as unknown as RawPlan,
          marketplace: params.marketplace!.toUpperCase(),
          locale: intl.locale,
          dummy: false,
        }),
      };
    },
    [intl.locale, normalizeEUPlan, normalizeNARPlan, params.marketplace],
  );

  const premiumPlan = useMemo(() => {
    const premium =
      availablePlansData?.length &&
      availablePlansData?.find((plan) => plan.variant.includes('PREMIUM'));

    if (premium) {
      return normalizePlan(premium);
    }

    return null;
  }, [normalizePlan, availablePlansData]);

  const basicPlan = useMemo(() => {
    const basic =
      availablePlansData?.length &&
      availablePlansData?.find((plan) => plan.variant.includes('BASIC'));

    if (basic) {
      return normalizePlan(basic);
    }

    return null;
  }, [availablePlansData, normalizePlan]);

  const normalizedPlans = useMemo(() => {
    if (!availablePlansData?.length) {
      return null;
    }

    const normalizedAvailablePlans = availablePlansData.map(
      (singlePlanObject) => normalizePlan(singlePlanObject),
    );

    const sortedNormalizedAvailablePlans = normalizedAvailablePlans.sort(
      (a, b) => {
        const getOrderPoints = (sortingParam: string) => {
          if (sortingParam.includes('INCLUSIVE')) {
            return 1;
          } else if (sortingParam.includes('PREMIUM')) {
            return 2;
          }

          return 3;
        };

        return getOrderPoints(a.type) - getOrderPoints(b.type);
      },
    );

    return sortedNormalizedAvailablePlans;
  }, [availablePlansData, normalizePlan]);

  useEffect(() => {
    if (normalizedPlans?.length && onSetSelected) {
      onSetSelected(normalizedPlans[0].type);
    }
  }, [normalizedPlans, onSetSelected]);

  return {
    isLoadingPlans,
    premiumPlan,
    basicPlan,
    normalizedPlans,
  };
};
