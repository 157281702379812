import { useScript } from 'hooks/useScript';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isCustomEvent } from 'utils/isCustomEvent';
import { usePayPal } from './usePayPal';

export type PaymentData = {
  additionalData: {
    lastDigits: string;
  };
  paymentMethodDetails: {
    paymentMethod: {
      id: string;
      typeDisplayName: string;
      type: string;
      token: string;
    };
    strongCustomerAuthenticationData?: {
      shopperEmail: string;
    };
  };
};

interface IUsePaymentWidget {
  apiKey: string;
  token: string;
  isPayPalSelected: boolean;
  defaultPaymentMethodId?: string;
  onChange?: (paymentData: PaymentData) => void;
  handleApplePayTokenSave?: (token: string) => void;

  shouldUseIntegratedLogic?: boolean;
  onChangePaymentId?: (paimentMethodId: string) => void;
}

export const usePaymentWidget = ({
  apiKey,
  token,
  isPayPalSelected: isPayPalSelectedByDefault,
  defaultPaymentMethodId,
  onChange,
  onChangePaymentId,
  handleApplePayTokenSave,
  shouldUseIntegratedLogic,
}: IUsePaymentWidget) => {
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<
    string | undefined
  >(defaultPaymentMethodId);
  const [resolvedDefault, setResolvedDefault] = useState<string | undefined>();

  const {
    triggerPayPalInitialTransaction,
    savedPaymentWidgetPayPalMethodId,
    isCommingFromPayPalRedirect,
    payPalPaymentStatus,
  } = usePayPal({
    apiKey,
    token,
    shouldIgnoreHook: !shouldUseIntegratedLogic,
  });

  const [isPayPalSelected, setIsPayPalSelected] = useState<boolean>(
    isPayPalSelectedByDefault || isCommingFromPayPalRedirect(),
  );

  const defaultPaymentOption = useMemo(() => {
    if (resolvedDefault) {
      return resolvedDefault;
    }

    let paymentOption: string | undefined = '{"type": "new-credit-card"}';

    if (selectedPaymentMethodId) {
      paymentOption = `{"id": "${selectedPaymentMethodId}"}`;
    }

    if (selectedPaymentMethodId && isPayPalSelected) {
      paymentOption = '{"type": "paypal"}';
    }

    if (!selectedPaymentMethodId) {
      paymentOption = undefined;
    }

    setResolvedDefault(paymentOption);

    return paymentOption;
  }, [isPayPalSelected, resolvedDefault, selectedPaymentMethodId]);

  const [paymentStatus, setPaymentStatus] = useState<PaymentData>();

  const loadingScriptState = useScript(
    process.env.REACT_APP_PORSCHE_PAYMENT_WIDGET_URL!,
  );

  const widgetRef = useRef() as React.MutableRefObject<HTMLElement> | null;
  const paymentButtonWidgetRef =
    useRef() as React.MutableRefObject<HTMLElement> | null;

  let shouldShowSignInButton = false;
  if (
    shouldUseIntegratedLogic &&
    paymentStatus?.paymentMethodDetails.paymentMethod.typeDisplayName.toLowerCase() ===
      'paypal' &&
    !paymentStatus?.paymentMethodDetails?.strongCustomerAuthenticationData
      ?.shopperEmail
  ) {
    shouldShowSignInButton = true;
  }

  useEffect(() => {
    if (loadingScriptState !== 'ready') {
      return;
    }

    const eventHandler: EventListener = (event) => {
      if (!isCustomEvent<PaymentData>(event)) {
        return;
      }

      setPaymentStatus(event.detail);
      onChange?.(event.detail);

      const isPayPalSelected =
        event.detail?.paymentMethodDetails?.paymentMethod.typeDisplayName.toLowerCase() ===
        'paypal';
      setIsPayPalSelected(isPayPalSelected);

      let paymentMethodId: string | undefined =
        event.detail?.paymentMethodDetails.paymentMethod?.id;

      setSelectedPaymentMethodId(paymentMethodId);

      if (isPayPalSelected) {
        paymentMethodId = savedPaymentWidgetPayPalMethodId;
      }

      if (!paymentMethodId) return;

      onChangePaymentId?.(paymentMethodId);
    };

    const paymentButtonHandler: EventListener = (event) => {
      if (!isCustomEvent<PaymentData>(event)) {
        return;
      }

      handleApplePayTokenSave?.(
        event?.detail?.paymentMethodDetails.paymentMethod.token,
      );
    };

    const element = widgetRef?.current;
    const paymentButttonElement = paymentButtonWidgetRef?.current;

    if (element) {
      element.addEventListener('paymentMethodSelectedHandler', eventHandler);
    }

    if (paymentButttonElement) {
      paymentButttonElement.addEventListener(
        'paymentMethodSelectedHandler',
        paymentButtonHandler,
      );
    }

    return () => {
      element?.removeEventListener(
        'paymentMethodSelectedHandler',
        eventHandler,
      );

      paymentButttonElement?.removeEventListener(
        'paymentMethodSelectedHandler',
        paymentButtonHandler,
      );
    };
  }, [
    resolvedDefault,
    defaultPaymentMethodId,
    onChange,
    loadingScriptState,
    savedPaymentWidgetPayPalMethodId,
    payPalPaymentStatus,
    handleApplePayTokenSave,
    onChangePaymentId,
  ]);

  const isWidgetLoading = loadingScriptState !== 'ready';

  const handleSubmit = () => {
    if (shouldShowSignInButton) {
      triggerPayPalInitialTransaction();
    }
  };

  return {
    defaultPaymentOption,
    handleSubmit,
    isWidgetLoading,
    paymentStatus,
    shouldShowSignInButton,
    widgetRef,
    paymentButtonWidgetRef,
  };
};
