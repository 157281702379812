import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { PText } from '@porsche-design-system/components-react';
import { messages } from './baseFee.messages';
import { FormattedMessage } from 'react-intl';
import { InclusivePeriod } from './InclusivePeriod';
import style from './baseFee.module.scss';
import { planContext } from '../../../../context/plan/PlanContext';

/**
 * Row to display the monthly base fee of a contract
 */
export const BaseFee: React.FunctionComponent = observer((props) => {
  const context = useContext(planContext);

  return (
    <div data-testid={'baseFee'}>
      <div className={style.title}>
        <PText>
          <FormattedMessage {...messages.monthlyTitle} />
        </PText>
      </div>
      <div className={style.container}>
        <PText weight={'bold'} align={'right'} className={style.text}>
          <FormattedMessage
            {...messages.monthlyText}
            values={{ baseFee: context.baseFee }}
          />
        </PText>
        <InclusivePeriod />
      </div>
    </div>
  );
});
