import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dateFromLabel: {
    id: 'subscriptionHistoryItem.dateFromLabel',
    defaultMessage: 'From',
    description: 'Label date from',
  },
  dateToLabel: {
    id: 'subscriptionHistoryItem.dateToLabel',
    defaultMessage: 'To',
    description: 'Label date to',
  },
  billsLinkLabel: {
    id: 'subscriptionHistoryItem.billsLinkLabel',
    defaultMessage: 'Invoices',
    description: 'Label bills link',
  },
  historyListNoResult: {
    id: 'subscriptionHistoryItem.historyListNoResult',
    defaultMessage: 'No results',
    description: 'History links no result',
  },
});
