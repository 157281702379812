import React, { createContext, useMemo } from 'react';
import { ChargingEuBasic } from './eu/ChargingEuBasic';
import { ChargingEuPremium } from './eu/ChargingEuPremium';
import { ChargingEuLegacy } from './eu/ChargingEuLegacy';
import { ChargingNaLegacy } from './na/ChargingNaLegacy';
import { EuropeOnly } from '../../context/plan/discriminators/region/EuropeOnly';
import { BasicOnly } from '../../context/plan/discriminators/tariff/BasicOnly';
import { PremiumOnly } from '../../context/plan/discriminators/tariff/PremiumOnly';
import { LegacyOnly } from '../../context/plan/discriminators/tariff/LegacyOnly';
import { NorthAmericaOnly } from '../../context/plan/discriminators/region/NorthAmericaOnly';
import { messages as defaultMessages } from './charging.messages';
import defaultStyle from './charging.module.scss';

const defaultComponents = {
  simpleSegmentSubTitle: {
    size: 'small',
  },
  simpleSegmentSubText: {
    size: 'small',
  },
};

export type ChargingStyle = typeof defaultStyle;
export type ChargingMessages = typeof defaultMessages;
export type ChargingComponents = typeof defaultComponents;

export type ChargingOverrides = {
  style: ChargingStyle;
  messages: ChargingMessages;
  components: ChargingComponents;
};

export const chargingOverrides = createContext<ChargingOverrides>({
  style: defaultStyle,
  messages: defaultMessages,
  components: defaultComponents,
});

export interface ChargingProps {
  /**
   * Override default messages
   */
  messages?: Partial<ChargingMessages>;
  /**
   * Override default styles
   */
  style?: Partial<ChargingStyle>;
  /**
   * Override sub component props
   */
  components?: Partial<ChargingComponents>;
}

/**
 * Display charging cost <br/>
 * Discriminates between tariffs and regions
 */
export const Charging: React.FunctionComponent<ChargingProps> = (props) => {
  const messages = useMemo(() => {
    if (!props.messages) {
      return defaultMessages;
    }
    return {
      ...defaultMessages,
      ...props.messages,
    } as ChargingMessages;
  }, [props.messages]);

  const style = useMemo(() => {
    if (!props.style) {
      return defaultStyle;
    }
    return {
      ...defaultStyle,
      ...props.style,
    } as ChargingStyle;
  }, [props.style]);

  const components = useMemo(() => {
    if (!props.components) {
      return defaultComponents;
    }
    return {
      ...defaultComponents,
      ...props.components,
    } as ChargingComponents;
  }, [props.components]);

  return (
    <React.Fragment>
      <chargingOverrides.Provider value={{ style, messages, components }}>
        <div style={{ display: 'none' }} data-testid={'charging'} />
        <EuropeOnly>
          <BasicOnly>
            <ChargingEuBasic />
          </BasicOnly>
          <PremiumOnly>
            <ChargingEuPremium />
          </PremiumOnly>
          <LegacyOnly>
            <ChargingEuLegacy />
          </LegacyOnly>
        </EuropeOnly>
        <NorthAmericaOnly>
          <LegacyOnly>
            <ChargingNaLegacy />
          </LegacyOnly>
        </NorthAmericaOnly>
      </chargingOverrides.Provider>
    </React.Fragment>
  );
};
