import React from 'react';
import style from './customerService.module.scss';
import {
  PIcon,
  PLinkPure,
  PText,
} from '@porsche-design-system/components-react';
import { messages } from './customerService.messages';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

export const CustomerService: React.FunctionComponent = () => {
  const { marketplace, locale } = useParams();
  return (
    <div className={style.root}>
      <PIcon name={'information'} aria={{ 'aria-label': 'Information icon' }} />
      <div className={style.column}>
        <PText>
          <FormattedMessage {...messages.text} />
        </PText>

        <div>
          <PLinkPure
            href={`${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/contact`}
            target={'_blank'}
          >
            <FormattedMessage {...messages.action} />
          </PLinkPure>
        </div>
      </div>
    </div>
  );
};
