import React, { useContext, useMemo } from 'react';
import { PButtonPure, PText } from '@porsche-design-system/components-react';
import { messages } from './tariff.messages';
import { FormattedMessage } from 'react-intl';
import style from './tariff.module.scss';
import { observer } from 'mobx-react-lite';
import { useRoutes } from '../../../../../hooks/useRoutes';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../../router/routes';
import { TariffName } from '../../../../tariff/components/name/TariffName';
import { upgradeContext } from '../../../../context/upgrade/UpgradeContextProvider';
import { NextPlanDisclaimer } from 'components/tariff/components/name/NextPlanDisclaimer';

export const Tariff: React.FunctionComponent = observer(() => {
  const context = useContext(upgradeContext);
  const generateRoute = useRoutes();

  const manageButton = useMemo(() => {
    if (context.planVariants.length === 0) {
      return null;
    }
    return (
      <Link
        to={generateRoute(Routes.ChangeTariff)}
        className={style.manageButton}
      >
        <PButtonPure icon={'arrow-right'}>
          <FormattedMessage {...messages.action} />
        </PButtonPure>
      </Link>
    );
  }, [context.planVariants, generateRoute]);

  return (
    <div data-testid={'tariff'}>
      <PText>
        <FormattedMessage {...messages.title} />
      </PText>
      <div className={style.row}>
        <PText weight={'bold'} align={'right'}>
          <TariffName />
          <PText
            className={style.disclaimer}
            color={'contrast-medium'}
            align={'right'}
          >
            <NextPlanDisclaimer />
          </PText>
        </PText>
        {manageButton}
      </div>
    </div>
  );
});
