import style from 'components/layout/frosted/frostedLayout.module.scss';
import { FC, ReactNode } from 'react';
import background from '../../../media/backgroundTariff.jpg';

interface FrostedProps {
  children: ReactNode;
  /**
   * Optional background, a default background will be provided
   */
  background?: string;
  /**
   * ClassName for the frosted panel
   */
  className?: string;
}

/**
 * Crates a layout with a frosted panel for the content and a full size background image. <br/>
 * Note, background change gets animated if you don't lifecycle the component.
 * @param props
 * @constructor
 */
export const FrostedLayout: FC<FrostedProps> = (props) => {
  return (
    <div
      style={{ backgroundImage: `url("${props.background || background}")` }}
      className={style.background}
      data-testid={'frostedLayout'}
    >
      <div className={style.layout}>
        <div className={`${style.panel} ${props.className}`}>
          {props.children}
        </div>
        <div className={style.spacing} />
      </div>
    </div>
  );
};
