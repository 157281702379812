import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  charginTimeLabel: {
    id: 'subscriptionHistoryItem.charginTimeLabel',
    defaultMessage: 'Charging duration',
    description: 'Label for charging time',
  },
  charginCostLabel: {
    id: 'subscriptionHistoryItem.charginCostLabel',
    defaultMessage: 'Cost',
    description: 'Label for charging cost',
  },
  charginCostFullLabel: {
    id: 'subscriptionHistoryItem.charginCostFullLabel',
    defaultMessage: 'Charging costs',
    description: 'Label for charging cost',
  },
  charginBlockingFeeLabel: {
    id: 'subscriptionHistoryItem.charginBlockingFeeLabel',
    defaultMessage: 'Blocking fees',
    description: 'Label for charging blocking fee',
  },
});
