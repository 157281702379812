import { FC } from 'react';
import {
  PButton,
  PHeading,
  PTag,
  PText,
} from '@porsche-design-system/components-react';
import { messages } from './adressForm.messages';
import { FormattedMessage, useIntl } from 'react-intl';
import style from './style.module.scss';
import { observer } from 'mobx-react-lite';
import { AddressWidget } from 'pages/activation/components/AddressWidget';
import CardAddress from './CardAddress';
import { BusinessVerification } from 'pages/activation/components/BusinessVerification';
import { useAddress } from './useAddress';
import { messages as checkoutMessages } from '../../../activation/checkout.messages';
import { EuropeOnly } from 'components/context/plan/discriminators/region/EuropeOnly';

interface IProps {
  onVatVerification: (isVatVerified: boolean) => void;
}

const AddressForm: FC<IProps> = observer(({ onVatVerification }) => {
  const {
    authentication,
    handleAddressChange,
    requestBusinessVerification,
    hasVerificatioErrored,
    fullAddress,
    handleBusinessChange,
    hasDoneVerification,
    isLoading,
    handleBusinessVerificationSubmit,
    newBusinessVerificationData,
  } = useAddress({ onVatVerification });

  const intl = useIntl();

  return (
    <>
      <PHeading tag="h2" size="x-large">
        <FormattedMessage {...messages.title} />
      </PHeading>
      <PText size="small" weight="semi-bold">
        <FormattedMessage {...messages.subtitle} />
      </PText>
      <PText size="large" weight="bold" className={style.py3}>
        <FormattedMessage {...messages.billingAdress} />
      </PText>
      <AddressWidget
        onChange={handleAddressChange}
        authorizationToken={authentication.token}
        name="addressId"
      />
      <div className={style.pt2}>
        {fullAddress && requestBusinessVerification && (
          <>
            <PText size="large" weight="bold" className={style.pt3}>
              <FormattedMessage
                {...checkoutMessages.bussinessVerificationSectionTitle}
              />
            </PText>
            <BusinessVerification
              legalName={
                newBusinessVerificationData.legalName ??
                fullAddress.companyName1 ??
                ''
              }
              vat={newBusinessVerificationData.vat ?? fullAddress.vatId ?? ''}
              onChange={handleBusinessChange}
              hasError={hasVerificatioErrored}
            />
            <div className={style.pt2}>
              <PButton
                type="button"
                onClick={handleBusinessVerificationSubmit}
                loading={isLoading}
                disabled={
                  !(
                    newBusinessVerificationData.legalName ??
                    fullAddress.companyName1
                  ) || !(newBusinessVerificationData.vat ?? fullAddress.vatId)
                }
              >
                {intl.formatMessage(
                  checkoutMessages.billingAddressActionButtonLabel,
                )}
              </PButton>
            </div>
          </>
        )}
        {hasDoneVerification && !hasVerificatioErrored && (
          <PTag className={style.pushed} color="notification-success-soft">
            Verified
          </PTag>
        )}
      </div>
      <EuropeOnly>
        <PText size="large" weight="bold" className={style.py3}>
          <FormattedMessage {...messages.chargingCardDeliveryAddressLabel} />
        </PText>
        <CardAddress />
      </EuropeOnly>
    </>
  );
});

export default AddressForm;
