export enum FlowRoutes {
  Confirmation = 'confirmation/*',
  Invitation = 'connect/invitation',
  Activation = 'activation/*',
  Management = 'manage/*',
  Tariff = 'tariff/*',
  Change = 'change/*',
}

export enum Routes {
  Activation = 'activation',
  ConfirmationFailure = 'confirmation/failure',
  ConfirmationSuccess = 'confirmation/success',
  TariffInfo = 'tariff/info',
  TariffSelect = 'tariff/select',
  TariffWelcome = 'tariff/welcome',
  ChangeTariff = 'change',
  Licenses = 'licenses',
}

export type TDefaultParams = {
  marketplace: string;
  locale: string;
  vin: string;
};
