// The multiple decoding is needed because the url could be encoded multiple times (e.g. when getting redirected to the login page or PayPal)
// essentially what happens is the "/" (from https:// for example) is encoded to "%2F" when we get redirected to the page
// then the "%" from "%2F" is encoded to "%25" when we get redirected out and back to the app and that happens everytime we get redirected
export const decodeURIUntilStable = (url: string) => {
  let decodedUrl = url;
  let previousValue = '';

  try {
    while (decodedUrl !== previousValue) {
      previousValue = decodedUrl;
      decodedUrl = decodeURIComponent(previousValue);
    }
  } catch (error) {
    console.error('URL decoding failed');
  }

  return decodedUrl;
};
