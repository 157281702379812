import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Tariff change failed',
    id: 'tariff.change.error.title',
  },
  text: {
    defaultMessage:
      'Unfortunately the tariff change failed. Please try again later.',
    id: 'tariff.change.error.text',
  },
  action: {
    defaultMessage: 'Go back to my tariff',
    id: 'tariff.change.error.action',
  },
});
