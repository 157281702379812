import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  success: {
    defaultMessage:
      'Contract successfully terminated. You’ll receive an e-mail confirmation shortly.',
    id: 'flyout.terminate.success',
  },
  confirm: {
    defaultMessage: 'Are you sure you want to terminate the contract?',
    id: 'flyout.terminate.confirm',
  },
  action: {
    defaultMessage: 'Terminate',
    id: 'flyout.terminate.action',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'flyout.terminate.cancel',
  },
  errorTitle: {
    defaultMessage: 'Failed to terminate the contract',
    id: 'flyout.terminate.error.title',
  },
  errorText: {
    defaultMessage: 'Please try again later.',
    id: 'flyout.terminate.error.text',
  },
  terminate: {
    defaultMessage: 'Terminate the contract',
    id: 'flyout.terminate.terminate',
  },
});
