import { FrostedItem } from 'components/layout/frosted/FrostedItem';
import { FrostedLayout } from 'components/layout/frosted/FrostedLayout';
import style from 'components/layout/slider/sliderLayout.module.scss';
import { Slider } from 'components/slider/Slider';
import { Step, Stepper } from 'components/stepper/Stepper';
import React, { ReactNode, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { resolveRelativePath } from 'utils/router';

export interface Panel {
  /**
   * Short link, used for navigation e.g. `welcome`
   */
  link: string;
  /**
   * ReactElement to render inside the panel
   */
  element: ReactNode;
  /**
   * Background src
   */
  background: string;
}

interface SliderLayoutProps {
  /**
   * Panels to display <br/>
   * Currently the display of only two panels is supported, otherwise animation problems will occur.
   */
  panels: Panel[];
}

export const SliderLayout: React.FunctionComponent<SliderLayoutProps> = (
  props,
) => {
  const location = useLocation();

  /**
   * Current panel index
   */
  const currentIndex = useMemo(() => {
    return props.panels.findIndex((panel) => location.pathname === panel.link);
  }, [location.pathname, props.panels]);

  /**
   * Array with links for the slider
   */
  const sliderLinks = useMemo(() => {
    return props.panels.map((panel) => {
      return panel.link;
    });
  }, [props.panels]);

  /**
   * Currently used background src
   */
  const background = useMemo(() => {
    return props.panels[currentIndex]?.background!;
  }, [props.panels, currentIndex]);

  /**
   * Switch animations depending on direction, it creates a book like pagination effect <br/>
   * Note that enter animations are switched between the two indexes,
   * the desync between state and the running animation, as well as the fact that you cannot update elements in animation,
   * make for a huge headache with 3 or more pages. <br/>
   * For coding efficiency two pages only are supported.
   **/
  const classNames = useMemo(() => {
    if (currentIndex === 0) {
      return {
        exitActive: style.slideOutLeft,
        exitDone: style.hidden,
        enterActive: style.slideInLeft,
      };
    }

    return {
      exitActive: style.slideOutRight,
      exitDone: style.hidden,
      enterActive: style.slideInRight,
    };
  }, [currentIndex]);

  if (currentIndex === -1) {
    return null;
  }

  return (
    <React.Fragment>
      <Stepper currentStep={Step.Tariff} />
      <FrostedLayout background={background}>
        <FrostedItem>
          <Slider activeIndex={currentIndex} links={sliderLinks} />
        </FrostedItem>
        <div data-testid={'sliderLayout'}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              classNames={classNames}
              key={location.pathname}
              timeout={200}
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Routes location={location}>
                {props.panels.map((panel) => {
                  return (
                    <Route
                      path={resolveRelativePath(panel.link)}
                      element={<div id="frostedRoute">{panel.element}</div>}
                      key={panel.link}
                    />
                  );
                })}
              </Routes>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </FrostedLayout>
    </React.Fragment>
  );
};
