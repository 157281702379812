import React from 'react';
import { PText } from '@porsche-design-system/components-react';
import style from './disclaimer.module.scss';
import { EuropeOnly } from '../../../../../context/plan/discriminators/region/EuropeOnly';
import { NorthAmericaOnly } from '../../../../../context/plan/discriminators/region/NorthAmericaOnly';
import { LegacyOnly } from '../../../../../context/plan/discriminators/tariff/LegacyOnly';
import { messages } from './disclaimer.messages';
import { FormattedMessage } from 'react-intl';
import { NarPremiumOnly } from '../../../../../context/plan/discriminators/tariff/NarPremiumOnly';
import { NarPremiumInclusiveOnly } from '../../../../../context/plan/discriminators/tariff/NarPremiumInclusiveOnly';
import { BasicOnly } from '../../../../../context/plan/discriminators/tariff/BasicOnly';

/**
 * Displays a disclaimer for taxes and charging prices
 * @constructor
 */
export const Disclaimer: React.FunctionComponent = () => {
  return (
    <div data-testid={'disclaimer'}>
      <EuropeOnly>
        <PText color={'contrast-medium'} className={style.text}>
          <FormattedMessage {...messages.included} />
        </PText>
      </EuropeOnly>
      <NorthAmericaOnly>
        <LegacyOnly>
          <PText color={'contrast-medium'} className={style.text}>
            <FormattedMessage {...messages.excluded} />
          </PText>
        </LegacyOnly>
        <BasicOnly>
          <div className={style.text}>
            <PText color={'contrast-medium'}>
              *<FormattedMessage {...messages.seeInApp} />
            </PText>
            <PText color={'contrast-medium'}>
              **
              <FormattedMessage {...messages.excluded} />
            </PText>
          </div>
        </BasicOnly>
        <NarPremiumOnly>
          <div className={style.text}>
            <PText color={'contrast-medium'}>
              *<FormattedMessage {...messages.seeInApp} />
            </PText>
            <PText color={'contrast-medium'}>
              **
              <FormattedMessage {...messages.excluded} />
            </PText>
          </div>
        </NarPremiumOnly>
        <NarPremiumInclusiveOnly>
          <div className={style.text}>
            <PText color={'contrast-medium'}>
              *<FormattedMessage {...messages.seeInApp} />
            </PText>
          </div>
        </NarPremiumInclusiveOnly>
      </NorthAmericaOnly>
    </div>
  );
};
