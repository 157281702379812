import { FC } from 'react';
import {
  PCheckboxWrapper,
  PText,
} from '@porsche-design-system/components-react';
import { FormattedMessage } from 'react-intl';
import useTermsAndConditions from 'hooks/useTermsAndConditions';
import { messages } from './termsAndConditions.messages';
import style from './termsAndConditions.module.scss';

interface TermsAndConditionsProps {
  checked: boolean;
  revocationPeriodChecked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRevocationPeriodChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  name?: string;
}

export const TermsAndConditions: FC<TermsAndConditionsProps> = ({
  checked,
  revocationPeriodChecked,
  onChange,
  onRevocationPeriodChange,
  className = '',
  disabled = false,
  name = 'termsAndConditions',
}) => {
  const { tacDoc, dpgDoc, touDoc } = useTermsAndConditions();

  return (
    <>
      <div className={style.checkboxContentWrapper}>
        <PCheckboxWrapper className={className}>
          <input
            type="checkbox"
            name={name}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />

          <span className={style.text}>
            <FormattedMessage
              id={messages.touAndTacCheckboxLabel.id}
              defaultMessage={messages.touAndTacCheckboxLabel.defaultMessage}
              values={{
                tacDoc: (
                  <a href={tacDoc || '#'} target="_blank" rel="noreferrer">
                    <FormattedMessage
                      {...messages.termsAndConditionsTacLabel}
                    />
                  </a>
                ),
                touDoc: (
                  <a href={touDoc || '#'} target="_blank" rel="noreferrer">
                    <FormattedMessage
                      {...messages.termsAndConditionsTouLabel}
                    />
                  </a>
                ),
              }}
            />
          </span>
        </PCheckboxWrapper>
      </div>

      <PText className={style.additionalText}>
        <FormattedMessage
          id={messages.gdprAutoacceptText.id}
          defaultMessage={messages.gdprAutoacceptText.defaultMessage}
          values={{
            privacyPolicy: (
              <a href={dpgDoc || '#'} target="_blank" rel="noreferrer">
                <FormattedMessage {...messages.privacyPolicy} />
              </a>
            ),
          }}
        />
      </PText>

      {revocationPeriodChecked !== undefined ? (
        <PCheckboxWrapper>
          <input
            type="checkbox"
            name="revocationPeriod"
            checked={revocationPeriodChecked}
            onChange={onRevocationPeriodChange}
            disabled={disabled}
          />

          <span className={style.text}>
            <FormattedMessage {...messages.revocationPeriodText} />
          </span>
        </PCheckboxWrapper>
      ) : null}
    </>
  );
};
