import {
  BasePricing,
  FeeType,
  RawPlan,
} from 'components/context/plan/planContext.types';
import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { Price, Region } from 'hooks/usePlans';
import { PaymentData } from 'pages/activation/components/PaymentWidget/usePaymentWidget';
import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useInvitation = () => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { marketplace } = useParams();
  const [isAddressAllowed, setIsAddressAllowed] = useState(true);
  const [isVatVerified, setIsVatVerified] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState<RawPlan>();
  const [applePayToken, setApplePayToken] = useState<null | string>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null | PaymentData
  >(null);
  const { vin } = useContext(vehicleContext);

  const isInitialApplePaySelected = useMemo(() => {
    if (
      typeof selectedPaymentMethod !== 'string' &&
      selectedPaymentMethod !== null
    ) {
      return (
        selectedPaymentMethod?.paymentMethodDetails?.paymentMethod.type ===
          'applepay' &&
        !selectedPaymentMethod?.paymentMethodDetails?.paymentMethod.id
      );
    }

    return false;
  }, [selectedPaymentMethod]);

  const isSubmitDisabled =
    !isVatVerified || !selectedPlan || !selectedPaymentMethod || !canSubmit;

  const planCurrency = useMemo(() => {
    if (!selectedPlan) {
      return 'EUR';
    }

    if (selectedPlan.region === Region.CA) {
      return 'CAD';
    }

    if (selectedPlan.region === Region.US) {
      return 'USD';
    }

    // Take currency code of base price if existing
    const base = selectedPlan?.options[marketplace || '']?.find((item) => {
      return item.feeType === FeeType.BASE;
    }) as BasePricing | undefined;

    if (base) {
      return base.pricingModel.price.grossAmount.currency;
    }

    // Just try our best at this point
    for (const item of selectedPlan?.options[marketplace || ''] || []) {
      const unknown = item as {
        pricingModel?: {
          priceTiers?: { price: Price }[];
        };
      };

      if (
        unknown.pricingModel?.priceTiers &&
        unknown.pricingModel.priceTiers[0]
      ) {
        return unknown.pricingModel.priceTiers[0].price.grossAmount.currency;
      }
    }

    return 'EUR';
  }, [marketplace, selectedPlan]);

  return {
    canSubmit,
    setCanSubmit,
    setSelectedPlan,
    setSelectedPaymentMethod,
    isSubmitDisabled,
    selectedPaymentMethod,
    isLoading,
    setIsLoading,
    isAddressAllowed,
    setIsAddressAllowed,
    vin,
    selectedPlan,
    isVatVerified,
    applePayToken,
    setApplePayToken,
    setIsVatVerified,
    isInitialApplePaySelected,
    planCurrency,
  };
};
