import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useGetConnectRedirectionUrl } from './getConnectRedirectionUrl';
import { useAuthentication } from '../../hooks/useAuthentication/useAuthentication';
import { vehicleContext } from '../../components/context/vehicle/VehicleContextProvider';
import { Entitlement } from '../../components/context/vehicle/VehicleContext';
import { TDefaultParams } from 'router/routes';
import { getIsMarketPlacePCSEnabled } from 'utils/getIsMarketPlacePCSEnabled';
import { NetworkStatus } from 'hooks/useCombinedNetworking/useCombinedNetworking';
import LoadingVehiculeSpinner from 'components/context/vehicle/barrier/LoadingVehicleSpinner';
export const InvitationWrapper: React.FC<{ children: React.ReactNode }> =
  observer(({ children }) => {
    const { entitlement, network } = useContext(vehicleContext);
    const authentication = useAuthentication();

    const redirectionUrl = useGetConnectRedirectionUrl();
    const { marketplace } = useParams<TDefaultParams>();
    let returnLoadingSpinner = false;

    if (!getIsMarketPlacePCSEnabled(marketplace)) {
      window.location.replace(
        `${redirectionUrl.redirectionIfNoChargingOrder.toString()}`,
      );
      returnLoadingSpinner = true;
    }

    if (
      authentication.token.length > 0 &&
      entitlement === Entitlement.NotEntitled
    ) {
      window.location.replace(
        `${redirectionUrl.redirectionIfNoChargingOrder.toString()}`,
      );
      returnLoadingSpinner = true;
    }

    if (returnLoadingSpinner || network === NetworkStatus.Loading) {
      return <LoadingVehiculeSpinner />;
    }

    return <>{children}</>;
  });
