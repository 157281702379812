import { Outlet, RouteObject, RouterProvider } from 'react-router-dom';
import { oauthCallback, tokenLoader } from 'utils/oauth';
import { NotFoundPage } from 'pages/404/NotFoundPage';
import InvitationProcess from 'pages/invitation';
import { ManagementPage } from 'pages/management/ManagementPage';
import { TariffPage } from 'pages/tariff/TariffPage';
import { ActivationPage } from 'pages/activation/ActivationPage';
import { LicensesPage } from 'pages/licenses/LicensesPage';
import { ConfirmationPage } from 'pages/confirmation/ConfirmationPage';
import { OauthErrorPage } from 'pages/activation/components/OauthPage/OauthPage';
import { BasicLayout } from 'components/layout/basic/BasicLayout';
import { VehicleBarrier } from 'components/context/vehicle/barrier/VehicleBarrier';
import { DeviceContextProvider } from 'components/context/device/DeviceContextProvider';
import { LocaleContextProvider } from 'components/context/locale/LocaleContextProvider';
import { VehicleContextProvider } from 'components/context/vehicle/VehicleContextProvider';
import { FlowRoutes, Routes } from './routes';
import { ChangePage } from '../pages/change/ChangePage';
import { InvitationWrapper } from 'pages/invitation/InvitationWrapper';
import { AuthBarrier } from '../components/misc/auth/AuthBarrier';
import RouteHandler from './RouteHandler';
import { PropsWithChildren } from 'react';
import { sentryCreateBrowserRouter } from 'utils/sentry';

const DefaultProviders: React.FC<PropsWithChildren> = ({ children }) => (
  <DeviceContextProvider>
    <LocaleContextProvider>
      <VehicleBarrier>
        <BasicLayout>{children}</BasicLayout>
      </VehicleBarrier>
    </LocaleContextProvider>
  </DeviceContextProvider>
);

const routes: RouteObject[] = [
  {
    loader: oauthCallback,
    element: <OauthErrorPage />,
    path: '/oauth/callback',
  },
  {
    path: '/m?/:marketplace/:locale/:vin',
    loader: tokenLoader,
    id: 'root',
    element: (
      <RouteHandler>
        <LocaleContextProvider>
          <AuthBarrier>
            <VehicleContextProvider>
              <Outlet />
            </VehicleContextProvider>
          </AuthBarrier>
        </LocaleContextProvider>
      </RouteHandler>
    ),
    children: [
      {
        path: FlowRoutes.Invitation,
        element: (
          <InvitationWrapper>
            <InvitationProcess />
          </InvitationWrapper>
        ),
      },
      {
        path: FlowRoutes.Management,
        element: (
          <DefaultProviders>
            <ManagementPage />
          </DefaultProviders>
        ),
      },
      {
        path: FlowRoutes.Tariff,
        element: (
          <DefaultProviders>
            <TariffPage />
          </DefaultProviders>
        ),
      },
      {
        path: FlowRoutes.Activation,
        element: (
          <DefaultProviders>
            <ActivationPage />
          </DefaultProviders>
        ),
      },
      {
        path: FlowRoutes.Confirmation,
        element: (
          <DefaultProviders>
            <ConfirmationPage />
          </DefaultProviders>
        ),
      },
      {
        path: FlowRoutes.Change,
        element: (
          <DefaultProviders>
            <ChangePage />
          </DefaultProviders>
        ),
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: Routes.Licenses,
    element: <LicensesPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

const router = sentryCreateBrowserRouter(routes);

export const Router = () => <RouterProvider router={router} />;
