import { fetchApi } from 'utils/fetchApi';

export type TBusinessVerificationStruct = {
  legalName: string;
  vat: string;
  country: string;
};

export const fetchBusinessVerification = async ({
  legalName,
  vat,
  country,
  authorizationToken,
}: {
  authorizationToken: string;
} & TBusinessVerificationStruct) => {
  const requiredValues = { legalName, vat, country };

  let isVerified = false;

  try {
    const result = await fetchApi(
      `${process.env.REACT_APP_BASE_API_URL}/vat/verification`,
      authorizationToken,
      {
        method: 'POST',
        body: JSON.stringify(requiredValues),
      },
    );

    if (!result.ok) {
      return false;
    }

    isVerified = await result.json();
  } catch (error) {
    isVerified = false;
  }

  return !!isVerified;
};
