import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ac: {
    defaultMessage: 'AC',
    id: 'flyout.overview.chargingCost.ac',
  },
  dc: {
    defaultMessage: 'DC',
    id: 'flyout.overview.chargingCost.dc',
  },
  ionity: {
    defaultMessage: 'IONITY',
    id: 'flyout.overview.chargingCost.ionity',
  },
  preferredTitle: {
    defaultMessage: 'Preferred',
    id: 'flyout.overview.chargingCost.preferred.title',
  },
  preferredSubtitle: {
    defaultMessage: 'IONITY, HPC Lounge, PZ & Werk',
    id: 'flyout.overview.chargingCost.preferred.subtitle',
  },
  loungeTitle: {
    defaultMessage: 'Porsche Charging Lounge',
    id: 'flyout.overview.chargingCost.lounge.title',
  },
  flatrate: {
    defaultMessage: '{amount} min flatrate',
    description: 'Example: 60 min flatrate',
    id: 'flyout.overview.chargingCost.flatrate',
  },
  gracePeriodTitle: {
    defaultMessage: 'Grace period',
    id: 'flyout.overview.chargingCost.gracePeriodTitle',
  },
  gracePeriodText: {
    defaultMessage: '10 min',
    id: 'flyout.overview.chargingCost.gracePeriodText',
  },
  idleFeeTitle: {
    defaultMessage: 'Idle fee',
    id: 'flyout.overview.chargingCost.idleFeeTitle',
  },
  perMinute: {
    defaultMessage: '{amount} /min',
    description: 'Example: 0.39€ /min',
    id: 'flyout.overview.chargingCost.perMinute',
  },
  perMinuteAdditional: {
    defaultMessage: '+ {amount} /min',
    description: 'Example: + 0.39€ /min',
    id: 'flyout.overview.chargingCost.perMinuteAdditional',
  },
  perMinuteStartingAt: {
    defaultMessage: '+ {amount} /min from {time} min',
    description: '{time} is always plural. Example: + 0.39€ /min from 60 min',
    id: 'flyout.overview.chargingCost.perMinuteStartingAt',
  },
  perMinuteKw: {
    defaultMessage: '+{kw} kW: {amount} /min',
    description: 'Example: +60 kW: 0.39€ /min',
    id: 'flyout.overview.chargingCost.perMinuteKw',
  },
  perKwh: {
    defaultMessage: '{amount} /kWh',
    id: 'flyout.overview.chargingCost.perKwh',
  },
  kwComparison: {
    defaultMessage: '≤ {amount} kW',
    id: 'flyout.overview.chargingCost.kwComparison',
  },
  acShort: {
    defaultMessage: 'AC',
    description: 'Prefix for blocking fee',
    id: 'flyout.overview.chargingCost.acShort',
  },
  dcShort: {
    defaultMessage: 'DC',
    description: 'Prefix for blocking fee',
    id: 'flyout.overview.chargingCost.dcShort',
  },
  dcOnly: {
    defaultMessage: 'DC only',
    description: 'Preferred DC only text',
    id: 'charging.dcOnly',
  },
});
