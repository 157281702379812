import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  orderSummaryTitle: {
    id: 'InvitationOrderSummary.orderSummaryTitle',
    defaultMessage: 'Order summary',
  },
  orderSummaryHeading: {
    id: 'InvitationOrderSummary.orderSummaryHeading',
    defaultMessage: 'One-off',
  },
  orderSummaryHeadingMonthly: {
    id: 'InvitationOrderSummary.orderSummaryHeadingMonthly',
    defaultMessage: 'Monthly payment',
  },
  orderSummaryDisclaimer: {
    id: 'InvitationOrderSummary.orderSummaryDisclaimer',
    defaultMessage:
      'Amount due at the beginning of the respective contract year',
  },
  orderSummaryDisclaimerMonthly: {
    id: 'InvitationOrderSummary.orderSummaryDisclaimerMonthly',
    defaultMessage: 'Amount due at end of the month',
  },
  vin: {
    id: 'InvitationOrderSummary.vin',
    defaultMessage: 'VIN: {vin}',
  },
  totalToPay: {
    id: 'InvitationOrderSummary.totalToPay',
    defaultMessage: 'Total to pay:',
  },
});
