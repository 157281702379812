import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  termsAndConditionsCheckboxLabel: {
    defaultMessage:
      'I have read the {touDoc}, {tacDoc}, as well as the {dpgDoc} and I agree with them.',
    id: 'tac.termsAndConditionsCheckboxLabel',
  },
  termsAndConditionsTouLabel: {
    defaultMessage: 'Terms of Use',
    id: 'tac.termsAndConditionsTOULabel',
  },
  termsAndConditionsTacLabel: {
    defaultMessage: 'Terms and Conditions',
    id: 'tac.termsAndConditionsTacLabel',
  },
  termsAndConditionsDpgLabel: {
    defaultMessage: 'Data Privacy Policy',
    id: 'tac.termsAndConditionsDpgLabel',
  },
  gdprAutoacceptText: {
    defaultMessage:
      'We always keep your vehicle up to date with software updates. By completing the purchase, you give your consent for the exchange of data between our servers and your vehicle. Information on the processing of your personal data can be found in our {privacyPolicy}.',
    id: 'tac.gdprAutoacceptText',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy policy',
    id: 'tac.privacyPolicy',
  },
  touAndTacCheckboxLabel: {
    defaultMessage:
      'I have read the {touDoc} and {tacDoc} and I agree with them.',
    id: 'tac.touAndTacCheckboxLabel',
  },
  revocationPeriodText: {
    defaultMessage:
      'I agree that cost of using a service in revocation period will still be charged to my account',
    id: 'tac.revocationPeriodText',
  },
});
