import { fetchApi } from 'utils/fetchApi';

export const addNewSubscription = async ({
  planVariant,
  invoiceAddressId,
  deliveryAddressId,
  paymentRef,
  vin,
  locale,
  token,
}: {
  vin: string;
  planVariant: string;
  invoiceAddressId: string;
  deliveryAddressId: string;
  paymentRef: string;
  locale: string;
  token: string;
}) => {
  try {
    const response = await fetchApi(
      `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions`,
      token,
      {
        method: 'POST',
        body: JSON.stringify({
          vin,
          planVariant,
          invoiceAddressId,
          deliveryAddressId,
          paymentRef,
          locale,
        }),
      },
    );

    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      throw new Error('Subscription creation failed');
    }
  } catch (error: unknown) {
    console.error(error);
  }
};
