export const validPathRegex =
  /^\/(m\/)?[a-z]{2}\/[a-z]{2}-[A-Z]{2}\/([A-Z0-9]{17})(\/(\w+(\/\w+)*|\d+))?$/i;

export const resolveRelativePath = (path: string) => {
  const segments = path.split('/');
  return segments.pop();
};

export const getPathSegments = (path: string) => {
  const matches = path.match(validPathRegex);

  if (!matches) return null;

  return {
    marketplace: matches[0],
    locale: matches[1],
    vin: matches[2],
  };
};

export const isNotLocalHostPath = (): boolean => {
  const hostname = window.location.hostname;
  return !(hostname === 'localhost' || hostname === '127.0.0.1');
};
