import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  effective: {
    defaultMessage: 'Change will take effect on {date}.',
    id: 'tariff.change.text.effective',
  },
  select: {
    defaultMessage:
      'Please choose between the {tariff1} and {tariff2} tariffs.',
    id: 'tariff.change.text.select',
  },
  switch: {
    defaultMessage: 'You’re about to switch to {tariff}.',
    id: 'tariff.change.text.switch',
  },
  downgrade: {
    defaultMessage:
      'You’re about to switch from {tariff1} to the ordinary {tariff2}.',
    id: 'tariff.change.text.downgrade',
  },
});
