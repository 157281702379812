import { useScript } from 'hooks/useScript';
import {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { isCustomEvent } from 'utils/isCustomEvent';
import AddressSkeleton from './Skeleton/AddressSkeleton';
import { getNormalizedLocale } from './getNormalizedLocale';

export type TAddressChangeEventPayload =
  | {
      selectedAddressId: string;
      identifier: string;
    }
  | string;

interface AddressWidgetProps {
  authorizationToken: string;
  identifier?: string;
  defaultAddressId?: string;
  name?: string;
  mode?: 'CHECKOUT' | 'PROFILE' | 'MYLANTA';
  onChange?: (addressId: TAddressChangeEventPayload) => void;
  onAddressEdit?: (isEditing: boolean) => void;
  preventDeleteAddressId?: string | null;
}

let hackyInterval: ReturnType<typeof setInterval> | undefined;

export const AddressWidget: FC<PropsWithChildren<AddressWidgetProps>> = ({
  authorizationToken,
  defaultAddressId,
  onChange,
  onAddressEdit,
  name = 'addressId',
  mode = 'CHECKOUT',
  identifier,
  preventDeleteAddressId,
}) => {
  const widgetRef = useRef() as React.MutableRefObject<HTMLElement> | null;

  const loadingScriptState = useScript(
    `${process.env.REACT_APP_PORSCHE_PROFILE_COMPONENTS_URL}/v5/webcomponents.js`,
  );
  const { marketplace, locale } = useParams<{
    marketplace: string;
    locale: string;
  }>();

  const normalizedLocale = useMemo(
    () => getNormalizedLocale(locale!),
    [locale],
  );

  const [selectedAddressId, setSelectedAddressId] = useState<
    undefined | string
  >();

  useEffect(() => {
    if (loadingScriptState !== 'ready') {
      return;
    }

    const eventHandler: EventListener = (event) => {
      if (isCustomEvent<TAddressChangeEventPayload>(event)) {
        onChange?.(event.detail);
        setSelectedAddressId(
          typeof event.detail === 'string'
            ? event.detail
            : event.detail.selectedAddressId,
        );
      }
    };

    const element = widgetRef?.current;

    if (element) {
      element.addEventListener('addressSelected', eventHandler);
    }

    return () => {
      element?.removeEventListener('addressSelected', eventHandler);
    };
  }, [onChange, loadingScriptState]);

  useEffect(() => {
    clearInterval(hackyInterval);

    let isEditing = false;
    hackyInterval = onAddressEdit
      ? setInterval(() => {
          const nextIsEditing = !!document
            .querySelector('myprofile-address-widget')
            ?.querySelector('myprofile-address-editform');

          if (isEditing !== nextIsEditing) {
            onAddressEdit(nextIsEditing);
            isEditing = nextIsEditing;
          }
        }, 100)
      : undefined;

    return () => {
      clearInterval(hackyInterval);
    };
  }, [onAddressEdit]);

  if (
    loadingScriptState !== 'ready' &&
    !widgetRef?.current &&
    widgetRef?.current?.childNodes.length === 0
  ) {
    return <AddressSkeleton />;
  }

  const deleteButton = widgetRef?.current?.querySelector(
    'profile-pds-p-button-pure[icon="delete"]',
  );

  if (deleteButton && preventDeleteAddressId) {
    const deleteButtonParent = deleteButton.parentElement;
    const deleteButtonHtmlContent = deleteButtonParent?.innerHTML.toString();
    const HTMLincludesSubscriptionAddressId = deleteButtonHtmlContent?.includes(
      `"ng-reflect-ng-if": "${preventDeleteAddressId}`,
    );

    if (HTMLincludesSubscriptionAddressId) {
      deleteButton.setAttribute('disabled', 'true');
    }
  }

  return (
    <>
      {widgetRef?.current?.childNodes.length === 0 && <AddressSkeleton />}
      <input
        readOnly
        name={name}
        value={selectedAddressId || ''}
        style={{ display: 'none' }}
      />
      <myprofile-address-widget
        ref={widgetRef}
        token={authorizationToken}
        country={marketplace!}
        locale={normalizedLocale}
        mode={mode}
        identifier={identifier}
        initially-selected-address-id={defaultAddressId}
      />
    </>
  );
};
