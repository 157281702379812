import { Region } from '../components/context/plan/planContext.types';

export const getRegion = (marketplace: string): Region => {
  switch (marketplace.toUpperCase()) {
    case 'US':
      return Region.US;
    case 'CA':
      return Region.CA;
    default:
      return Region.EU;
  }
};
