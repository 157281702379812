export enum NetworkStatus {
  Loading = 'LOADING',
  Error = 'ERROR',
  Success = 'SUCCESS',
}

/**
 * Returns the most impactful networking status <br/>
 * Error > Loading > Success
 * @param combine
 */
export const useCombinedNetworking = (combine: NetworkStatus[]) => {
  return combine.reduce((prev, current) => {
    if (prev === NetworkStatus.Error || current === NetworkStatus.Error) {
      return NetworkStatus.Error;
    }

    if (prev === NetworkStatus.Loading || current === NetworkStatus.Loading) {
      return NetworkStatus.Loading;
    }

    return NetworkStatus.Success;
  }, NetworkStatus.Success);
};
