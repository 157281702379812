import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  text: {
    defaultMessage:
      'Do you have questions about your tariff or have another concern regarding your Porsche Charging Service? Our customer service is of course available to help you.',
    id: 'flyout.customerService.text',
  },
  action: {
    defaultMessage: 'Contact Customer Service',
    id: 'flyout.customerService.action',
  },
});
