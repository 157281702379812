import { makeAutoObservable } from 'mobx';
import { Authentication } from '../../../hooks/useAuthentication/useAuthentication';
import { PlanVariant, RawPlan, Region } from '../plan/planContext.types';
import { NetworkStatus } from '../../../hooks/useCombinedNetworking/useCombinedNetworking';

interface UpgradePlansProps {
  /**
   * Disable networking
   */
  dummy: boolean;
  /**
   * Fetches plans for given region
   */
  region: Region;
  /**
   * Fetches plans for given planVariants
   */
  planVariants: PlanVariant[];
  /**
   * User authentication
   */
  auth: Authentication;
}

/**
 * Fetches planVariants of {@link UpgradeContext}
 */
export class UpgradePlansContext {
  /**
   * If available, premium plan will always be the first one
   */
  public plans: RawPlan[] = [];
  public network: NetworkStatus = NetworkStatus.Loading;
  public readonly planVariants: PlanVariant[];

  private readonly region: string;
  private readonly auth: Authentication;

  constructor(props: UpgradePlansProps) {
    makeAutoObservable(this);

    this.planVariants = props.planVariants;
    this.auth = props.auth;
    this.region = props.region;

    if (props.dummy) {
      return;
    }
    this.fetch();
  }

  private fetch = async () => {
    try {
      const params = new URLSearchParams({
        region: this.region,
      });

      this.planVariants.forEach((planVariant) => {
        params.append('variants', planVariant);
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/plans?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.auth.token}`,
            'apikey': this.auth.apiKey,
            'Content-Type': 'application/json',
          },
        },
      );
      const result = (await response.json()) as RawPlan[];

      if (result.length === 0) {
        this.network = NetworkStatus.Error;
        return;
      }

      /**
       * Sort plans so premium is always first
       */
      result.sort((a, b) => {
        if (a.variant === PlanVariant.V2_PREMIUM_INCLUSIVE) {
          return -1;
        }
        if (a.variant === PlanVariant.V2_PREMIUM) {
          return -1;
        }
        return 1;
      });

      this.plans = result;
      this.network = NetworkStatus.Success;
    } catch (e: unknown) {
      console.error(e);
      this.network = NetworkStatus.Error;
    }
  };
}
